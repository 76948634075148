
import { defineComponent } from "vue";
import ProductGrid from "@/views/Home/components/products/ProductLoadingGridCard.vue";
import GridLayout from "@/components/common/Products/components/GridLayout.vue";
export default defineComponent({
  name: "ProductsLoading",
  props: {
    productsCount: {
      type: Number,
      default: 8,
    },
  },
  components: {
    ProductGrid,
    GridLayout,
  },
});
