<template>
  <div class="filter-map-search__offers styled-scroll" :class="{show: offersLen > 0 && !hidePane}">
    <div class="filter-map-search__header">
      <div class="filter-map-search__header__close" ref="close" @click="HideList">
        <cross fill="#333"/>
      </div>
      <p class="filter-map-search__header__count">Объявлений: {{ offersLen }}</p>
      <div class="filter-map-search__header__sort">
        <div class="flex items-center" @click="showSort = !showSort">Сортировать по: {{ currentSort.label }}
          <chevron-down class="ml-2" width="10" height="11"/>
        </div>
        <div class="filter-map-search__header__sort__list" v-if="showSort">
          <div class="filter-map-search__header__sort__list__item" v-for="item in sortTypes" :key="item"
               @click="SetSortType(item)">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
    <base-loader class="flex justify-center" v-if="loading"/>
    <div v-else>
      <ObjectGridCard class="mb-3" v-for="offer in offers" :key="offer.id" :product="offer"/>
      <base-loader class="flex justify-center" v-if="nextPageLoading"/>
      <button class="filter-map-search__offers__button" @click="NextPage" v-if="!nextPageLoading && offersLen !== offers.length">Показать еще</button>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {OfferCard} from "@/typings/types";
import {mapSearchSelect} from "@/typings/types";
//components
import ObjectGridCard from "@/components/common/Products/components/ObjectGridCard.vue";
import BaseLoader from "@/components/base/loaders/BaseLoader.vue";

export default defineComponent({
  name: "SearchOnMapOffersList",
  emits: ['changeSort', 'nextPage'],
  props: {
    offers: {
      type: [Object] as PropType<OfferCard[]>,
      required: true
    },
    loading: {
      type: Boolean,
      default: (): boolean => {
        return false;
      }
    },
    error: {
      type: Boolean,
      default: (): boolean => {
        return false;
      }
    },
    currentSort: {
      type: Object as PropType<mapSearchSelect>,
      required: true
    },
    nextPageLoading: {
      type: Boolean,
      default: (): boolean => {
        return false
      }
    },
    offersLen: {
      type: Number,
      default: (): number => {
        return 0;
      }
    }
  },
  components: {
    ObjectGridCard,
    BaseLoader
  },
  data() {
    return {
      hidePane: false,
      showSort: false,
      sortTypes: [
        {label: "Сначала новые", sort: "asc", field: "date"},
        {label: "Сначала старые", sort: "desc", field: "date"},
        {label: "Сначала дешевые", sort: "asc", field: "price"},
        {label: "Сначала дороже", sort: "desc", field: "price"},
      ] as mapSearchSelect[]
    }
  },
  methods: {
    NextPage(): void {
      this.$emit('nextPage');
    },
    SetSortType(type: mapSearchSelect): void {
      this.$emit('changeSort', type);
      this.showSort = false;
    },
    HideList() {
      this.hidePane = true;
    },
    ShowList() {
      this.hidePane = false;
    }
  }
})
</script>

<style scoped lang="scss">
.styled-scroll {
  &::-webkit-scrollbar {
    width: 5px;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #333;

  }
}

.filter-map-search {
  position: relative;

  &__header {
    padding-bottom: 20px;
    position: relative;

    &__close {
      position: absolute;
      right: -3px;
      top: -4px;
      cursor: pointer;
    }

    &__count {
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      color: #3D7E9C;
      margin-bottom: 5px;
    }

    &__sort {
      position: relative;
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      color: #333;
      display: flex;
      align-items: center;
      width: max-content;
      cursor: pointer;

      &__list {
        z-index: 10;
        position: absolute;
        right: 0;
        top: 100%;
        border-radius: 4px;
        overflow: hidden;

        &__item {
          font-weight: 600;
          font-size: 14px;
          line-height: 150%;
          color: #333;
          padding: 5px 10px;
          background: #fff;
          cursor: pointer;

          &:hover {
            background: #EFEFEF;
          }
        }
      }
    }
  }

  &__offers {
    z-index: 20;
    padding: 15px;
    position: absolute;
    left: -430px;
    top: 0;
    width: 400px;
    height: 100%;
    background: #fff;
    overflow-y: scroll;
    transition: .5s;

    &__button {
      width: 100%;
      height: 30px;
      @apply bg-black rounded text-white;
    }

    &.show {
      left: 0;
    }

    @media(max-width: 768px) {
      width: 100%;
      left: -100%;
    }
  }
}
</style>
