
import { defineComponent } from "vue";

export default defineComponent({
  name: "ShareBox",
  data(){
    return {
      copied: false
    }
  },
  methods: {
    fallbackCopyTextToClipboard(text: string): void {
      let textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
    },
    copyTextToClipboard(text: string): void {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        this.copied = true;
        return;
      }
      navigator.clipboard.writeText(text);
      this.copied = true;
    },
  },
  computed: {
    url() {
      return window.location.href;
    },
  },
});
