<template>
  <div class="bg-white rounded-md">
    <!--  title && price && address  -->
    <div :class="$style.wrapper">
      <router-link to="/offers" :class="$style.back" class="block">
        <arrow/>
      </router-link>
      <div class="flex flex-wrap">
        <div class="w-full md:w-2/3">
          <h1 :class="$style.info" class="capitalize">ЖК {{ name }}</h1>
          <div :class="$style.address">
            {{ address.normalized_address }}
            <button :class="$style.map" @click="ScrollToMap">
              На карте
            </button>
          </div>
          <div>
          <span v-if="subLocality.name" :class="$style.district">
            <placeholder class="mr-2"/>
            <span>
              {{ subLocality.name }}
            </span>
          </span>
          </div>
        </div>
        <div :class="$style.right_block">
          <p :class="$style.price">
            от {{ price_per_unit }} за м<sup>2</sup>
          </p>
          <p :class="$style.price_sqm">
            от {{ price_from }} до {{ price_to }}
          </p>
        </div>
      </div>
    </div>
    <!--  residental complex params  -->
    <div class="pl-5 w-full  mb-5 params" v-if="params">
      <div class="flex flex-wrap leading-6">
        <div class="params__item"  v-for="param in params" :key="param">
          <div :class="$style.label" v-html="param.name"></div>
          <div :class="$style.value" v-html="param.value"/>
        </div>
      </div>
    </div>
    <!--  residental complex photos && advantages && params -->
    <div class="flex flex-wrap pb-5 xl:pb-7">
      <!--  residental complex photos  -->
      <div class="w-full lg:w-2/3 px-0 md:px-7 photos" :class="$style['residental-complex-photos']">
        <ImagesCarousel :photos="photos" with-lightbox v-if="photos.length"/>
        <img src="@/assets/no-images.svg" :class="$style['no-image']" alt="" v-else>
      </div>
      <!--  residental complex advantages  -->
      <div class="w-full pl-5 lg:w-1/3  advantages" v-if="advantages.length">
        <h3 class="font-bold text-xl border-b-2 border-blue">Преимущества</h3>
        <div class="space-y-5 pt-5 relative">
          <ResidentalComplexAdvantage v-for="adv in advantages" :adv="adv" :key="adv"/>
        </div>
      </div>
      <div class="w-full pl-5 lg:w-1/3 extra-images" v-else>
        <div class="extra-images__image">
          <img :src="photos[1].preview" v-if="photos[1]">
        </div>
        <div class="extra-images__image">
          <img :src="photos[2].preview" v-if="photos[2]">
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import ResidentalComplexAdvantage from "@/views/ResidentalComplex/components/ResidentalComplexAdvantage.vue";
import ImagesCarousel from "@/components/base/images/ImagesCarousel.vue";
import {Photo, Advantage, Address, SubLocality, PriceInfo, Param} from "@/typings/types";
import FormatNum from "@/ts-helpers/formatNum";

export default defineComponent({
  name: "ResidentalComplexTop",
  props: {
    photos: {
      type: [Object] as PropType<Photo[]>,
      required: true
    },
    advantages: {
      type: [Object] as PropType<Advantage[]>,

    },
    address: {
      type: Object as PropType<Address>,
      required: true
    },
    subLocality: {
      type: Object as PropType<SubLocality>,

    },
    name: {
      type: String,
      required: true
    },
    priceInfo: {
      type: Object as PropType<PriceInfo>,
      required: true
    },
    params: {
      type: [Object] as PropType<Param>,
      required: true
    }
  },
  components: {
    ResidentalComplexAdvantage,
    ImagesCarousel
  },
  computed: {
    currency(): string {
      let curr = this.priceInfo.currency;
      let res: string;
      if (curr === "RUR") res = "₽";
      else res = "$";
      return res;
    },
    price_from(): string {
      if(this.priceInfo?.value_from)
      return `${FormatNum(this.priceInfo.value_from)} ${this.currency}`;
      else return "";
    },
    price_to(): string {
      if(this.priceInfo?.value_to)
      return `${FormatNum(this.priceInfo.value_to)} ${this.currency}`;
      else return "";
    },
    price_per_unit(): string {
      return `${FormatNum(this.priceInfo.value_per_unit)} ${this.currency}`
    }
  },
  methods: {
    ScrollToMap(): void {
      if (document.getElementById("infrastructure") !== null)
        document
          .getElementById("infrastructure")
          ?.scrollIntoView({ behavior: "smooth" });
    },
  }
})
</script>

<style scoped module lang="scss">
.residental-complex-photos {
  height: 515px;
  @media (max-width: 768px) {
    height: 200px;
    margin: 0 14px;
  }
}

.wrapper {
  @apply px-5 md:px-7 pb-7 py-3 text-black;
}

.right_block {
  @apply ml-auto w-full md:w-1/3 md:text-right flex flex-col-reverse xl:flex-col;
  @media (max-width: 768px) {
    @apply flex-col;
  }
}

.info {
  @apply text-2xl md:text-4xl font-extrabold;
}

.address {
  @apply font-medium not-italic my-3 text-sm xl:text-lg;
}

.map {
  @apply text-blue underline font-semibold;
}

.price_sqm {
  @apply text-sm xl:text-xl text-gray font-medium;
}

.price {
  @apply text-2xl xl:text-4xl font-extrabold mt-3 xl:mt-0;
}

.district {
  @apply inline-flex text-base text-blue font-semibold rounded items-center;
}

.back {
  @apply mb-2 py-2 text-mediumgray hover:text-blue cursor-pointer;
  svg {
    @apply fill-current;
  }
}

.label {
  @apply text-gray font-bold;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.value {
  line-height: 89%;
  @apply font-semibold text-lg;
  @media (max-width: 1535px) {
    font-size: 14px;
  }
}
.no-image{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
<style lang="scss" scoped>
.extra-images{
  &__image{
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:first-child{
      height: 159px;
      width: 324px;
      margin-bottom: 20px;
    }
    &:last-child{
      height: 336px;
      width: 324px;
    }
  }
}
@media (min-width: 993px){
  .params {
    &__item {
      position: relative;
      padding-left: 43px;
      padding-right: 43px;
      width: max-content;
      &:first-child{
        padding-left: 10px;

        &::before{
          display: none;
        }
      }
      &::before {
        content: "";
        height: 72%;
        width: 2px;
        position: absolute;
        left: 0;
        top: 9px;
        @apply bg-blue;
      }
    }
  }
}
@media (max-width: 992px) {
  .extra-images{
    display: none;
  }
  .params {
    order: 2;
    padding-top: 20px;

    &__item {
      margin-bottom: 12px;
      border: none;
      position: relative;
      width: 50%;
      padding-left: 15px;
      &::before {
        content: "";
        height: 72%;
        width: 2px;
        position: absolute;
        left: 0;
        top: 9px;
        @apply bg-blue;
      }
    }
  }
  .photos {
    order: 1;
  }
  .advantages {
    order: 3;
  }
}

</style>
