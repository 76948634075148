<template>
  <div :class="$style['fields-list']">
    <div v-for="item in current_tab_data" :key="item" class="mr-2">
      <div :class="$style.fields" v-if="current_tab === item.tab_value">
        <div
          v-for="field in Array.prototype.slice
            .call(item.fields)
            .sort((a, b) => {
              return a.ord - b.ord;
            })"
          :key="field.field_value"
        >
          <Field
            #="field"
            :component="field.component_type"
            :data="field.values"
            :label="field.field_name"
            :name="field.field_value"
            :value="
              GET_INPUT_VALUE(
                item.tab_value,
                field.field_value,
                field.component_type
              )
            "
            :childs="field.child_fields"
            :left_radio="field.values[0]"
            :right_radio="field.values[1]"
            :tab="item.tab_value"
            :unit="field.values[1].name"
            @select="Select"
            @change_value="Select"
          />
          <!--                           :value="get_additional_filter[item.tab_value][field.field_name]"-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Field from "@/views/FilterRendering/components/Field";
import { mapGetters } from "vuex";

export default {
  name: "FieldsList",
  props: {
    current_tab_data: {
      type: Object,
      required: true,
    },
    current_tab: {
      type: String,
      required: true,
    },
    additional_filter: {
      type: Object,
      required: true,
    },
  },
  emits: ["OnSelect"],
  components: {
    Field,
  },
  computed: {
    ...mapGetters("search", ["GET_INPUT_VALUE"]),
  },
  methods: {
    Select(payload) {
      this.$emit("OnSelect", payload);
      this.$forceUpdate();
    },
  },
};
</script>

<style module scoped lang="scss">
.fields {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    width: calc(50% - 15px);
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
.fields-list{
  padding-top: 50px;
  padding-right: 30px;
  padding-left: 30px;
}
@media(max-width: 768px){
  .fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    > div {
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
  .fields-list{
    padding-top: 15px;
    padding-right: 10px;
    padding-left: 10px;
  }
}
</style>
