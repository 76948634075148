<template>
<div class="wrapper">
  {{ coords }}
  <div id="block-1" :style="{left: `${coords.x}px`,top: `${coords.y}px`}"></div>
</div>
</template>

<script>
export default {
  name: "test",
  data(){
    return {
      coords: {
        x: 0,
        y: 0
      }
    }
  },
  mounted(){
    window.addEventListener('touchmove',this.Move);
    document.body.style.overflow = "hidden"
  },
  computed: {
    block() {
      return document.querySelector('#block-1')
    }
  },
  methods: {
    Move(e){
      const touch = e.changedTouches[0];
      this.coords.x = touch.clientX;
      this.coords.y = touch.clientY;
    }
  }
}
</script>

<style scoped>
.wrapper{
  height: 100vh;
}
#block-1{
  position: fixed;
  width: 50px;
  height: 50px;
  top: 200px;
  left: 200px;
  background: black;
  cursor: pointer;
}
</style>
