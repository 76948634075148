
import {defineComponent, PropType} from "vue";
import {Flat, Param, FloorInterface} from "@/typings/types";
import Floor from "@/components/common/ResidentialComplexFlats/components/Floor.vue";
import FlatsList from "@/components/common/ResidentialComplexFlats/components/FlatsList.vue";
import InputWithCurrency from "@/components/base/input/InputWithCurrency.vue";
import LiterSelect from "@/components/common/ResidentialComplexFlats/components/LiterSelect.vue";
import DefaultCheckbox from "@/components/base/checkbox/DefaultCheckbox.vue";


import {get_residental_complex_flats} from "@/api/productsApi";

export default defineComponent({
  name: "ResidentialComplexFlats",
  props: {
    sections: {
      type: [Object] as PropType<Param[]>,
      required: true
    }
  },
  components: {
    Floor,
    InputWithCurrency,
    FlatsList,
    LiterSelect,
    DefaultCheckbox,
  },
  mounted() {
    this.SetLiter(this.sections[0].value)
  },
  data() {
    return {
      current_liter: "",
      filter: {
        price_from: "",
        price_to: "",
        rooms: [] as string[],
        area_from: "",
        area_to: "",
        floor_from: "",
        floor_to: "",
        not_first: false,
        not_last: false,
        from_builder: false,
        from_buyer: false,
      },
      flat_types: [
        {name: "C", value: "студия"},
        {name: "1", value: "1"},
        {name: "2", value: "2"},
        {name: "3", value: "3"},
        {name: "4", value: "4"},
        {name: "5+", value: "5+"},
      ],
      GraphView: false,
      floors: [],
      minFlatsCount: 5,
      flatsTotalCount: 0,
      filter_bubbles: [
        {name: "От собственника", border: "#559DBE", bg: "transparent"},
        {name: "В продаже", border: "#B4B4B4", bg: "transparent"},
      ]
    };
  },
  computed: {
    flats(): Flat[] {
      let res: Flat[] = [];
      const filter = this.filter;
      this.floors.map((item: FloorInterface, i: number) => {
        const flats: Flat[] = item.flats.map(item => {
          return {
            ...item,
            floor: i + 1,
            totalFloors: this.floors.length
          }
        })
        res = res.concat(flats);
      });
      //TODO: Оптимизировать на подфункции
      return res.filter((flat: Flat) => {
        let valid = true;
        for (let key in this.filter) {
          if (!valid) break;
          switch (key) {
            case "rooms": {
              if (filter.rooms.length) {
                const ThisRoom = flat.rooms_count ? flat.rooms_count.toString() : "";
                valid = filter.rooms.includes(ThisRoom);
              }
              break
            }
            case "not_last": {
              if (filter.not_last)
                valid = flat.floor !== flat.totalFloors
              break
            }
            case "not_first": {
              if (filter.not_first)
                valid = flat.floor !== 1
              break
            }
            case "from_builder": {
              if (filter.from_builder)
                valid = flat.seller?.toLowerCase() === "застройщик"
              break
            }
            case "from_buyer": {
              if (filter.from_buyer)
                valid = flat.seller?.toLowerCase() === "собственник"
              break
            }
            case "floor_from": {
              const floor_from = Number(filter.floor_from);
              const floor = flat.floor ?? 0;
              if (floor_from)
                valid = floor >= floor_from
              break
            }
            case "floor_to": {
              const floor_to = Number(filter.floor_to)
              const floor = flat.floor ?? 0;
              if (floor_to)
                valid = floor <= floor_to
              break
            }
            case "price_from": {
              const price_from = Number(filter.price_from.replace(/ /g, ''))
              const price = flat.price ?? 0;
              if (price_from)
                valid = price >= price_from;
              break
            }
            case "price_to": {
              const price_to = Number(filter.price_to.replace(/ /g, ''));
              const price = flat.price ?? 0;
              if (price_to)
                valid = price <= price_to
              break
            }
            case "area_from": {
              const area_from = Number(filter.area_from.replace(/ /g, ''))
              const area = flat.area ?? 0;
              if (area_from)
                valid = area >= area_from;
              break
            }
            case "area_to": {
              const area_to = Number(filter.area_to.replace(/ /g, ''));
              const area = flat.area ?? 0;
              if (area_to)
                valid = area <= area_to;
              break
            }
          }
        }
        return valid;
      });

    },

  },
  methods: {
    SetLiter(value: string | number): void {
      this.current_liter = value.toString();
      this.GetResidentalComplexFlats();
    },
    SetRoom(value: string): void {
      const InRooms = this.filter.rooms.includes(value);
      if (InRooms) this.filter.rooms = this.filter.rooms.filter((item: string) => item !== value);
      else this.filter.rooms.push(value);
    },
    GetResidentalComplexFlats(): void {
      get_residental_complex_flats(this.current_liter)
        .then(({data}) => {
          this.floors = data.floors;
          this.minFlatsCount = data.max_flats_count;
        })
    },
    SetView(value: boolean): void {
      this.GraphView = value;
    },
    ClearFilter(): void {
      this.filter = {
        price_from: "",
        price_to: "",
        rooms: [] as string[],
        area_from: "",
        area_to: "",
        floor_from: "",
        floor_to: "",
        not_first: false,
        not_last: false,
        from_builder: false,
        from_buyer: false,
      }
    }
  },
});

