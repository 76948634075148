<template>
  <div class="m-auto">
    <h5 :class="$style.asideHeader">
      Подпишитесь на нашу рассылку чтобы ничего не пропустить
    </h5>
    <BaseInput class="w-full my-3" placeholder="'Ваш email'" />
    <p class="text-sm ">
      Подписываясь на нашу рассылку, вы соглашаетесь с
      <span class="text-blue underline"> пользовательским соглашением</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "NewsItemAside",
};
</script>

<style scoped module lang="scss">
.asideHeader {
  @apply font-bold text-3xl my-3;
}
</style>
