
import { defineComponent } from "vue";

export default defineComponent({
  name: "SortButton",
  /*
   *
   * @prop title - Заголовок для кнопки
   * @prop sort  - Какую сортировку активирует кнопка
   * @prop sortNow - Кака сортировка активка на данный момент
   * */
  props: {
    title: {
      type: String,
      required: true,
    },
    sort: {
      type: String,
      required: true,
    },
    sortNow: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      type: "asc",
    };
  },
  methods: {
    ChangeSortType() {
      if (this.type === "asc") this.type = "desc";
      else this.type = "asc";

      this.$emit("changeSort", this.sort, this.type);
    },
  },
  computed: {
    /*
     * Проверка на совпадение sort и sortNow
     * OUTPUT True | False
     * */
    active(): boolean {
      return this.sort === this.sortNow;
    },
    /*
     * Проверка на активный тип сортировки
     * OUTPUT True | False
     * */
    asc(): boolean {
      return this.active && this.type === "asc";
    },
    /*
     * Проверка на активный тип сортировки
     * OUTPUT True | False
     * */
    desc(): boolean {
      return this.active && this.type === "desc";
    },
  },
});
