
import {defineComponent, PropType} from "vue";
import {Region, id, Company} from "@/typings/types";
import loader from "@/views/ForDevelopers/components/AboutDeveloperLoading.vue";

export default defineComponent({
  name: "AboutDeveloper",
  props: {
    company: {
      type: Object as PropType<Company>,
      required: true
    },
    loading: {
      type: Boolean,
      default: (): boolean => {
        return true;
      }
    }
  },
  components: {
    loader,
  },
  mounted(){
    if(this.company?.cities_of_presence)
    this.SetRegion(this.company.cities_of_presence[0]);
  },
  data() {
    return {
      currentRegion: "",
      showRegionList: false,
    };
  },
  methods: {
    /*
     * Установить регион показа новостроек застройщика
     *
     * */
    SetRegion(region: Region): void {
      this.showRegionList = false;
      this.currentRegion = region.name;
      this.$emit("changeRegion", region);
    },
    /*
     * Скрыть или показать список регионов застройщика
     *
     * */
    ToggleRegionsList(): void {
      this.showRegionList = !this.showRegionList;
    },
    /*
     * Получить информацию о застройщике
     * */
  },
  computed: {
    id(): id {
      return this.$route.params.id.toString();
    },
  },
});
