<template>
  <div class="offer-top">
    <div class="offer-top__mobile">
      <div class="offer-top__mobile__share" @click="showShare = true">
        <share fill="#333"/>
      </div>
      <div class="offer-top__mobile__favorites">
        <heart-transparent/>
      </div>
    </div>
    <div v-if="showShare" class="offer-top__share-modal">
      <div class="offer-top__share-modal__blur"/>
      <share-box @close="showShare = false"/>
    </div>
    <div :class="$style.wrapper">
      <router-link :class="$style.back" :to="{name: 'Offers',params: {toOldPos: true}}">
        <arrow/>
      </router-link>
      <div class="offer-top__top">
        <div class="offer-top__top__title-container">
          <h1 class="offer-top__top__title-container__title first-letter-upper" v-html="title"/>
          <div class="offer-top__top__title-container__address">
            {{ TitleInBox(address, 40, true) }}
            <button class="offer-top__top__title-container__address__link" @click="ScrollToMap">На карте</button>
          </div>
          <div class="offer-top__top__title-container__sub-locality">
            <span v-if="subLocality" class="offer-top__top__title-container__sub-locality__button">
              <placeholder class="mr-2"/>{{ subLocality?.name }}
            </span>
          </div>
        </div>
        <div class="offer-top__top__price-container">
          <p class="offer-top__top__price-container__price-sqm" v-html="price_per_unit"/>
          <p class="offer-top__top__price-container__price">{{ price }}</p>
          <router-link v-if="residentialComplexInfo.id"
                       :to="{name: 'ResidentialComplex',params: {id: residentialComplexInfo.id}}">
            {{ residentialComplexInfo.name }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap pb-5 xl:pb-7">
      <div class="w-full lg:w-1/2 px-0 md:px-7">
        <div class="gallery">
          <ImagesCarousel :photos="photos" with-lightbox/>
        </div>
      </div>
      <div class="w-full lg:w-1/2">
        <!--    information      -->
        <div class="w-full md:border-t-2 border-blue pt-5 px-5 md:pl-0 md:pr-7 relative offer-top__information">
          <div v-if="residentialComplexInfo.liter" :class="$style.liter">{{ residentialComplexInfo.liter }}</div>
          <div class="params-list">
            <div v-for="param in params" :key="param" class="params-list__item">
              <div class="params-list__item__label" v-html="param.name"/>
              <div class="params-list__item__value" v-html="param.value"/>
            </div>
          </div>
          <div :class="$style.desc" class="mt-3 desc">
            <TextDropdown :text="description"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {Photo, SubLocality} from "@/typings/types";
import TitleInBox from "@/ts-helpers/TitleInBox";
import ImagesCarousel from "@/components/base/images/ImagesCarousel.vue";
import TextDropdown from "@/components/base/dropdown/TextDropdown.vue";
import ShareBox from "@/components/common/Share/ShareBox.vue";

export default defineComponent({
  name: "OfferTop",
  props: {
    address: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    price_per_unit: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    photos: {
      type: [Object] as PropType<Photo[]>,
      required: true,
    },
    params: {
      type: [Object] as PropType<any>,
      required: true
    },
    description: {
      type: String,
      default: () => {
        return "";
      },
    },
    subLocality: {
      type: Object as PropType<SubLocality>
    },
    residentialComplexInfo: {
      type: Object,
      default: (): any => {
        return {};
      }
    }
  },
  components: {
    TextDropdown,
    ImagesCarousel,
    ShareBox
  },
  data() {
    return {
      showShare: false
    }
  },
  methods: {
    TitleInBox: TitleInBox,
    ScrollToMap(): void {
      if (document.getElementById("infrastructure") !== null)
        document
          .getElementById("infrastructure")
          ?.scrollIntoView({behavior: "smooth"});
    },
  },
});
</script>
<style lang="scss" scoped>
.offer-top {
  position: relative;

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &__price-container {
      &__price {
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 150%;
        text-align: right;
      }

      &__price-sqm {
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 150%;
        text-align: right;
      }
    }

    &__title-container {
      &__title {
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 150%;
      }

      &__address {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;

        &__link {
          margin-left: 10px;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 150%;
          text-decoration-line: underline;
        }
      }

      &__sub-locality {
        padding-top: 10px;

        // &__button {
        //  //
        // }
      }
    }

    @media(max-width: 1535px) {
      &__title-container {
        &__title {
          font-size: 32px;
        }
      }
    }
    @media(max-width: 1024px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      &__price-container {
        margin-left: auto;
      }
    }
    @media(max-width: 768px) {
      &__title-container {
        &__title {
          font-size: 22px;
          line-height: 150%;
          margin-bottom: 6px;
        }

        &__address {
          font-size: 14px;
          white-space: pre-line;

          &__link {
            font-size: 14px;
          }
        }
      }
      &__price-container {
        &__price {
          font-size: 24px;
          line-height: 150%;
        }

        &__price-sqm {
          font-size: 14px;
          line-height: 120%;
        }
      }
    }
    @media(max-width: 420px) {
      &__title-container {
        &__title {
          font-size: 22px;
          line-height: 150%;
          margin-bottom: 6px;
          white-space: pre-line;
        }
      }
    }
  }

  &__mobile {
    position: absolute;
    right: 14px;
    top: 20px;
    display: none;

    &__share {
      margin-right: 14px;
    }

    @media(max-width: 1024px) {
      display: flex;
    }
  }

  &__share-modal {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;

    &__blur {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(51, 51, 51, 0.6);
      backdrop-filter: blur(2px);
    }
  }
}

.gallery {
  height: 432px;
  @media(max-width: 1024px) {
    height: 292px;
    @apply px-5;
  }
}

.params-list {
  display: flex;
  flex-wrap: wrap;

  &__item {
    padding-left: 14px;
    width: calc(100% / 3);
    margin-bottom: 18px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-left: 1px solid #EFEFEF;

    &__label {
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 150%;
      color: #828282;
      margin-bottom: 4px;
    }

    &__value {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 89%;
      color: #333;
    }

    &:nth-child(6),
    &:nth-child(5),
    &:nth-child(4), {
      background: #EFEFEF;
      border-left: 1px solid #fff;

      .params-list__item__label {
        color: #828282;
      }
    }

    @media (max-width: 450px) {
      padding-left: 5px;
      &__value {
        font-size: 12px;
      }
    }
  }
}
</style>
<style lang="scss" module scoped>
.link {
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  text-decoration-line: underline;
  @apply text-blue mt-3;
}

.liter {
  position: absolute;
  left: 0;
  bottom: 100%;
  font-size: 20px;
  line-height: 150%;

  @apply text-black font-bold;
}

.wrapper {
  @apply px-5 md:px-7 pb-7 py-3 text-black;
  @media(max-width: 1024px) {
    @apply pb-2;
  }
}

.desc {
  white-space: pre-line;
}

.right_block {
  @apply ml-auto w-full md:w-1/3 md:text-right flex flex-col-reverse xl:flex-col;
}

.info {
  @apply text-2xl md:text-4xl font-extrabold;
}

.address {
  @apply font-medium not-italic my-3 text-sm xl:text-lg flex flex-row items-end;
  p {
    max-width: 70%;
  }

  @media (max-width: 1520px) {
    p {
      max-width: 70%;
    }
  }
  @media (max-width: 500px) {
    @apply block;
    p {
      max-width: 100%;
    }
    button {
      @apply block ml-0;
    }
  }
}

.map {
  margin-left: 15px;
  font-size: 18px;
  @apply text-blue underline font-semibold;
}

.price_sqm {
  @apply text-sm xl:text-xl text-gray font-medium;
}

.price {
  @apply text-2xl xl:text-4xl font-extrabold mt-3 xl:mt-0;
  @media (max-width: 1024px) {
    @apply mt-3;
  }
}

.district {
  @apply inline-flex text-base  text-blue font-semibold rounded items-center;
}

.back {
  width: max-content;
  @apply mb-2 py-2 text-mediumgray hover:text-blue cursor-pointer block;
  svg {
    @apply fill-current;
  }
}

.label {
  font-size: 13px;
  @apply text-lightgray font-bold;
  @media (max-width: 500px) {
    @apply text-sm;
  }
}

.value {
  @apply font-medium;
  @media (max-width: 500px) {
    @apply text-sm;
  }
}

.param-item {
  @apply w-1/3 pl-3 py-1;
  &:nth-child(2) {
    @apply border-l-2 border-r-2 border-milk;
  }

  &:last-child {
    @apply border-r-0;
  }
}

</style>
