
import {defineComponent, PropType} from 'vue';
import {Photo} from "@/typings/types";

export default defineComponent({
  name: "lightbox",
  props: {
    photos: {
      type: [Object] as PropType<Photo[]>,
      required: true
    },
  },
  emits: ["closeLightbox"],
  data() {
    return {
      currentImage: 0
    }
  },
  mounted() {
    window.addEventListener('keydown', this.KeyDownChange)
    this.currentImage = 0;
  },
  unmounted() {
    window.removeEventListener('keydown', this.KeyDownChange)
  },
  computed: {
    prevPhoto(): Photo {
      if (this.photos[this.currentImage - 1]) return this.photos[this.currentImage - 1];
      else return this.photos[this.photos.length - 1];
    },
    nextPhoto(): Photo {
      if (this.photos[this.currentImage + 1]) return this.photos[this.currentImage + 1];
      else return this.photos[0];
    },
  },
  methods: {
    NextImage(): void {
      if (this.currentImage === this.photos.length - 1) this.currentImage = 0;
      else this.currentImage++;
    },
    PrevImage(): void {
      if (this.currentImage === 0) this.currentImage = this.photos.length - 1;
      else this.currentImage--;
    },
    Hide(): void {
      this.$emit('closeLightbox');
    },
    KeyDownChange({code}: any): void {
      console.log(code)
      if (code === "ArrowRight") this.NextImage();
      else if (code === "ArrowLeft") this.PrevImage();
      else if (code === "Escape") this.Hide();
      else return undefined;
    }
  }
})
