
import {defineComponent, PropType} from 'vue';
import {id, OfferCard} from "@/typings/types";
import {get_random_objects} from "@/api/productsApi";
import ObjectsList from "@/components/common/Products/ObjectsList.vue";
import ProductsLoading from "@/components/base/loaders/ProductsLoading.vue";

export default defineComponent({
  name: "SimilarObjects",
  props: {
    title: {
      type: String,
      default: (): string => {
        return "Похожие объекты";
      }
    },
    subTitle: {
      type: String,
      default: (): string => {
        return "";
      }
    }
  },
  components: {
    ObjectsList,
    ProductsLoading
  },
  data() {
    return {
      loading: true,
      error: false,
      similar: [Object] as PropType<OfferCard[]>
    }
  },
  mounted() {
    this.GetSimilar();
  },
  methods: {
    GetSimilar(): void {
      this.loading = true;
      get_random_objects({count: 3}).then((response) => {
        this.similar = response.data;
        this.loading = false;
      })
    }
  }
})
