
import { defineComponent, PropType } from "vue";
import { mapGetters } from "vuex";
import { OfferCard } from "@/typings/types";
//виды обертки для объектов недвижимости
import GridLayout from "@/components/common/Products/components/GridLayout.vue";
import ListLayout from "@/components/common/Products/components/ListLayout.vue";
//виды карточек объекта
import ObjectGridCard from "@/components/common/Products/components/ObjectGridCard.vue";
export default defineComponent({
  name: "ObjectsList",
  props: {
    offers: {
      type: [Object] as PropType<OfferCard[]>,
      required: true,
    },
  },
  components: {
    GridLayout,
    ListLayout,
    ObjectGridCard,
  },
  computed: {
    ...mapGetters("site", {
      view: "GET_OBJECTS_VIEW_STYLE",
    }),
    layout(): string {
      return this.view === "grid" ? "grid-layout" : "list-layout";
    },
    card(): string {
      return this.view === "grid" ? "object-grid-card" : "object-list-card";
    },
  },
});
