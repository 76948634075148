<template>
  <div class="map-wrapper">
    <div id="offices_map"></div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "Map",
  data() {
    return {
      icon: require("@/assets/icons/map/office.png"),

    };
  },
  props: {
    offices: Array,
  },
  mounted() {
    ymaps.ready(this.initializeYandexMap)
  },
  unmounted() {
    window.map.destroy();
    window.map = null;
  },
  /* eslint-disable */
  methods: {
    initializeYandexMap() {
      if (window.map) {
        window.map.destroy();
        window.map = null;
      }
      window.map = new ymaps.Map("offices_map", {
        center: [45.0448, 38.976],
        zoom: 13,
        controls: ["zoomControl", "fullscreenControl"],
      });
      window.map.geoObjects.removeAll();
      this.offices.map(office => {
        const {lat, lon} = office.office_coordinates;
        const Placemark = new ymaps.Placemark([lat, lon], {},
          {
            iconLayout: "default#image",
            iconImageHref: this.icon,
            iconImageSize: [42, 58],
            iconImageOffset: [0, -58],
          });
        window.map.geoObjects.add(Placemark);
        window.map.setCenter([lat,lon])
      })
    },
  },
};
</script>

<style scoped>
#offices_map {
  width: 100%;
  height: 500px;
}

@media (max-width: 768px) {
  #offices_map {
    height: 320px;
  }
}
</style>
