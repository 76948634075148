
import { defineComponent } from "vue";
import RandomProducts from "./RandomProducts.vue";
import TagsList from "@/views/Search/components/TagsList.vue";
export default defineComponent({
  name: "NothingFound",
  components: {
    RandomProducts,
    TagsList,
  },
});
