
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "PaymentTypes",
  props: {
    types: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
});
