<template>
  <nav class="flex line">
    <ul class="flex categories">
      <li>
        <a href="">
          Все новости
        </a>
      </li>
      <li>
        <a href="">
          Недвижимость
        </a>
      </li>
      <li>
        <a href="">
          Ипотека
        </a>
      </li>
      <li>
        <a href="">
          LOFT.UG
        </a>
      </li>
    </ul>
    <ul class="flex ml-auto orders">
      <li>
        <a href="">
          Новые
        </a>
      </li>
      <li>
        <a href="">
          Популярные
        </a>
      </li>
      <li>
        <a href="">
          Обсуждаемые
        </a>
      </li>
      <li class="flex items-center">
        <a href="">
          <span class="mr-2">
            <font-awesome-icon icon="search"></font-awesome-icon>
          </span>
          <span class="">
            Поиск по статьям
          </span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "NewsNav",
};
</script>

<style scoped>
.categories li {
  @apply mr-3 font-semibold;
}

.orders li {
  @apply mr-3 font-semibold;
}

a {
  @apply transition ease-out duration-200 hover:text-blue;
}

.line {
  @apply border-b border-lightgray border-solid flex mb-5 pb-2;
}
</style>
