
import {defineComponent, PropType} from "vue";
import {Photo, SubLocality} from "@/typings/types";
import TitleInBox from "@/ts-helpers/TitleInBox";
import ImagesCarousel from "@/components/base/images/ImagesCarousel.vue";
import TextDropdown from "@/components/base/dropdown/TextDropdown.vue";
import ShareBox from "@/components/common/Share/ShareBox.vue";

export default defineComponent({
  name: "OfferTop",
  props: {
    address: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    price_per_unit: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    photos: {
      type: [Object] as PropType<Photo[]>,
      required: true,
    },
    params: {
      type: [Object] as PropType<any>,
      required: true
    },
    description: {
      type: String,
      default: () => {
        return "";
      },
    },
    subLocality: {
      type: Object as PropType<SubLocality>
    },
    residentialComplexInfo: {
      type: Object,
      default: (): any => {
        return {};
      }
    }
  },
  components: {
    TextDropdown,
    ImagesCarousel,
    ShareBox
  },
  data() {
    return {
      showShare: false
    }
  },
  methods: {
    TitleInBox: TitleInBox,
    ScrollToMap(): void {
      if (document.getElementById("infrastructure") !== null)
        document
          .getElementById("infrastructure")
          ?.scrollIntoView({behavior: "smooth"});
    },
  },
});
