<template>
  <div class="exclusive mb-5">
    <div class="exclusive__title text-white">{{ title }}</div>
    <div class="exclusive__subtitle text-white">{{ subtitle }}</div>
    <router-link :to="{name: 'Offers',params:{reset:true}}" class="exclusive__link">Посмотреть</router-link>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "Exclusive",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
});
</script>

<style scoped lang="scss">
.exclusive {
  height: 245px;
  width: 100%;
  border-radius: 4px;
  background: linear-gradient(90deg, #3d7d9c 31.67%, rgba(51, 51, 51, 0) 100%),
  url("~@/assets/images/exclusive.jpg");
  padding-top: 53px;
  padding-left: 60px;

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 100%;
  }

  &__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    margin-top: 10px;
  }

  &__link {
    padding: 9.5px 23.75px;
    width: 141.59px;
    height: 44px;
    border-radius: 4.75px;
    font-style: normal;
    font-weight: 600;
    font-size: 16.625px;
    line-height: 150%;
    margin-top: 23px;
    @apply bg-black text-white flex flex-row items-center justify-center;
    &:hover {
      background: #000;
    }
  }

  @media (max-width: 768px) {
    height: unset;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 53px;
    &__title{
      font-size: 24px;
      line-height: 100%;
    }
    &__subtitle{
      font-size: 14px;
      line-height: 100%;
    }
  }
}
</style>
