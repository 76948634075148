
import {defineComponent, PropType} from 'vue';
import ResidentalComplexAdvantage from "@/views/ResidentalComplex/components/ResidentalComplexAdvantage.vue";
import ImagesCarousel from "@/components/base/images/ImagesCarousel.vue";
import {Photo, Advantage, Address, SubLocality, PriceInfo, Param} from "@/typings/types";
import FormatNum from "@/ts-helpers/formatNum";

export default defineComponent({
  name: "ResidentalComplexTop",
  props: {
    photos: {
      type: [Object] as PropType<Photo[]>,
      required: true
    },
    advantages: {
      type: [Object] as PropType<Advantage[]>,

    },
    address: {
      type: Object as PropType<Address>,
      required: true
    },
    subLocality: {
      type: Object as PropType<SubLocality>,

    },
    name: {
      type: String,
      required: true
    },
    priceInfo: {
      type: Object as PropType<PriceInfo>,
      required: true
    },
    params: {
      type: [Object] as PropType<Param>,
      required: true
    }
  },
  components: {
    ResidentalComplexAdvantage,
    ImagesCarousel
  },
  computed: {
    currency(): string {
      let curr = this.priceInfo.currency;
      let res: string;
      if (curr === "RUR") res = "₽";
      else res = "$";
      return res;
    },
    price_from(): string {
      if(this.priceInfo?.value_from)
      return `${FormatNum(this.priceInfo.value_from)} ${this.currency}`;
      else return "";
    },
    price_to(): string {
      if(this.priceInfo?.value_to)
      return `${FormatNum(this.priceInfo.value_to)} ${this.currency}`;
      else return "";
    },
    price_per_unit(): string {
      return `${FormatNum(this.priceInfo.value_per_unit)} ${this.currency}`
    }
  },
  methods: {
    ScrollToMap(): void {
      if (document.getElementById("infrastructure") !== null)
        document
          .getElementById("infrastructure")
          ?.scrollIntoView({ behavior: "smooth" });
    },
  }
})
