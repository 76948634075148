<template>
  <div class="container mx-auto py-5">
    <ol class="policy">
      <li class="header-li">
        <h2>Общие положения</h2>
        <ol>
          <li>
            <p>
              Настоящие Правила являются официальным документом и определяют
              порядок обработки и защиты информации о физических лицах,
              пользующихся услугами интернет-сайта
              <strong>www.loft.ug</strong>
              (далее – Сайт).
            </p>
          </li>
          <li>
            <p>
              Целью настоящих Правил является обеспечение надлежащей защиты
              информации о пользователях, в том числе их персональных данных, от
              несанкционированного доступа и разглашения.
            </p>
          </li>
          <li>
            <p>
              Отношения, связанные со сбором, хранением, распространением и
              защитой информации о пользователях Сайта, регулируются настоящими
              Правилами и действующим законодательством Российской Федерации.
            </p>
          </li>
          <li>
            <p>
              Действующая редакция Правил, являющихся публичным документом,
              доступна любому пользователю сети Интернет. Администрация Сайта
              вправе вносить изменения в настоящие Правила. При внесении
              изменений в Правила Администрация Сайта уведомляет об этом
              пользователей путем размещения новой редакции Правил на Сайте по
              постоянному адресу www.loft.ug не позднее, чем за 10 дней
              до вступления в силу соответствующих изменений.
            </p>
          </li>
          <li>
            <p>
              Регистрируясь и используя Сайт, Пользователь выражает свое согласие
              с условиями настоящей Политики Конфиденциальности.
            </p>
          </li>
          <li>
            <p>
              В случае несогласия Пользователя с условиями настоящей Политики
              Конфиденциальности использование Сайта должно быть немедленно
              прекращено.
            </p>
          </li>
        </ol>
      </li>
      <li class="header-li">
        <h2>Условия пользования Сайтом</h2>
        <ol>
          <li>
            <h3>
              Оказывая услуги по использованию Сайта, Администрация Сайта,
              действуя разумно и добросовестно, считает, что Пользователь:
            </h3>
            <ol>
              <li>
                <p>
                  обладает всеми необходимыми правами, позволяющими ему
                  осуществлять регистрацию и использовать настоящий Сайт;
                </p>
              </li>
              <li>
                <p>
                  указывает достоверную информацию о себе в объемах,
                  необходимых для пользования Сайтом;
                </p>
              </li>
              <li>
                <p>
                  ознакомлен с настоящей Политикой Конфиденциальности и выражает
                  свое согласие с ней и принимает на себя указанные в
                  ней права и обязанности.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              Администрация Сайта не проверяет достоверность получаемой
              (собираемой) информации о пользователях, за исключением случаев,
              когда такая проверка необходима в целях исполнения Администрацией
              Сайта обязательств перед пользователем.
            </p>
          </li>
        </ol>
      </li>
      <li class="header-li">
        <h2>Цели обработки информации</h2>
        <ol>
          <li>
            <p>
              Обработка информации о Пользователях осуществляется с целью
              предоставления Пользователю информации о компании
              ИП Ломака Екатерина Леонидовна, а также в целях выполнения
              обязательств Администрации Сайта перед Пользователями.
            </p>
          </li>
        </ol>
      </li>
      <li class="header-li">
        <h2>Состав информации о пользователях</h2>
        <ol>
          <li>
            <p>
              Персональные данные Пользователей включают в себя
              предоставляемые Пользователями и минимально необходимые для
              регистрации на Сайте: имя, номер мобильного телефона и адрес
              электронной почты.
            </p>
          </li>
          <li>
            <h3>
              Сайт обрабатывает также иную информацию о Пользователях,
              которая включает в себя:
            </h3>
            <ol>
              <li>
                <p>
                  стандартные данные, автоматически получаемые http-сервером
                  при доступе к Сайту и последующих действиях Пользователя на
                  Сайте (IP-адрес хоста, вид операционной системы пользователя,
                  страницы Сайта, посещаемые пользователем);
                </p>
              </li>
              <li>
                <p>
                  информацию, автоматически получаемую при доступе к Сайту
                  с использованием закладок (cookies);
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li class="header-li">
        <h2>Обработка информации о пользователях</h2>
        <ol>
          <li>
            <h3>
              Обработка персональных данных осуществляется на основе принципов:
            </h3>
            <ol>
              <li>
                <p>
                  законности целей и способов обработки персональных данных;
                </p>
              </li>
              <li>
                <p>
                  добросовестности;
                </p>
              </li>
              <li>
                <p>
                  соответствия целей обработки персональных данных целям,
                  заранее определенным и заявленным при сборе персональных
                  данных, а также полномочиям Администрации Сайта;
                </p>
              </li>
              <li>
                <p>
                  соответствия объема и характера обрабатываемых персональных
                  данных, способов обработки персональных данных целям обработки
                  персональных данных;
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              Персональные данные пользователей хранятся исключительно на
              электронных носителях и обрабатываются с использованием,
              как автоматизированных систем, так и лично штатным
              Сотрудником ИП Ломака Екатерина Леонидовна.
            </p>
          </li>
          <li>
            <p>
              Персональные данные Пользователей не передаются каким-либо третьим лицам,
              за исключением случаев, прямо предусмотренных настоящими Правилами.
              При указании пользователя или при наличии согласия пользователя
              возможна передача персональных данных пользователя третьим
              лицам-контрагентам Администрации Сайта с условием принятия
              такими контрагентами обязательств по обеспечению конфиденциальности
              полученной информации. Предоставление персональных данных Пользователей
              по запросу государственных органов (органов местного самоуправления)
              осуществляется в порядке, предусмотренном законодательством.
            </p>
          </li>
        </ol>
      </li>
      <li class="header-li">
        <h2>Права и обязанности пользователей</h2>
        <ol>
          <li>
            <p>
              Пользователи вправе на основании запроса получать от Администрации
              Сайта информацию, касающуюся обработки его персональных данных.
            </p>
          </li>
          <li>
            <p>
              Сайт является официальным ресурсом ИП Ломака Екатерина Леонидовна,
              и главной функцией Сайта является предоставление достоверной
              информации о работе компании ИП Ломака Екатерина Леонидовна.
              Данные предоставленные Пользователями не видны другим Пользователям.
            </p>
          </li>
        </ol>
      </li>
      <li class="header-li">
        <h2>Меры по защите информации о Пользователях</h2>
        <ol>
          <li>
            <p>
              Администрация Сайта принимает технические и организационно-правовые
              меры в целях обеспечения защиты персональных данных Пользователя
              от неправомерного или случайного доступа к ним, уничтожения,
              изменения, блокирования, копирования, распространения,
              а также от иных неправомерных действий.
            </p>
          </li>
        </ol>
      </li>
      <li class="header-li">
        <h2>Обращения пользователей</h2>
        <ol>
          <li>
            <p>
              Пользователи вправе направлять Администрации Сайта свои запросы,
              в том числе запросы относительно использования их персональных
              данных в форме электронного документа, подписанного
              квалифицированной электронной подписью в соответствии
              с законодательством Российской Федерации, по адресу
              электронной почты
              <strong><u>office@loft.ug</u></strong
              >.
            </p>
          </li>
          <li>
            <p>
              Администрация Сайта обязуется рассмотреть и направить ответ
              на поступивший запрос пользователя в течение 10 рабочих
              дней с момента поступления обращения.
            </p>
          </li>
          <li>
            <p>
              Вся корреспонденция, полученная Администрацией Сайта от Пользователей,
              относится к информации ограниченного доступа и не разглашается
              без письменного согласия Пользователя. Персональные данные и
              иная информация о Пользователе, направившем запрос, не могут
              быть без специального согласия Пользователя использованы иначе,
              как для ответа по теме полученного запроса или в случаях,
              прямо предусмотренных законодательством.
            </p>
          </li>
        </ol>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "Policy",
};
</script>

<style lang="scss" scoped>
.policy {
  padding-left: 0;
  margin-top: 0;
  list-style-type: none;
}

ol {
  padding-left: 30px;
  margin: 10px 0;
  list-style-type: decimal;
  counter-reset: item;
}

.header-li {
  h2 {
    @apply text-center font-bold text-2xl text-blue my-3;
  }
}
</style>
