//Генерация даты из прошедших секунд от 1970г.
const months: {[key: number]: string} = {
  1: "января",
  2: "февраля",
  3: "марта",
  4: "фпреля",
  5: "мая",
  6: "июня",
  7: "июля",
  8: "фвгуста",
  9: "сентября",
  10: "октября",
  11: "ноября",
  12: "декабря",
}
export default function ParseSecondsToDate(seconds: number | string): string {
  const _date = new Date(Number(seconds) * 1000)
  return `${_date.getDay()} ${months[_date.getMonth()]} ${_date.getFullYear()}г.`;
}
