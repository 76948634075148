<template>
  <div class="request-error text-center">
    <div class="text-xl">
      При получении данных с сервера произошла ошибка
      <span class="request-error__code">{{ errorCode }}</span>
    </div>
    <div class="text-2xl">
      Попробуйте
      <a href="/offers" class="request-error__link">перезагрузить</a> страницу
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "RequestError",
  props: {
    errorCode: {
      type: Number,
      default: (): number => {
        return 500
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.request-error {
  min-height: 56vh;
  padding-top: 20vh;

  &__code,
  &__link {
    @apply text-blue;
  }
}
</style>
