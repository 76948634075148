<template>
  <GridLayout>
    <product-grid v-for="item in productsCount" :key="item" />
  </GridLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ProductGrid from "@/views/Home/components/products/ProductLoadingGridCard.vue";
import GridLayout from "@/components/common/Products/components/GridLayout.vue";
export default defineComponent({
  name: "ProductsLoading",
  props: {
    productsCount: {
      type: Number,
      default: 8,
    },
  },
  components: {
    ProductGrid,
    GridLayout,
  },
});
</script>

<style lang="scss" scoped></style>
