<template>
  <component :is="currentField">
    <template
      v-for="slotName in Object.keys($slots)"
      :key="slotName"
      v-slot:[slotName]="slotProps"
    >
      <slot :name="slotName" v-bind="slotProps" />
    </template>
  </component>
</template>

<script>
import Multiselect from "@/views/FilterRendering/components/Multiselect";
import Radio from "@/views/FilterRendering/components/Radio";
import Split_inputs from "@/views/FilterRendering/components/SplitInputs";

export default {
  props: {
    component: String,
  },
  computed: {
    currentField() {
      switch (this.component) {
        case "multiselect":
          return Multiselect;
        case "radio":
          return Radio;
        case "splitinput":
          return Split_inputs;
      }

      return this.component.toLowerCase();
    },
  },
};
</script>

<style scoped></style>
