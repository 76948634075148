
import { defineComponent } from "vue";
import ProductsLoading from "@/components/base/loaders/ProductsLoading.vue";
import Pagination from "@/components/base/pagination/Pagination.vue";
import SortButton from "@/components/base/button/SortButton.vue";
import NothingFound from "@/components/common/Products/NothingFound.vue";
import TagsList from "@/views/Search/components/TagsList.vue";
import ObjectsList from "@/components/common/Products/ObjectsList.vue";
import RequestError from "@/components/base/errors/RequestError.vue";
export default defineComponent({
  name: "DetectedObjects",
  components: {
    ProductsLoading,
    Pagination,
    SortButton,
    NothingFound,
    TagsList,
    ObjectsList,
    RequestError,
  },
  computed: {
    offers(): any {
      return this.$store.state.search.detectedObjects;
    },
    currentPage(): number {
      return this.$store.state.search.old_search.page;
    },
    loading(): boolean {
      return this.$store.state.search.loading;
    },
    error(): boolean {
      return this.$store.state.search.error;
    },
    errorCode(): string | number {
      return this.$store.state.search.errorCode;
    },
    sort_by(): string {
      return this.$store.state.search.sort_by;
    },
  },
  methods: {
    ChangePage(page: number): void {
      this.$store.dispatch("search/SearchFromPagination", page);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    ChangeSortType(sort: string, type: string): void {
      this.$store.commit("search/SET_SORT", { sort, type });
      this.$store.dispatch("search/SearchAfterChangeSortType");
    },
  },
});
