<template>
  <div>
    <label class="label">
      <slot name="label"></slot>
    </label>
    <div class="wrapper">
      <input type="checkbox" :value="value" id="value" />
      <label
        for="value"
        :class="value ? 'active' : ''"
        @click.prevent="setTrue"
        >{{ true_text }}</label
      >
      <label
        for="value"
        v-html="false_text"
        :class="!value ? 'active' : ''"
        @click.prevent="setFalse"
      ></label>
    </div>
  </div>
</template>

<script>
export default {
  name: "Radio",
  props: {
    true_text: String,
    false_text: String,
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setTrue() {
      this.$emit("change", true);
    },
    setFalse() {
      this.$emit("change", false);
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  border: 1px solid #C9C9C9;
  @apply flex rounded overflow-x-hidden w-full;
  label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #333;
    width: 100%;
    text-align: center;
    padding: 8px 18px;
    cursor: pointer;
    &.active {
      transition: all cubic-bezier(0.4, 0, 1, 1) 0.3s;
      @apply text-white bg-black;
    }
  }

  input {
    display: none;
    width: 1px;
    height: 1px;
    visibility: hidden;
  }
}
.label {
  display: block;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 12px;
  @apply text-black;
}
</style>
