<template>
  <div id="infrastructure" class="infrastructure">
    <div class="infrastructure__title">
      <div>Узнайте сколько времени у вас займёт дорога до работы, детского сада или школы вашего ребёнка</div>
      <svg width="77" height="79" viewBox="0 0 77 79" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.7946 65.0835L39.6257 64.6276C47.7608 64.6276 54.3792 58.0076 54.3792 49.8704C54.3792 41.7333 47.7608 35.1132 39.6257 35.1132H30.8199C25.2403 35.1132 20.701 30.5728 20.701 24.9918C20.701 19.4108 25.2403 14.8705 30.8199 14.8705H54.8427L56.3103 10.2346H30.8199C22.6848 10.2346 16.0664 16.8547 16.0664 24.9918C16.0664 33.129 22.6848 39.7491 30.8199 39.7491H39.6257C45.2053 39.7491 49.7446 44.2894 49.7446 49.8704C49.7446 55.4514 45.2053 59.9918 39.6257 59.9918H22.6187L19.7946 65.0835Z" fill="#333333"/>
        <ellipse cx="64.7831" cy="35.0911" rx="8.39782" ry="3.89899" fill="#DADADA"/>
        <ellipse cx="12.597" cy="74.681" rx="8.39782" ry="3.89899" fill="#DADADA"/>
        <g filter="url(#filter0_b)">
          <circle cx="36.5907" cy="37.7899" r="7.19813" fill="#3D7E9C" fill-opacity="0.8"/>
        </g>
        <path d="M34.3916 34.7702L40.3423 37.9238C40.5037 38.0093 40.5037 38.2407 40.3423 38.3262L34.3914 41.48C34.0763 41.6469 33.7185 41.345 33.8298 41.0064L34.7307 38.2672C34.7611 38.1748 34.7611 38.075 34.7307 37.9827L33.8299 35.2437C33.7186 34.9051 34.0766 34.6031 34.3916 34.7702Z" fill="white"/>
        <path d="M64.5742 0C57.7437 0 52.1865 5.42505 52.1865 12.0932C52.1865 20.4743 64.5864 34.791 64.5864 34.791C64.5864 34.791 76.9619 20.0621 76.9619 12.0932C76.9619 5.42505 71.405 0 64.5742 0Z" fill="#333333"/>
        <path d="M68.9823 4.79883V20.3948H65.3832V18.5952H62.9838V20.3948H59.3848V7.51117L68.9823 4.79883Z" fill="white"/>
        <g filter="url(#filter1_b)">
          <path d="M12.2802 40.7896C5.50896 40.7896 0 46.3042 0 53.0825C0 61.6022 12.2923 76.1554 12.2923 76.1554C12.2923 76.1554 24.5604 61.1832 24.5604 53.0825C24.5604 46.3042 19.0517 40.7896 12.2802 40.7896Z" fill="#3D7E9C" fill-opacity="0.8"/>
          <circle cx="12.2967" cy="52.4856" r="5.69852" fill="white"/>
        </g>
        <defs>
          <filter id="filter0_b" x="28.7927" y="29.992" width="15.596" height="15.596" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feGaussianBlur in="BackgroundImage" stdDeviation="0.299922"/>
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
          </filter>
          <filter id="filter1_b" x="-0.599844" y="40.1897" width="25.7601" height="36.5655" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feGaussianBlur in="BackgroundImage" stdDeviation="0.299922"/>
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
          </filter>
        </defs>
      </svg>

    </div>
    <div class="w-full">
      <div id="yandex-map"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Infrastructure",
  props: {
    longitude: Number,
    latitude: Number,
    infrastructure: {
      type: Array
    },
    hintTitle: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      iconEnd: require("@/assets/icons/map/object-placeholder.svg"),
      icon: require("@/assets/icons/map/object-end-placeholder.svg"),
      house: {
        latitude: this.latitude,
        longitude: this.longitude,
      },
    };
  },
  mounted() {
    this.initializeYandexMap();
  },
  /* eslint-disable */
  methods: {
    initializeYandexMap() {
      ymaps.ready(() => {
        if (myMap) {
          myMap.destroy();
          myMap = null;
        }
        let myMap = new ymaps.Map("yandex-map", {
          center: [this.latitude, this.longitude],
          controls: [],
          zoom: 13,
        });
        //layouts
        const ZoomLayout = ymaps.templateLayoutFactory.createClass(`
      <div class="zoom-buttons">
            <div class="zoom-buttons__button" id="zoom-in">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="#333" xmlns="http://www.w3.org/2000/svg">
                <rect y="8.44531" width="19" height="2.11111" />
                <rect x="10.3721" width="19" height="2.11111" transform="rotate(90 10.3721 0)" />
            </svg>
            </div>
            <div class="zoom-buttons__button" id="zoom-out">
            <svg width="19" height="3" viewBox="0 0 19 3" fill="#333" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.111328" width="19" height="2.11111" />
            </svg>

            </div>
       </div>
      `, {
          build: function () {

            ZoomLayout.superclass.build.call(this);
            const ZoomInButton = document.querySelector('#zoom-in'),
              ZoomOutButton = document.querySelector('#zoom-out');

            this.zoomInCallback = ymaps.util.bind(this.zoomIn, this);
            this.zoomOutCallback = ymaps.util.bind(this.zoomOut, this);

            ZoomInButton.addEventListener('click', this.zoomInCallback);
            ZoomOutButton.addEventListener('click', this.zoomOutCallback);

          },

          zoomIn: function () {
            var map = this.getData().control.getMap();
            map.setZoom(map.getZoom() + 1, {checkZoomRange: true});
          },

          zoomOut: function () {
            var map = this.getData().control.getMap();
            map.setZoom(map.getZoom() - 1, {checkZoomRange: true});
          }
        })
        //Зум контроллер для карты
        const ZoomControl = new ymaps.control.ZoomControl({
          options: {
            layout: ZoomLayout,
            position: {
              right: 15,
              top: 18
            }
          }
        })
        const hintLayout = new ymaps.templateLayoutFactory.createClass(
          `
          <div class="_time-layout">
            <div class="_time-layout__duration">Расстояние: {{ properties.distance.text }}</div>
            <div class="_time-layout__duration">Время в пути {{ properties.duration.text}}</div>
          </div>
          `);
        //route panel and route
        let options = {
          // Внешний вид путевых точек.
          wayPointStartIconLayout: "default#image",
          wayPointStartIconImageHref: this.icon,
          wayPointStartIconImageSize: [25, 36],
          wayPointStartIconImageOffset: [-12, -35],
          wayPointStartIconColor: "#333",
          wayPointStartIconFillColor: "#B3B3B3",
          // Задаем собственную картинку для последней путевой точки.
          wayPointFinishIconLayout: "default#image",
          wayPointFinishIconImageHref: this.iconEnd,
          wayPointFinishIconImageSize: [25, 40],
          wayPointFinishIconImageOffset: [-12, -35],
          // Позволяет скрыть иконки путевых точек маршрута.
          // wayPointVisible:false,

          // Внешний вид транзитных точек.
          viaPointIconRadius: 7,
          viaPointIconFillColor: "#333",
          viaPointActiveIconFillColor: "#333",
          // Транзитные точки можно перетаскивать, при этом
          // маршрут будет перестраиваться.
          viaPointDraggable: true,
          // Позволяет скрыть иконки транзитных точек маршрута.
          // viaPointVisible:false,

          // Внешний вид точечных маркеров под путевыми точками.
          pinIconFillColor: "#333",
          pinActiveIconFillColor: "#333",
          // Позволяет скрыть точечные маркеры путевых точек.
          // pinVisible:false,

          // Внешний вид линии маршрута.
          routeStrokeWidth: 1,
          routeStrokeColor: "#333",
          routeActiveStrokeWidth: 6,
          routeActiveStrokeColor: "#333",

          // Внешний вид линии пешеходного маршрута.
          routeActivePedestrianSegmentStrokeStyle: "solid",
          routeActivePedestrianSegmentStrokeColor: "#333",

          // Автоматически устанавливать границы карты так, чтобы маршрут был виден целиком.
          boundsAutoApply: true
        }
        let routePanelControl = new ymaps.control.RoutePanel();
        routePanelControl.routePanel.state.set({
          fromEnabled: false,
          from: [this.latitude, this.longitude]
        });
        routePanelControl.routePanel.options.set({
          allowSwitch: false,
          types: {masstransit: true, pedestrian: true, taxi: true},
          autofocus: false
        })
        routePanelControl.routePanel.getRouteAsync().then(function (route) {
          route.options.set(options)
          route.model.events.add('requestsuccess', function () {
            let activeRoute = route.getActiveRoute();
            if (activeRoute) {
              let duration = route.getActiveRoute().properties.get("duration");
              let balloonContentLayout = ymaps.templateLayoutFactory.createClass(
                  `<span class="_time-layout">Время в пути: ${duration.text}</span>`);

              route.options.set('routeBalloonContentLayout', balloonContentLayout);
              activeRoute.balloon.open();
            }
          });
        });
        const AddMultiRoute = () => {
          let block = document.querySelector("#infrastructure");
          let blockPosY = block.getBoundingClientRect().top;

          if (window.pageYOffset  > blockPosY) myMap.controls.add(routePanelControl)
        }
        myMap.controls.add(ZoomControl)
        window.addEventListener("scroll", AddMultiRoute);
      });
    },
    destroyMap(myMap) {
      myMap.destroy();
    },
  },
  unmounted() {
    this.destroyMap();
  },
  /* eslint-enable */
};
</script>
<style lang="scss">
.ymaps-2-1-79-route-panel-types__type_selected {
  background-color: #3D7E9C !important;
}
.ymaps-2-1-79-balloon__content{
  margin-right: 0!important;
  padding: 0!important;
}
.ymaps-2-1-79-balloon__layout{
  border: 0.3px solid rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  padding-left: 12px;
}
.ymaps-2-1-79-balloon__tail{
  display: none;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.ymaps-2-1-79-balloon{
  box-shadow: none!important;
}
.ymaps-2-1-79-balloon__close{
  display: none;
}
.ymaps-2-1-79-route-panel-input__letter{
  display: none;
}

.ymaps-2-1-79-route-panel-input__icon.ymaps-2-1-79-route-panel-input__icon_first{
  svg{
    circle{
      fill: #333;
    }
  }
  &::after{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 7px;
    height: 7px;
    background: #fff;
    transform: translate(-50%,-50%);
    border-radius: 100%;
  }
}
.ymaps-2-1-79-route-panel-input__icon.ymaps-2-1-79-route-panel-input__icon_last{
  svg{
    circle{
      fill: #3D7E9C;
    }
  }
  &::after{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 7px;
    height: 7px;
    background: #fff;
    transform: translate(-50%,-50%);
    border-radius: 100%;
  }
}
._time-layout {
  display: block;
  border-radius: 5px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #333333;
}
.zoom-buttons {
  &__button {
    width: 37px;
    height: 37px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    transition: .5s;
    backdrop-filter: blur(2px);
    border: 0.3px solid rgba(0, 0, 0, 0.2);

    &:first-child {
      margin-bottom: 3px;
    }

    &:hover {
      background: #333;

      svg {
        fill: #fff;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.infrastructure {
  @apply flex flex-wrap bg-white rounded-md mt-5;
  &__title {
    width: 100%;
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 120%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    padding: 25px 35px;
    div{
      max-width: 834px;
    }
    @apply text-black;
  }

  @media(max-width: 768px) {
    &__title {
      font-size: 20px;
      line-height: 150%;
      img{
        display: none;
      }
    }
  }
}

#yandex-map {
  width: 100%;
  height: 500px;
  @media (max-width: 768px) {
    height: 320px;
  }
}

.infrastructure-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  padding: 18px 0;
  border-bottom: 1px solid;
  @apply text-black border-milk;
  &:last-child {
    border-bottom: 0;
  }
}
</style>
