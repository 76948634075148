
import { defineComponent } from "vue";
import { get_random_objects } from "@/api/productsApi";
import ProductsLoading from "@/components/base/loaders/ProductsLoading.vue";
import ObjectsList from "@/components/common/Products/ObjectsList.vue";
export default defineComponent({
  name: "RandomProducts",
  props: {
    title: {
      type: String,
      default: (): string => {
        return "Все объявления в Краснодаре";
      },
    },
    products_count: {
      type: Number,
      default: () => {
        return 6;
      },
    },
  },
  components: {
    ProductsLoading,
    ObjectsList,
  },
  data() {
    return {
      loading: false,
      error: false,
      products: [],
    };
  },
  mounted() {
    this.get_random_objects();
  },
  methods: {
    get_random_objects(): any {
      this.error = false;
      this.loading = true;
      get_random_objects({ count: this.products_count })
        .then((response: any): void => {
          this.loading = false;
          this.products = response.data;
        })
        .catch((): void => {
          this.error = true;
          this.loading = false;
        });
    },
  },
});
