<template>
  <ul class="flex flex-wrap py-5 justify-between">
    <NewsCard v-for="card in 9" :key="card" />
  </ul>
</template>

<script>
import NewsCard from "@/views/News/Components/NewsCard";

export default {
  name: "NewsList",
  components: {
    NewsCard,
  },
};
</script>

<style scoped></style>
