<template>
  <div class="container mx-auto pb-10 pt-5 detected-objects">
    <TagsList v-if="!loading && offers.count > 0 && !error" />
    <div class="detected-objects__filter" v-if="offers.count > 0 && !error">
      <div class="detected-objects__title">
        Все объявления в Краснодаре: {{ offers.count }}
      </div>
      <div class="detected-objects__sort">
        Сортировать по:
        <sort-button
          title="дате"
          sort="creation_date"
          :sortNow="sort_by"
          v-on:changeSort="ChangeSortType"
        />
        <sort-button
          title="cтоимости"
          sort="price"
          :sortNow="sort_by"
          v-on:changeSort="ChangeSortType"
        />
      </div>
    </div>
    <div v-if="offers.count > 0 && !loading && !error">
      <ObjectsList :offers="offers.results" />
      <Pagination
        v-if="!loading"
        :value="currentPage"
        :length="Math.ceil(offers.count / 20)"
        :total-visible="7"
        @input="ChangePage"
      />
    </div>
    <ProductsLoading v-else-if="loading" />
    <NothingFound v-else-if="offers.count === 0 && !loading && !error" />
    <RequestError :errorCode="errorCode" v-else />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ProductsLoading from "@/components/base/loaders/ProductsLoading.vue";
import Pagination from "@/components/base/pagination/Pagination.vue";
import SortButton from "@/components/base/button/SortButton.vue";
import NothingFound from "@/components/common/Products/NothingFound.vue";
import TagsList from "@/views/Search/components/TagsList.vue";
import ObjectsList from "@/components/common/Products/ObjectsList.vue";
import RequestError from "@/components/base/errors/RequestError.vue";
export default defineComponent({
  name: "DetectedObjects",
  components: {
    ProductsLoading,
    Pagination,
    SortButton,
    NothingFound,
    TagsList,
    ObjectsList,
    RequestError,
  },
  computed: {
    offers(): any {
      return this.$store.state.search.detectedObjects;
    },
    currentPage(): number {
      return this.$store.state.search.old_search.page;
    },
    loading(): boolean {
      return this.$store.state.search.loading;
    },
    error(): boolean {
      return this.$store.state.search.error;
    },
    errorCode(): string | number {
      return this.$store.state.search.errorCode;
    },
    sort_by(): string {
      return this.$store.state.search.sort_by;
    },
  },
  methods: {
    ChangePage(page: number): void {
      this.$store.dispatch("search/SearchFromPagination", page);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    ChangeSortType(sort: string, type: string): void {
      this.$store.commit("search/SET_SORT", { sort, type });
      this.$store.dispatch("search/SearchAfterChangeSortType");
    },
  },
});
</script>

<style scoped lang="scss">
.detected-objects {
  &__filter {
    @apply flex items-center justify-between pb-5;
  }

  &__title {
    @apply mr-2 text-blue text-2xl md:text-3xl font-semibold;
  }

  &__sort {
    @apply flex items-center text-black text-xl;
  }

  @media (max-width: 1024px) {
    &__title {
      font-size: 24px;
    }
  }
  @media (max-width: 768px) {
    &__filter {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }
  @media (max-width: 400px) {
    &__filter {
      flex-direction: column;
    }
    &__title {
      font-size: 20px;
    }
    &__sort {
      font-size: 14px;
    }
  }
}
</style>
