
import {defineComponent, PropType} from 'vue';
import {Flat} from "@/typings/types";
import ListFlat from "@/components/common/ResidentialComplexFlats/components/ListFlat.vue";

export default defineComponent({
  name: "FlatsList",
  props: {
    flats: {
      type: [Object] as PropType<Flat[]>,
      required: true
    },
    filter: {
      type: Object as PropType<any>,
      required: true
    }
  },
  components: {
    ListFlat
  },
  data() {
    return {
      currentPage: 1,
      showOnPage: 6
    }
  },
  watch: {
    flats() {
      this.currentPage = 1;
    }
  },
  computed: {
    currentPageData(): Flat[] {
      const start = (this.currentPage - 1) * this.showOnPage;
      const end = start + this.showOnPage;
      return this.flats.slice(start, end);
    },
  },
  methods: {
    SetPage(page: number): void {
      this.currentPage = page;
    }
  }
})
