<template>
  <div class="params-list">
    <h2 class="params-list__title">
      {{ title }}
    </h2>
    <div v-if="text" class="mb-4">
      <TextDropdown :max-len="400" :text="text"/>
    </div>
    <div class="params-list__list">
      <div
        class="params-list__list__item"
        v-for="(param, i) in params"
        :key="i"
      >
        <div class=" params-list__list__item__name">{{ param.name }}</div>
        <div class="params-list__list__item__value" v-html="param.value"/>
        <div class="params-list__list__item__line"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {Param} from "@/typings/types";
import TextDropdown from "@/components/base/dropdown/TextDropdown.vue";

export default defineComponent({
  name: "ParamsList",
  props: {
    params: {
      type: [Object] as PropType<Param[]>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  components: {
    TextDropdown
  }
});
</script>
<style scoped lang="scss">
.params-list {
  @apply p-5 bg-white rounded-md mt-5;
  &__title {
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 150%;
    margin-bottom: 10px;
    @apply text-black;
  }

  &__list {
    @apply grid grid-cols-1 md:grid-cols-2 gap-x-5;
    &__item {
      @apply flex relative font-semibold my-2;
      &__name {
        @apply bg-white z-10 pr-2;
      }

      &__value {
        @apply ml-auto bg-white z-10 pl-2;
      }

      &__line {
        height: 1px;
        @apply bg-whitegray w-full bottom-1.5 left-0 absolute;
      }
    }
  }

  @media(max-width: 768px) {
    &__title {
      font-size: 20px;
      line-height: 150%;
    }
    &__list {
      &__item {
        &__name {
          font-style: normal;
          font-weight: 600;
          font-size: 12.9331px;
          line-height: 150%;
          @apply text-black;
        }

        &__value {
          font-style: normal;
          font-weight: 600;
          font-size: 12.9331px;
          line-height: 150%;
          @apply text-black;
        }
      }
    }
  }
}
</style>

