<template>
  <div class="card is-loading">
    <div class="image"></div>
    <div class="content">
      <p></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductLoadingGridCard",
};
</script>

<style lang="scss" scoped>
.card {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

  .image {
    img {
      max-width: 100%;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  .content {
    padding: 20px 30px;
  }
}

.card.is-loading {
  .image,
  h2,
  p {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    border-radius: 5px;
    animation: 1.5s shine linear infinite;
  }

  .image {
    height: 220px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  p {
    height: 100px;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
</style>
