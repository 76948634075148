<template>
  <div class="rounded bg-white flex flex-wrap items-center mb-5">
    <div class="pl-5 py-5">
      <h3 class="text-black font-bold text-xl lg:text-3xl mb-5">
        Свяжитесь с нами удобным для вас способом
      </h3>
      <div class="flex flex-wrap contacts-list">
        <a href="tel:8 (938) 514-81-11" class="contact-card">
          <font-awesome-icon class="mr-3 text-2xl" icon="phone-alt"/>
          <span> 8 (938) 514-81-11 </span>
        </a>
        <a href="mailto:it@loft.ug" class="contact-card">
          <font-awesome-icon class="mr-3 text-2xl" icon="envelope"/>
          <span> e-mail </span>
        </a>
        <a href="https://t.me/loft_ug" target="_blank" class="contact-card">
          <telegram/>
          <span> Telegram </span>
        </a>
        <a
          href="https://web.whatsapp.com/send?phone=79385148111"
          class="contact-card"
          target="_blank"
        >
          <whatsApp/>
          <span> WhatsApp </span>
        </a>
      </div>
    </div>
    <div class="lg:ml-auto px-5 lg:px-0 w-full lg:w-2/12 flex flex-col">
      <div class="border-b border-whitegray mb-3 font-medium">
        Больше новостей
      </div>
      <div class="flex pb-5 lg:pb-0 lg:ml-auto pr-5 social">
        <span class="p-2 inline-block w-10 flex vk">
          <a href="https://vk.com/loft_groups" class="m-auto">
            <vk/>
          </a>
        </span>
        <span class="p-2 inline-block w-10 flex instagram">
          <a href="https://www.instagram.com/loft_groups/" class="m-auto">
            <instagram type="white"/>
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
};
</script>

<style lang="scss">
.contact-card {
  border-right: 1px solid #d3d3d3;
  padding: 5px 23px;
  margin-bottom: 10px;
  &:first-child{
    padding-left: 0;
  }
  &:last-child{
    border-right: none ;
  }
  &:hover {
    transition: .3s;
    @apply bg-transparent text-blue;
    svg {
      path {
        fill: #3D7E9C;
      }

      .svg-phone {
        fill: #fff;
      }
    }
  }

  @apply hover:bg-blue hover:text-white  inline-block flex items-center text-black mr-2 lg:mr-0;
  @media(max-width: 768px){
    padding-left: 0;

  }
}

@media (max-width: 768px) {
  .contacts-list {
    a {
      width: 100%;
      border-right: none;
    }
  }
}

.vk:hover {
  svg {
    path:first-child {
      fill: #3e87f5;
    }
  }
}

.social #vk-bg,.social #inst-bg{
  fill: #333;
}
 .instagram:hover, .vk:hover {
  #vk-bg{
    fill: none;
  }
  #inst-bg{
    fill: url(#paint0_linear);
  }
}
</style>
