<template>
  <div>
    <div class="unclickable-wrapper" v-if="isAlreadyLoading"></div>
    <div class="filter bg-white relative">
      <div class="container mx-auto py-3 relative">
        <Filter @map="key++" @ToggleMap="mapIsOpen = !mapIsOpen" :open-map="mapIsOpen" show-map-button />
      </div>
    </div>
    <DetectedObjects/>
    <SearchOnMap :key="key" v-if="mapIsOpen" @close="mapIsOpen = false"/>
  </div>
</template>

<script>
import DetectedObjects from "./components/DetectedObjects";
import Filter from "./components/Filter";
import SearchOnMap from "./components/SearchOnMap";
import {mapMutations, mapActions} from "vuex";

export default {
  name: "Search",
  components: {
    DetectedObjects,
    Filter,
    SearchOnMap
  },
  data() {
    return {
      mapIsOpen: false,
      key: 1
    }
  },
  mounted() {
    this.init();
    window.addEventListener('click',this.IfClickOnLink)
  },
  unmounted() {
    window.removeEventListener('click',this.IfClickOnLink)
  },
  watch: {
    $route: async function (to) {
      this.mapIsOpen = false;
      if (to.name === "Offers") {
        this.ClearFilterAndSearch();
      }
      if (to.name === "NewFlats") {
        localStorage.removeItem("saved_search");
        await this.reset();
        await this.set_type(to.meta.type);
        await this.Search({save: false});
      }
      if(to.name !== "NewFlats" || to.name !== "Offers") {
        this.$store.commit('search/RESET_FILTER')
        localStorage.removeItem("saved_search");
      }
    },
  },
  computed: {
    isAlreadyLoading() {
      return this.$store.state.search.loading;
    },
    offers() {
      return this.$store.state.search.detectedObjects.results ?? [];
    },
  },
  methods: {
    ...mapMutations({
      set_type: "search/SET_OFFER_PROPERTY_TYPE",
      reset: "search/RESET_FILTER",
    }),
    ...mapActions("search", [
      "ClearFilterAndSearch",
      "Search",
      "SearchFromSavedSearchData",
    ]),
    IfClickOnLink(e){
      if(e.target.getAttribute('href') === "/offers" && !this.isAlreadyLoading) {
        this.mapIsOpen = false;
        this.ClearFilterAndSearch();
      }
    },
    async init() {
      const saved_search = await localStorage.getItem("saved_search");
      const pos = await localStorage.getItem('saved_position');
      //Если запрос не происходит и нету в параметрах типа коммерции и нету в параметрах сброса фильтра
      if (!this.isAlreadyLoading && !this.$route.meta.type && !this.$route.params.reset) {
        if (saved_search  && this.$route.params.toOldPos === "true") {
          await this.SearchFromSavedSearchData({old_pos: pos});
        } else this.ClearFilterAndSearch();
      }
      //если в параметрах указан тип коммерции
      if (this.$route.meta.type) {
        this.reset();
        this.set_type(this.$route.meta.type);
        this.Search({save: false});
      }
      //если в параметре указан reset сбросить фильтр и начать поиск
      if (this.$route.params.reset === "true") {
        this.ClearFilterAndSearch();
      }
    }
  },
};
</script>

<style scoped lang="scss">
.filter {
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
  z-index: 20;
}
.unclickable-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 10000;
}
</style>
