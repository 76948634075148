
import {defineComponent, PropType} from 'vue';
import {SubLocality} from "@/typings/types";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';

export default defineComponent({
  name: "AreaPhotos",
  props: {
    subLocality: {
      type: Object as PropType<SubLocality>,
      required: true
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    onChangeSlide(swiper: any):void {
      console.log(swiper)
    }
  }
})
