<template>
  <div class="pt-25 pb-70">
    <div class="container mx-auto">
      <div class="pt-5 pb-20">
        <about-developer :loading="loading" :company="company" v-on:changeRegion="setNewRegion"/>
      </div>
      <div class="pb-50">
        <developer-products :region="region.name"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {Region, Company} from "@/typings/types";
//components
import AboutDeveloper from "@/views/ForDevelopers/components/AboutDeveloper.vue";
import DeveloperProducts from "@/views/ForDevelopers/components/DeveloperProducts.vue";
import {RawCompanyInfo} from "@/typings/RAW_DATA";

export default defineComponent({
  name: "ForDevelopers",
  components: {
    AboutDeveloper,
    DeveloperProducts
  },
  data() {
    return {
      region: {} as Region,
      loading: true,
      error: false
    };
  },
  mounted() {
    this.loading = false;
  },
  computed: {
    company():Company {return RawCompanyInfo}
  },
  methods: {
    /*
     *
     * Установить новый регион для того чтобы передать его соседнему компоненту со списком
     * новостроек(developer-products),
     * */
    setNewRegion(region: Region): void {
      this.region = region;
    },

  },
});
</script>

<style scoped lang="scss"></style>
