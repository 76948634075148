import { render } from "./OfferTop.vue?vue&type=template&id=62ae724d&scoped=true"
import script from "./OfferTop.vue?vue&type=script&lang=ts"
export * from "./OfferTop.vue?vue&type=script&lang=ts"

import "./OfferTop.vue?vue&type=style&index=0&id=62ae724d&lang=scss&scoped=true"
const cssModules = script.__cssModules = {}
import style1 from "./OfferTop.vue?vue&type=style&index=1&id=62ae724d&lang=scss&module=true&scoped=true"
cssModules["$style"] = style1
script.render = render
script.__scopeId = "data-v-62ae724d"

export default script