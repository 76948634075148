
import {defineComponent} from 'vue';

export default defineComponent({
  name: "TextDropdown",
  props: {
    text: {
      type: String,
      required: true
    },
    maxLen: {
      type: Number,
      default: ():number =>{
        return 200;
      }
    }
  },
  data() {
    return {
      less: true,
    }
  },
  computed: {
    NeedTrim(): boolean {
      return this.text.length > this.maxLen;
    },
    CroppedText(): string {
      return this.text.slice(0, this.maxLen) + "...";
    }
  }
})
