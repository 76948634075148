<template>
  <div class="flex flex-row flex-wrap tags" v-if="GET_TAGS.length">
    <div v-for="(item, i) in GET_TAGS" :key="i" class="mb-3 flex flex-wrap">
      <div
        class="
          px-3
          py-1.5
          border-lightgray border-1
          bg-whitegray
          mr-2
          font-semibold
          rounded
          text-sm
          flex
          items-center
          mb-3
        "
        :style="{ borderLeft: `5px solid ${item.color}` }"
      >
        <div class="mr-2">
          <span>{{ item.name }}&nbsp;</span>
          <span v-if="item.value">{{ item.value }}</span>
        </div>

        <div @click="DeleteFromTag(item)">
          <font-awesome-icon class="text-xs cursor-pointer" icon="times"/>
        </div>
      </div>
      <div
        v-for="(sub_field, index) in item.sub_fields"
        :key="index"
        class="
          px-3
          py-1.5
          border-lightgray border-1
          bg-whitegray
          mr-2
          font-semibold
          rounded
          text-sm
          flex
          items-center
          mb-3
        "
        :style="{ borderLeft: `5px solid ${item.color}` }"
      >
        <div class="mr-2">
          <span>{{ sub_field.name }}&nbsp;</span>
          <span v-if="sub_field.value" v-html="sub_field.value"/>
        </div>

        <div @click="DeleteFromTag(sub_field)">
          <font-awesome-icon class="text-xs cursor-pointer" icon="times"/>
        </div>
      </div>
    </div>
    <div class="clear-filter" v-if="GET_TAGS.length" @click="ClearFilter">Очистить фильтр</div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "TagsList",
  props: {
    copy: {
      type: Boolean,
      default: (): boolean => {
        return false
      }
    }
  },
  computed: {
    ...mapGetters("search", ["GET_TAGS"]),
    tags(): any {
      if (this.copy) return this.GET_TAGS.map();
      else return this.GET_TAGS;
    }
  },
  methods: {
    DeleteFromTag(data: any): void {
      this.$store.commit("search/DELETE_FILTER_DATA_FROM_TAG", data);
      this.$store.dispatch("search/Search");
    },
    ClearFilter(){
      this.$store.dispatch("search/ClearFilterAndSearch");
    }
  },
});
</script>

<style scoped lang="scss">
.tags{
  @media(max-width: 768px){
    padding: 0 1.25rem;
  }
}
.clear-filter{
  font-weight: bold;
  font-size: 14px;
  text-decoration-line: underline;
  color: #C9C9C9;
  cursor: pointer;
  margin-left: 10px;
  padding-top: 4px;
}
</style>
