
import {defineComponent, PropType} from 'vue';
import {Photo} from "@/typings/types";
import declOfNum from "@/ts-helpers/declOfNum";
import lightbox from "@/components/base/images/lightbox.vue";

export default defineComponent({
  name: "ImagesCarousel",
  props: {
    photos: {
      type: [Object] as PropType<Photo[]>,
      required: true,
    },
    delay: {
      type: Number,
      default: (): number => {
        return 0;
      }
    },
    withLightbox: {
      type: Boolean,
      default: (): boolean => {
        return false;
      }
    }
  },
  components: {
    lightbox
  },
  data(): {
    currentImageIndex: number,
    allowToUse: boolean,
    time_start: any,
    image_complete: boolean,
    showLightbox: boolean
  } {
    return {
      currentImageIndex: 0,
      allowToUse: false,
      time_start: new Date(),
      image_complete: true,
      showLightbox: false
    }
  },
  computed: {
    images_text(): string {
      return declOfNum(this.photos.length - 5, [
        "изображение",
        "изображения",
        "изображений",
      ]);
    },
  },
  methods: {
    SetImage(index: number): void {
      if (this.allowToUse)
        this.currentImageIndex = index;
      this.image_complete = true;
    },
    AllowToUse(): void {
      this.allowToUse = Date.now() - this.time_start >= this.delay;
    },
    startTimer(): void {
      this.time_start = Date.now();
    },
    endTimer(): void {
      this.time_start = null;
    },
    onImageLoading(): void {
      this.image_complete = false;
    },
    ToggleLightbox(): void {
      this.showLightbox = !this.showLightbox;
    },
    SwipeHandlerNext(): void {
      if(this.currentImageIndex < this.photos.slice(0,5).length - 1)
      this.currentImageIndex++;
    },
    SwipeHandlerPrev(): void {
      if(this.currentImageIndex !== 0)
      this.currentImageIndex--;
    }
  }
})
