
import {defineComponent} from "vue";
import ProductsLoading from "@/components/base/loaders/ProductsLoading.vue";
import ObjectsList from "@/components/common/Products/ObjectsList.vue";
import PrepositionalCase from "@/ts-helpers/PrepositionalCase";
export default defineComponent({
  name: "DeveloperProducts",
  props: {
    loading: {
      type: Boolean,
      default: (): boolean => {
        return true;
      }
    },
    region: {
      type: String,
      default: (): string => {
        return "";
      }
    }
  },
  computed: {
    regionInPrepositionalCase():string {
      return PrepositionalCase(this.region);
    }
  },
  components: {
    ProductsLoading,
    ObjectsList
  },
});
