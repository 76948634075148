
import {defineComponent, PropType} from 'vue';
import {Seller, Param} from "@/typings/types";
import ShareBox from "@/components/common/Share/ShareBox.vue";
import clickOutside from "@/helpers/clickoutside.js";
import {formatPhoneNumber} from "@/helpers/formatPhone.js";
import ParseSecondsToDate from "@/ts-helpers/ParseSecondsToDate";

export default defineComponent({
  name: "Seller",
  props: {
    createdAt: {
      type: Number,
      default: (): number => {
        return 0
      }
    },
    seller: {
      type: Object as PropType<Seller>,
      required: true
    },
    rightParam: {
      type: Object as PropType<Param>,
      required: true
    },
    leftParam: {
      type: Object as PropType<Param>,
      required: true
    }
  },
  components: {
    ShareBox
  },
  data() {
    return {
      showShare: false,
      showSeller: false
    }
  },
  computed: {
    creationDate(): string {
      return ParseSecondsToDate(this.createdAt);
    },
    name(): string {
      let res = this.seller.is_physical
        ? this.seller.name
        : this.seller.seller_company_name;
      return res ?? "";
    },
    phone(): string {
      return formatPhoneNumber(this.seller.phone) ?? "";
    },
    type():string {
      let res = this.seller.seller_type;
      if(this.seller.is_physical && this.seller.seller_type === 'Компания Loft') res = "Персональный менеджер";
      if(!this.seller.is_physical && this.seller.seller_type === 'Компания Loft') res = "Агенство недвижимости";
      return res ?? "";
    }
  },
  methods: {
    print(): void {
      window.print();
    },
    ToggleSeller(): void {
      this.showSeller = !this.showSeller;
    },
    HideSeller(): void {
      this.showSeller = false;
    }
  },
  directives: {
    clickOutside
  }
})
