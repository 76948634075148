<template>
  <div class="no-products-found">
    <div class="no-products-found__title text-black">
      По вашему запросу ничего не найдено
    </div>
    <div class="no-products-found__subtitle">
      Попробуйте изменить критерии поиска
    </div>
    <TagsList />
    <RandomProducts
      :products_count="8"
      title="Другие предложения в Краснодаре"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import RandomProducts from "./RandomProducts.vue";
import TagsList from "@/views/Search/components/TagsList.vue";
export default defineComponent({
  name: "NothingFound",
  components: {
    RandomProducts,
    TagsList,
  },
});
</script>

<style scoped lang="scss">
.no-products-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 100px;

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 100%;
    margin-bottom: 10px;
    text-align: center;
  }

  &__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    margin-bottom: 21px;
  }

  &__tags {
    display: flex;
    align-items: center;
    padding-bottom: 100px;
  }

  &__clear {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    margin-bottom: 0;
    cursor: pointer;
  }
}
</style>
