<template>
  <div class="payment-types">
    <h2 class=" payment-types__title">Возможные способы оплаты</h2>
    <div class="payment-types__list">
      <div class="payment-types__list__item" v-for="(type, i) in types" :key="i">
        {{ type }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "PaymentTypes",
  props: {
    types: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
});
</script>

<style scoped  lang="scss">
.payment-types{
  @apply p-5 bg-white rounded-md mt-5;
  &__title{
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 150%;
    margin-bottom: 10px;
    @apply text-black;
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    &__item {
      height: 20px;
      border-left: 3px solid ;
      @apply pl-3 border-blue mr-10 font-semibold text-xl items-center flex;
      @media (max-width: 500px) {
        @apply mb-3;
      }
    }
  }
  @media(max-width: 768px){
    &__title{
      font-size: 20px;
      line-height: 150%;
    }
  }
}

</style>
