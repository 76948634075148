<template>
  <div>
    <label class="label">
      {{ label }}
    </label>
    <div class="wrapper">
      <input type="text" :value="value" :id="rand_id" />
      <label
        class="border-r border-r border-lightgray"
        :for="rand_id"
        :class="value === left_radio.value ? 'active' : ''"
        @click.prevent="setValue(left_radio.value, left_radio.name)"
        >{{ left_radio.name }}</label
      >
      <label
        :for="rand_id"
        v-html="right_radio.name"
        :class="value === right_radio.value ? 'active' : ''"
        @click.prevent="setValue(right_radio.value, right_radio.name)"
      ></label>
    </div>
  </div>
</template>

<script>
export default {
  name: "Radio",
  data() {
    return {
      rand_id: Math.floor(Math.random() * 10000),
    };
  },
  props: {
    left_radio: Object,
    right_radio: Object,
    value: {
      type: String,
      default: () => {
        return "";
      },
    },
    label: String,
    tab: String,
    name: String,
  },
  methods: {
    setValue(value, value_label) {
      let payload = {
        value: value,
        tab: this.tab,
        name: this.name,
        label: this.label,
        value_label: value_label,
        type: "radio",
      };
      if (this.value === value) {
        payload.value = "";
        this.$emit("change_value", payload);
      } else {
        this.$emit("change_value", payload);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  border: 1px solid #C9C9C9;
  height: 36px;
  @apply flex rounded overflow-x-hidden w-full;
  label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #333;
    width: 100%;
    text-align: center;
    padding: 7.5px 0;
    cursor: pointer;
    &.active {
      transition: all cubic-bezier(0.4, 0, 1, 1) 0.3s;
      @apply text-white bg-black;
    }
  }

  input {
    display: none;
    width: 1px;
    height: 1px;
    visibility: hidden;
  }
}
.label {
  display: block;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 12px;
  @apply text-black;
}
</style>
