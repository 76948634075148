<template>
  <div>
    <div class="error-404">
      <img class="error-404__image" src="~@/assets/404.png" alt="" />
      <div class="container mx-auto">
        <div class="error-404__text">
          <div class="error-404__title">
            Кажется, этого адреса не существует...
          </div>
          <div class="error-404__subtitle">
            Но мы подобрали для вас проверенные предложения
          </div>
        </div>
      </div>
    </div>
    <div class="container mx-auto mb-10">
      <random-products :products_count="4" title="Эксклюзивные предложения от Loft"/>
    </div>
  </div>
</template>

<script>
import RandomProducts from "@/components/common/Products/RandomProducts";
export default {
  name: "404",
  components: {
    RandomProducts
  }
};
</script>

<style lang="scss" scoped>
.error-404{
  height: 650px;
  position: relative;
  background: #fff;
  &__image{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 56%;
  }
  &__text{
    padding-top: 395px;
  }
  &__title{
    font-weight: bold;
    font-size: 30px;
    line-height: 100.9%;
    margin-bottom: 11px;
  }
  &__subtitle{
    font-weight: 600;
    font-size: 18px;
    line-height: 100.9%;
  }
  @media (max-width: 1380px) {
    &__title{
      font-size: 24px;
    }
    &__subtitle{
      font-weight: 600;
      font-size: 16px;
      line-height: 100.9%;
    }
  }
  @media (max-width: 1024px) {
    &__image{
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 100%;object-fit: cover;
    }
    &__title{
      text-shadow: 1px 1px 2px #fff, 0 0 1em #fff; /* Параметры тени */
      @apply text-blue;
    }
    &__subtitle{
      text-shadow: 1px 1px 2px #fff, 0 0 1em #fff; /* Параметры тени */
      @apply text-blue;
    }
    &__text{
      padding-top: 0;
      position: absolute;
      transform: translate(-50%,-50%);
      top: 50%;
      left: 50%;
      width: 100% ;
      text-align: center;
    }
  }
}
</style>
