<template>
  <div class="random-products pt-10">
    <div class="random-products__filter">
      <div class="random-products__title">
        {{ title }}
      </div>
    </div>
    <ProductsLoading v-if="loading" />
    <ObjectsList v-else :offers="products" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { get_random_objects } from "@/api/productsApi";
import ProductsLoading from "@/components/base/loaders/ProductsLoading.vue";
import ObjectsList from "@/components/common/Products/ObjectsList.vue";
export default defineComponent({
  name: "RandomProducts",
  props: {
    title: {
      type: String,
      default: (): string => {
        return "Все объявления в Краснодаре";
      },
    },
    products_count: {
      type: Number,
      default: () => {
        return 6;
      },
    },
  },
  components: {
    ProductsLoading,
    ObjectsList,
  },
  data() {
    return {
      loading: false,
      error: false,
      products: [],
    };
  },
  mounted() {
    this.get_random_objects();
  },
  methods: {
    get_random_objects(): any {
      this.error = false;
      this.loading = true;
      get_random_objects({ count: this.products_count })
        .then((response: any): void => {
          this.loading = false;
          this.products = response.data;
        })
        .catch((): void => {
          this.error = true;
          this.loading = false;
        });
    },
  },
});
</script>

<style scoped lang="scss">
.random-products {
  width: 100%;

  &__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 25px;
  }

  &__title {
    @apply text-blue text-2xl md:text-3xl font-semibold;
  }

  &__sort {
    @apply text-black flex items-center text-lg font-semibold;
  }

  @media (max-width: 1024px) {
    &__filter{
      padding-bottom: 18px;
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }
    &__title {
      font-weight: bold;
      font-style: normal;
      font-size: 20px;
      line-height: 100%;
      color: #3D7E9C;
    }
  }
  @media (max-width: 768px) {
    &__filter {
      flex-direction: column;
    }
  }
  @media (max-width: 400px) {
    &__filter {
      flex-direction: column;
    }
    &__title {
      font-size: 20px;
    }
    &__sort {
      font-size: 14px;
    }
  }
}
</style>
