
import {defineComponent, PropType} from "vue";
import {Flat} from "@/typings/types";
import ResidentialComplexFlat from "@/components/common/ResidentialComplexFlats/components/ResidentialComplexFlat.vue";
import ClickOutside from "@/helpers/clickoutside.js";

export default defineComponent({
  name: "FloorFlat",
  props: {
    flat: {
      type: Object as PropType<Flat>,
      required: true
    },
    filter: {
      type: Object,
      required: true
    },
    currentFloor: {
      type: Number,
      required: true
    },
    totalFloors: {
      type: Number,
      required: true
    }
  },
  components: {
    ResidentialComplexFlat
  },
  emits: ["reset", "setFlat"],
  data() {
    return {
      showDetails: false
    }
  },
  computed: {
    //TODO: Оптимизировать на подфункции
    selected(): boolean {
      let BooleanArray: boolean[] = [];
      const flat: Flat = this.flat;
      const filter = this.filter
      for (let key in filter) {
        switch (key) {
          case "rooms": {
            if (filter.rooms.length)
              BooleanArray.push(filter.rooms.includes(flat.rooms_count?.toString()));
            break
          }
          case "not_last": {
            if (filter.not_last)
              BooleanArray.push(this.currentFloor !== this.totalFloors);
            break
          }
          case "not_first": {
            if (filter.not_first)
              BooleanArray.push(this.currentFloor !== 1);
            break
          }
          case "from_builder": {
            if (filter.from_builder)
              BooleanArray.push(flat.seller?.toLowerCase() === "застройщик");
            break
          }
          case "from_buyer": {
            if (filter.from_buyer)
              BooleanArray.push(flat.seller?.toLowerCase() === "собственник");
            break
          }
          case "floor_from": {
            const floor_from = Number(filter.floor_from)
            if (floor_from)
              BooleanArray.push(this.currentFloor >= floor_from);
            break
          }
          case "floor_to": {
            const floor_to = Number(filter.floor_to)
            if (floor_to)
              BooleanArray.push(this.currentFloor <= floor_to);
            break
          }
          case "price_from": {
            const price_from = Number(filter.price_from.replace(/ /g, ''))
            const price = flat.price ?? 0;
            if (price_from)
              BooleanArray.push(price >= price_from);
            break
          }
          case "price_to": {
            const price_to = Number(filter.price_to.replace(/ /g, ''));
            const price = flat.price ?? 0;
            if (price_to)
              BooleanArray.push(price <= price_to);
            break
          }
          case "area_from": {
            const area_from = Number(filter.area_from.replace(/ /g, ''))
            const area = flat.area ?? 0;
            if (area_from)
              BooleanArray.push(area >= area_from);
            break
          }
          case "area_to": {
            const area_to = Number(filter.area_to.replace(/ /g, ''));
            const area = flat.area ?? 0;
            if (area_to)
              BooleanArray.push(area <= area_to);
            break
          }
        }
      }
      if (BooleanArray.length)
        return !BooleanArray.includes(false);
      else return false;
    },
    status(): string | undefined {
      return this.flat.status;
    }
  },
  methods: {
    Hide(): void {
      this.showDetails = false;
    },
    Toggle(flag: boolean): void {
      if (this.status !== 'disabled')
        this.showDetails = flag;
    }
  },
  directives: {
    ClickOutside
  }
});
