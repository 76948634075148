<template>
  <loader v-if="loading"/>
  <div class="about-developer" v-else>
    <router-link to="/" class="about-developer__back">
      <arrow/>
    </router-link>
    <div class="about-developer__region">
      <span class="about-developer__region__button" @click="ToggleRegionsList">
        Регион: {{ currentRegion }}
        <chevronDown v-if="company.cities_of_presence.length > 1"/>
      </span>
      <div
        class="about-developer__region__list"
        v-show="showRegionList && company.cities_of_presence.length > 1"
      >
        <div
          class="about-developer__region__list__item"
          v-for="region in company.cities_of_presence"
          :key="region"
          @click="SetRegion(region)"
        >
          {{ region.name }}
        </div>
      </div>
    </div>
    <div class="about-developer__content">
      <div class="about-developer__name">{{ company.name }}</div>
      <div class="about-developer__info-container">
        <div class="about-developer__info">
          <div class="title">Год основания</div>
          <div class="info">2010</div>
        </div>
        <div class="about-developer__info">
          <div class="title">Сдано</div>
          <div class="info">{{ company.houses_finished?.handover }} домов в
            {{ company.houses_finished?.residental_complex_count }} ЖК
          </div>
        </div>
        <div class="about-developer__info">
          <div class="title">Строится</div>
          <div class="info">{{ company.houses_in_progress?.on_finish }} домов в
            {{ company.houses_finished?.residental_complex_count }} ЖК
          </div>
        </div>
      </div>
      <div class="about-developer__text" v-if="company.description">{{ company.description }}</div>
    </div>
    <div class="about-developer__image">
      <img :src="company.logo?.preview" alt="">
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {Region, id, Company} from "@/typings/types";
import loader from "@/views/ForDevelopers/components/AboutDeveloperLoading.vue";

export default defineComponent({
  name: "AboutDeveloper",
  props: {
    company: {
      type: Object as PropType<Company>,
      required: true
    },
    loading: {
      type: Boolean,
      default: (): boolean => {
        return true;
      }
    }
  },
  components: {
    loader,
  },
  mounted(){
    if(this.company?.cities_of_presence)
    this.SetRegion(this.company.cities_of_presence[0]);
  },
  data() {
    return {
      currentRegion: "",
      showRegionList: false,
    };
  },
  methods: {
    /*
     * Установить регион показа новостроек застройщика
     *
     * */
    SetRegion(region: Region): void {
      this.showRegionList = false;
      this.currentRegion = region.name;
      this.$emit("changeRegion", region);
    },
    /*
     * Скрыть или показать список регионов застройщика
     *
     * */
    ToggleRegionsList(): void {
      this.showRegionList = !this.showRegionList;
    },
    /*
     * Получить информацию о застройщике
     * */
  },
  computed: {
    id(): id {
      return this.$route.params.id.toString();
    },
  },
});
</script>

<style scoped lang="scss">
.about-developer {
  position: relative;
  background: #fff;
  border-radius: 5px;
  display: flex;
  min-height: 242px;
  padding: 20px 34px;

  &__content {
    max-width: calc(100% - 255px);
  }

  &__back {
    position: absolute;
    left: 32px;
    top: 12px;
    @apply mb-2 py-2 text-mediumgray hover:text-blue cursor-pointer block;
    svg {
      @apply fill-current;
    }
  }

  &__region {
    position: absolute;
    right: 253px;
    top: 18px;
    @apply font-semibold text-lg;
    &__list {
      z-index: 10;
      position: absolute;
      right: 0;
      width: 216px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06),
      0 0 1px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
      padding: 8px 0;
      @apply bg-white font-semibold;
      @media (max-width: 768px) {
        right: unset;
        left: 25px;
      }
      @media (max-width: 575px) {
        right: unset;
        left: 10px;
      }

      &__item {
        @apply bg-white font-semibold pl-2 text-lg font-semibold text-black hover:bg-milk;
      }
    }

    &__button {
      cursor: pointer;

      svg {
        display: inline;
      }
    }
  }

  &__name {
    @apply font-bold text-3xl text-black pt-5;
  }

  &__info-container {
    @apply flex flex-row justify-start pt-16 items-center;
  }

  &__info {
    position: relative;
    @apply pr-10 pl-10;
    .title {
      color: #b4b4b4;
      @apply font-bold text-xl pb-1 text-mediumgray;
    }

    .info {
      @apply font-semibold text-xl text-black;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;

      &::after {
        display: none;
      }
    }

    &::after {
      content: "";
      height: 100%;
      width: 1px;
      display: block;
      background: #3d7e9c;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__text {
    @apply pt-10 text-lg text-black;
  }

  &__image {
    width: 182px;
    height: 182px;
    border-radius: 4px;
    margin-left: auto;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.04);
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-right: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.04);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    border-radius: 0;
    &__image {
      order: 1;
      margin-left: 0;
    }
    &__region,
    &__back {
      position: unset;
      padding-bottom: 15px;
    }
    &__content {
      max-width: 100%;
      order: 2;
    }
  }
  @media (max-width: 575px) {
    padding-left: 15px;
    padding-right: 15px;
    &__info-container {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    &__info {
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 15px;

      &::after {
        display: none;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}
</style>
