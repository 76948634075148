<template>
  <div class="flat-list-item" v-click-outside="Hide">
    <div class="flat-list-item__card" @click="Toggle" :class="{choosed: showDetails}">
      <div class="flat-list-item__card__image">
        <img :src="flat.plan" alt="">
      </div>
      <div class="flat-list-item__card__info">
        <div class="flat-list-item__card__info__rooms">{{ rooms }}</div>
        <div class="flat-list-item__card__info__seller">{{ seller }}</div>
      </div>
      <div class="flat-list-item__card__params">
        <div class="flat-list-item__card__params__param">
          <div class="flat-list-item__card__params__param__label">Общая площадь</div>
          <div class="flat-list-item__card__params__param__value">{{ flat.area }} м<sup>2</sup></div>
        </div>
        <div class="flat-list-item__card__params__param">
          <div class="flat-list-item__card__params__param__label">этаж</div>
          <div class="flat-list-item__card__params__param__value">{{ flat.floor }}/{{ flat.totalFloors }}</div>
        </div>
        <div class="flat-list-item__card__params__param">
          <div class="flat-list-item__card__params__param__label">Цена за м<sup>2</sup></div>
          <div class="flat-list-item__card__params__param__value">{{ price_per_unit }}</div>
        </div>
      </div>
      <div class="flat-list-item__card__price">{{ price }}</div>
    </div>
    <ResidentialComplexFlat :flat="flat" v-if="showDetails"/>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {Flat} from "@/typings/types"
import FormatNum from "@/ts-helpers/formatNum";
import ClickOutside from "@/helpers/clickoutside.js"
import ResidentialComplexFlat from "@/components/common/ResidentialComplexFlats/components/ResidentialComplexFlat.vue";

export default defineComponent({
  name: "ListFlat",
  props: {
    flat: {
      type: Object as PropType<Flat>,
      required: true
    },
    filter: {
      type: Object,
      required: true
    }
  },
  components: {
    ResidentialComplexFlat
  },
  emits: ['reset', "setFlat"],
  data() {
    return {
      showDetails: false
    }
  },
  computed: {
    price(): string {
      return FormatNum(this.flat.price ?? 0) + " ₽";
    },
    price_per_unit(): string {
      const price: string = String(this.flat.price).replaceAll(",",".");
      const area: string = String(this.flat.area).replaceAll(",",'.');
      const res = parseInt(price) / parseInt(area)
      let tmp = Math.floor(res);
      return FormatNum(tmp) + " ₽";
    },
    rooms(): string {
      return `${this.flat.rooms_count}-ком.`
    },
    seller(): string {
      return `От ${this.flat.seller}a`;
    },
  },
  methods: {
    Hide() {
      this.showDetails = false;
    },
    Toggle() {
      this.showDetails = !this.showDetails;
    }
  },
  directives: {
    ClickOutside
  }
})
</script>

<style scoped lang="scss">
.flat-list-item {
  &__card {
    background: #FFF;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.11);
    border-radius: 4px;
    height: 70px;
    width: 100%;
    padding: 5px 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &__image {
      height: 100%;
      width: 59px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &__info {
      margin-left: 15px;

      &__rooms {
        font-weight: bold;
        font-size: 15px;
        line-height: 150%;
        color: #333;
      }

      &__seller {
        font-weight: 600;
        font-size: 13.0851px;
        line-height: 150%;
        color: #3D7E9C;
      }
    }

    &__params {
      display: flex;
      align-items: center;
      margin: 0 20px;

      &__param {
        padding-right: 18px;
        padding-left: 18px;
        border-right: 1px solid #DADADA;

        &__label {
          font-weight: bold;
          font-size: 13.0851px;
          line-height: 150%;
          color: #B4B4B4;
        }

        &__value {
          font-weight: 600;
          font-size: 13.9574px;
          line-height: 150%;
          color: #333;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
          border-right: none;
        }
      }
    }

    &__price {
      margin-left: auto;
      font-weight: 800;
      font-size: 20px;
      line-height: 150%;
      color: #333;
    }

    &.selected {
      @apply bg-milk;
    }

    &.choosed {
      @apply bg-lightblue;
    }

    @media (max-width: 1535px) {
      &_info {
        &__rooms, &__seller {
          font-size: 10px;
        }
      }
      &__params {
        margin-left: 30px;

        &__param {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
      &__price {
        font-size: 14px;
      }
    }
    @media(max-width: 640px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      height: unset;
      padding: 0 0 15px;
      overflow: hidden;
      &__image {
        width: 100%;
        height: 200px;
      }
      &__info {
        margin-left: 0;
        padding-top: 15px;
        padding-left: 15px;
      }
      &__params {
        margin-left: 0;
        padding-left: 15px;
      }
      &__price {
        margin-right: 15px;
      }
    }
  }
}
</style>
