
import {defineComponent, PropType} from 'vue';
import {OfferCard} from "@/typings/types";
import {mapSearchSelect} from "@/typings/types";
//components
import ObjectGridCard from "@/components/common/Products/components/ObjectGridCard.vue";
import BaseLoader from "@/components/base/loaders/BaseLoader.vue";

export default defineComponent({
  name: "SearchOnMapOffersList",
  emits: ['changeSort', 'nextPage'],
  props: {
    offers: {
      type: [Object] as PropType<OfferCard[]>,
      required: true
    },
    loading: {
      type: Boolean,
      default: (): boolean => {
        return false;
      }
    },
    error: {
      type: Boolean,
      default: (): boolean => {
        return false;
      }
    },
    currentSort: {
      type: Object as PropType<mapSearchSelect>,
      required: true
    },
    nextPageLoading: {
      type: Boolean,
      default: (): boolean => {
        return false
      }
    },
    offersLen: {
      type: Number,
      default: (): number => {
        return 0;
      }
    }
  },
  components: {
    ObjectGridCard,
    BaseLoader
  },
  data() {
    return {
      hidePane: false,
      showSort: false,
      sortTypes: [
        {label: "Сначала новые", sort: "asc", field: "date"},
        {label: "Сначала старые", sort: "desc", field: "date"},
        {label: "Сначала дешевые", sort: "asc", field: "price"},
        {label: "Сначала дороже", sort: "desc", field: "price"},
      ] as mapSearchSelect[]
    }
  },
  methods: {
    NextPage(): void {
      this.$emit('nextPage');
    },
    SetSortType(type: mapSearchSelect): void {
      this.$emit('changeSort', type);
      this.showSort = false;
    },
    HideList() {
      this.hidePane = true;
    },
    ShowList() {
      this.hidePane = false;
    }
  }
})
