<template>
  <div v-click-outside="closeDropdown" class="multiselect" :class="{active: isOpen}">
    <div class="multiselect__button" @click="toggleDropdown">
      <slot name="value"></slot>
      <div class="multiselect__button__icon">
        <font-awesome-icon icon="chevron-down"/>
      </div>
    </div>
    <div
      v-show="isOpen"
    >
      <ul class="list">
        <li
          v-for="d in data"
          v-bind:key="d"
          :class="{active: value.includes(d.value)}"
          class="list__item"
          @click="select(d.value, d.name)"
        >
          <div :class="{checked: value.includes(d.value)}" class="list__item__check">
            <checked v-if="value.includes(d.value)"/>
          </div>
          <div class="list__item__name first-letter-upper">{{ d.name }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ClickOutside from "@/helpers/clickoutside";

export default {
  name: "BaseMultiSelect",

  props: {
    data: Array,
    value: Array,
    studio: {
      type: Number,
      defautl: false,
    },
    isInMainFilter: Boolean,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    isSelected(value) {
      return this.value === value;
    },
    closeDropdown() {
      this.isOpen = false;
    },
    openDropdown() {
      this.isOpen = true;
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    select(value) {
      this.$emit("valueSelect", value);
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss" scoped>
.multiselect {
  position: relative;

  &__button {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 30px;
    border-right: 1px solid #B4B4B4;
    font-size: 15px;
    &__icon {
      margin-left: 30px;
      transition: .5s;
    }
  }
  &.active{
    .multiselect__button__icon{
      transform: rotate(180deg);
    }
  }
  @media(min-width: 1280px) and (max-width: 1535px) {
    &__button{
      font-size: 14px;
      padding: 10px 15px;
      &__icon{
        margin-left: 15px;
      }
    }
  }
  @media(min-width: 1025px) and (max-width: 1279px) {
    &__button{
      font-size: 12px;
      padding: 10px;
      &__icon{
        margin-left: 15px;
      }
    }
  }
  @media(max-width: 1024px) {
    border-bottom: 1px solid #D3D3D3;
    &__button {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 10px 30px 10px 20px;
      border-right: none;

      &__icon {
        margin-left: auto;
      }
    }
  }
}

.list {
  position: absolute;
  top: calc(100% + 10px);
  min-width: 100%;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 4px 4px;
  padding: 20px 0;
  z-index: 20;
  width: max-content;

  &__item {
    cursor: pointer;
    padding: 5px 12px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #333;

    &__check {
      margin-right: 8px;
      width: 21px;
      height: 21px;
      background: transparent;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #828282;

      &.checked {
        background: #333;
        border: 1px solid #333;
      }
    }

    &:hover {
      background: #DADADA;
    }
  }

  @media(max-width: 1024px) {
    position: unset;
    width: 100%;
    top: 100%;
    box-shadow: none;
    border-radius: 0;
    padding: 5px;
    &__item{
      padding: 5px 20px;
    }
  }
}
</style>
