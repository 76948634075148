<template>
  <label
    class="input-with-currency">
    <span class="input-with-currency__left" v-html="textLeft"></span>

    <input
      class="flex-grow w-full focus:outline-none pl-1"
      v-bind="$attrs"
      :value="$attrs.modelValue"
      @input="onValueChange"
    />

    <span class="input-with-currency__right" v-html="textRight" />
  </label>
</template>

<script>
import { formatPrice } from "@/helpers/formatPrice";
export default {
  name: "InputWithCurrency",
  props: {
    textLeft: String,
    textRight: String,
    value: String,
    needFormat: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  emits: ["update:modelValue"],
  methods: {
    formatPrice: (event) => {
      const toCurrency = (n, curr, LanguageFormat = undefined) =>
        Intl.NumberFormat(LanguageFormat, {
          style: "currency",
          currency: curr,
        }).format(n);
      let target = event.target;
      if (target.value === "") {
        return;
      }
      let value = parseInt(target.value);

      target.value = toCurrency(value, "RUB", "Ru-ru");
    },
    onValueChange(event) {
      let value = event.target.value;
      if (this.needFormat) value = formatPrice(value);
      event.target.value = value;
      this.$emit("update:modelValue", value);
    },
  },
};
</script>

<style scoped lang="scss">
input {
  min-width: 70px;
}
.input-with-currency{
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #C9C9C9;
  box-sizing: border-box;
  border-radius: 5px 5px 4px 4px;
  padding: 7px 12px;
  height: 36px;
  &__left{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #C9C9C9;
  }
  &__right{
    font-size: 16px;
    line-height: 150%;
    text-align: right;
  }
}
</style>
