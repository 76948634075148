
import {defineComponent} from "vue";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "TagsList",
  props: {
    copy: {
      type: Boolean,
      default: (): boolean => {
        return false
      }
    }
  },
  computed: {
    ...mapGetters("search", ["GET_TAGS"]),
    tags(): any {
      if (this.copy) return this.GET_TAGS.map();
      else return this.GET_TAGS;
    }
  },
  methods: {
    DeleteFromTag(data: any): void {
      this.$store.commit("search/DELETE_FILTER_DATA_FROM_TAG", data);
      this.$store.dispatch("search/Search");
    },
    ClearFilter(){
      this.$store.dispatch("search/ClearFilterAndSearch");
    }
  },
});
