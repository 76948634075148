<template>
  <div class="relative">
    <span class="label">{{
      label
    }}</span>
    <div class="flex w-full">
      <div class="pr-1">
        <InputWithCurrency
          v-model="model_from"
          text-left="от"
          :text-right="unit"
          :needFormat="needFormat"
        />
      </div>
      <div class="pl-1">
        <InputWithCurrency
          v-model="model_to"
          text-left="до"
          :text-right="unit"
          :needFormat="needFormat"
          @blur="validate"
        />
      </div>
    </div>
    <div v-if="childs.length" class="childs">
      <div class="offseted">
        <base-checkbox
          v-on:changeValue="SetCheckboxValue"
          :checked="not_first"
          name="not_first"
          label="Не первый"
        />
      </div>
      <base-checkbox
        v-on:changeValue="SetCheckboxValue"
        :checked="not_last"
        name="not_last"
        label="Не последний"
      />
    </div>
  </div>
</template>

<script>
import InputWithCurrency from "@/components/base/input/InputWithCurrency";
import BaseCheckbox from "../../../components/base/checkbox/BaseCheckbox";
import { createNamespacedHelpers } from "vuex";
import { mapMutations } from "vuex";

const { mapGetters } = createNamespacedHelpers("search");

export default {
  name: "SplitInputs",
  components: {
    BaseCheckbox,
    InputWithCurrency,
  },
  data() {
    return {
      error: false,
    };
  },
  props: {
    label: String,
    name: String,
    value: Object,
    tab: String,
    childs: {
      type: Array,
      default: () => {
        return [];
      },
    },
    unit: String,
  },
  computed: {
    ...mapGetters(["GET_SPLIT_INPUT_DATA", "GET_INPUT_VALUE"]),
    model_from: {
      get() {
        let payload = {
          name: this.name,
          tab: this.tab,
          type: "from",
        };
        return this.GET_SPLIT_INPUT_DATA(payload);
      },
      set(value) {
        let payload = {
          name: this.name,
          tab: this.tab,
          value: value,
          label: this.label,
          value_label: "от",
          type: "split_from",
        };

        this.$store.commit("search/SET_ADDITIONAL_FILTER_SELECT_DATA", payload);
      },
    },
    model_to: {
      get() {
        let payload = {
          name: this.name,
          tab: this.tab,
          type: "to",
        };
        return this.GET_SPLIT_INPUT_DATA(payload);
      },
      set(value) {
        let payload = {
          name: this.name,
          tab: this.tab,
          value: value,
          label: this.label,
          value_label: "до",
          type: "split_to",
        };
        this.$store.commit("search/SET_ADDITIONAL_FILTER_SELECT_DATA", payload);
      },
    },
    needFormat() {
      let res = true;
      if (this.name === "floor") res = false;
      else if (this.name === "built_year") res = false;
      else res = true;

      return res;
    },
    not_last() {
      return this.$store.state.search.additional_filter[this.tab].floor
        ?.not_last;
    },
    not_first() {
      return this.$store.state.search.additional_filter[this.tab].floor
        ?.not_first;
    },
  },
  methods: {
    ...mapMutations("search", ["SET_ADDITIONAL_FILTER_SELECT_DATA"]),
    validate() {
      if (this.model_to !== "" && this.model_from !== "") {
        this.error = this.model_from > this.model_to;
      }
    },
    SetCheckboxValue(data) {
      this.SET_ADDITIONAL_FILTER_SELECT_DATA({
        ...data,
        tab: this.tab,
        name: "floor",
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.childs {
  position: absolute;
  left: 0;
  top: calc(100% + 3px);
  width: 100%;
  display: flex;
}

.offseted {
  margin-right: 59px;
  @media(max-width: 355px){
    margin-right: 20%;
  }
}
.label {
  display: block;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 12px;
  @apply text-black;
}
</style>
