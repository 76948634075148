import { render } from "./FieldsList.vue?vue&type=template&id=1317ba5c&scoped=true"
import script from "./FieldsList.vue?vue&type=script&lang=js"
export * from "./FieldsList.vue?vue&type=script&lang=js"

const cssModules = script.__cssModules = {}
import style0 from "./FieldsList.vue?vue&type=style&index=0&id=1317ba5c&module=true&scoped=true&lang=scss"
cssModules["$style"] = style0
script.render = render
script.__scopeId = "data-v-1317ba5c"

export default script