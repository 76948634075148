
import {defineComponent, PropType} from "vue";
import {Flat} from "@/typings/types";
import FormatNum from "@/ts-helpers/formatNum";

export default defineComponent({
  name: "ResidentialComplexFlat",
  props: {
    flat: {
      type: Object as PropType<Flat>,
      required: true
    }
  },
  emits: ["close"],
  computed: {
    price(): string {
      return FormatNum(this.flat.price ?? 0)
    },
    flat_type(): string {

      return `${this.flat.rooms_count} комн. квартира`;
    },
    boxOffset(): string {
      const container = document.querySelector<HTMLElement>('.container.mx-auto');
      if (window.innerWidth < 1024) return "unset";
      if (container && window.innerWidth < 1530) return `${container.offsetLeft + 35}px`;
      if (container !== null) return `${container.offsetLeft + 97}px`;
      else return "50%";
    }
  },
  methods: {
    close_adv(): void {
      this.$emit("close");
    },
  },

})
