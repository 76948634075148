<template>
  <div class="document-dropdown">
    <div class="document-dropdown__button">
      <div class="document-dropdown__button__left">
        <folder />
        <div class="document-dropdown__button__text">
          <div class="document-dropdown__button__title">{{ title }}</div>
          <div class="document-dropdown__button__files-count">
            {{ files_count }} файлов
          </div>
        </div>
      </div>
      <chevronDown />
    </div>
    <div class="document-dropdown__list">
      <div class="document-dropdown__item"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DocumentDropdown",
  props: {
    title: {
      type: String,
      required: true,
    },
    files_count: {
      type: Number,
      default: (): number => {
        return 0;
      },
    },
  },
  data() {
    return {
      show: false,
    };
  },
});
</script>

<style scoped lang="scss">
.document-dropdown {
  &__button {
    @apply flex flex-row items-center justify-between bg-milk rounded-md px-5 py-2 cursor-pointer;
    &__left {
      @apply flex items-center;
    }

    &__text {
      @apply pl-5;
    }

    &__title {
      @apply text-black font-semibold text-lg;
    }

    &__files-count {
      @apply text-sm text-gray font-semibold;
    }
  }
}
</style>
