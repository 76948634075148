import { render } from "./ResidentalComplexTop.vue?vue&type=template&id=ddfd0316&scoped=true"
import script from "./ResidentalComplexTop.vue?vue&type=script&lang=ts"
export * from "./ResidentalComplexTop.vue?vue&type=script&lang=ts"

const cssModules = script.__cssModules = {}
import style0 from "./ResidentalComplexTop.vue?vue&type=style&index=0&id=ddfd0316&scoped=true&module=true&lang=scss"
cssModules["$style"] = style0
import "./ResidentalComplexTop.vue?vue&type=style&index=1&id=ddfd0316&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-ddfd0316"

export default script