<template>
  <div>
    <p v-if="less  && NeedTrim">{{ CroppedText }} <span class="text-blue font-bold cursor-pointer" @click="less = false">Раскрыть</span></p>
    <p v-else >{{ text }} <span class="text-blue font-bold cursor-pointer" v-if="NeedTrim" @click="less = true">Скрыть</span></p>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "TextDropdown",
  props: {
    text: {
      type: String,
      required: true
    },
    maxLen: {
      type: Number,
      default: ():number =>{
        return 200;
      }
    }
  },
  data() {
    return {
      less: true,
    }
  },
  computed: {
    NeedTrim(): boolean {
      return this.text.length > this.maxLen;
    },
    CroppedText(): string {
      return this.text.slice(0, this.maxLen) + "...";
    }
  }
})
</script>

<style scoped lang="scss">
p{
  white-space: pre-line;
}
</style>
