<template>
  <div class="container mx-auto py-5">
    <h1 class="text-5xl font-bold mb-5">
      Новости рынка <span class="text-blue">недвижимости</span>
    </h1>
    <NewsNav />
    <NewsList />
  </div>
</template>

<script>
import NewsNav from "@/views/News/Components/NewsNav";
import NewsList from "@/views/News/Components/NewsList";

export default {
  name: "News",
  components: {
    NewsNav,
    NewsList,
  },
};
</script>

<style scoped></style>
