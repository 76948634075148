import { render } from "./NewsItem.vue?vue&type=template&id=2f693a96&scoped=true"
import script from "./NewsItem.vue?vue&type=script&lang=js"
export * from "./NewsItem.vue?vue&type=script&lang=js"

const cssModules = script.__cssModules = {}
import style0 from "./NewsItem.vue?vue&type=style&index=0&id=2f693a96&scoped=true&module=true&lang=css"
cssModules["$style"] = style0
script.render = render
script.__scopeId = "data-v-2f693a96"

export default script