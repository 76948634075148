<template>
  <router-link
    :to="LinkToDetail"
    @click="SavePosition"
    class="object-card"
    target="_blank">
    <div v-if="isResidentalComplex">
      <div v-for="item in WrapperCount" :key="item"
           :style="{top: item * 5 + 'px',left: item * 5 + 'px',zIndex: item % 2}"
           class="counter"></div>
    </div>
    <div class="object-card__box">
      <!--  Изображения объекта  -->
      <div class="object-card__box__images">
        <ImagesCarousel :photos="product.photos" :delay="250" v-if="product.photos.length"/>
        <img src="@/assets/no-images.svg" alt="" v-else>
      </div>
      <!--  Информация о объекте  -->
      <div v-if="!isResidentalComplex" class="object-card__box__object-info">
        <div class="object-card__box__address">{{ TitleInBox(product.address,43) }}</div>
        <div class="object-card__box__info">
          <div class="object-card__box__object-info__category">{{ category }}</div>
          <div class="object-card__box__seller">
            <div class="object-card__box__seller__type" v-if="product.seller?.name?.toLowerCase() !== 'компания loft'">
              {{ product.seller.category }}
            </div>
            <div class="object-card__box__seller__name">{{ product.seller.name }}</div>
          </div>
        </div>
        <div class="object-card__box__params">
          <div class="object-card__box__params__param" v-for="param in product.object_info.parameters_list"
               :key="param.name">
            <div class="object-card__box__params__param__label" v-html="param.name"/>
            <div class="object-card__box__params__param__value"
                 v-html="(typeof param.value === 'number') ? FormatNum(param.value) + ' ₽' : param.value"/>
          </div>
        </div>
        <div class="object-card__box__footer">
          <div v-if="product.object_info.residential_complex_name"
               class="object-card__box__object-info__residential-complex-name first-letter-upper">
            {{ product.object_info.residential_complex_name }}
          </div>
          <div class="object-card__box__price">{{ price }}</div>
        </div>
      </div>
      <div v-else class="object-card__box__residential-complex-info">
        <div class="object-card__box__address">{{ TitleInBox(product.address,40) }}</div>
        <div class="object-card__box__info">
          <div class="object-card__box__seller">
            <div class="object-card__box__seller__type">{{ product.seller.category }}</div>
            <div class="object-card__box__seller__name">{{ product.seller?.name }}</div>
          </div>
        </div>
        <div class="object-card__box__params">
          <div class="object-card__box__params__param">
            <div class="object-card__box__params__param__label">Жилой комплекс</div>
            <div class="object-card__box__params__param__value">{{ product.residential_complex_info?.name }}</div>
          </div>
          <div class="object-card__box__params__param" v-if="product.residential_complex_info?.finish_at">
            <div class="object-card__box__params__param__label">Сдача</div>
            <div class="object-card__box__params__param__value">{{ product.residential_complex_info?.finish_at }}</div>
          </div>
          <div class="object-card__box__params__param" v-if="ResidentalComplexPricePerUnit">
            <div class="object-card__box__params__param__label">Цена за м<sup>2</sup></div>
            <div class="object-card__box__params__param__value">{{ ResidentalComplexPricePerUnit }}</div>
          </div>
        </div>
        <div class="object-card__box__footer">
          <div class="object-card__box__residential-complex-info__flats">
            {{ product.residential_complex_info?.filtered_flats_count }}
            {{ flats_formatted }}
          </div>
          <div class="object-card__box__price">от {{ FormatNum(product.residential_complex_info.price_from) }} ₽</div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {OfferCard, Link} from "@/typings/types";
import declOfNum from "@/ts-helpers/declOfNum";
import FormatNum from "@/ts-helpers/formatNum";
import TitleInBox from "@/ts-helpers/TitleInBox";
import TYPES from "@/constants/FilterTypes";
import ImagesCarousel from "@/components/base/images/ImagesCarousel.vue";

export default defineComponent({
  name: "ObjectGridCard",
  props: {
    product: {
      required: true,
      type: Object as PropType<OfferCard>,
    },
  },
  components: {
    ImagesCarousel
  },
  data(): {
    currentImage: number;
    time_start: any;
    TIME_TO_SHOW: any;
    canUse: boolean;
    preloader: boolean;
  } {
    return {
      currentImage: 0,
      time_start: null,
      TIME_TO_SHOW: 250,
      canUse: false,
      preloader: true,
    };
  },
  computed: {
    isResidentalComplex(): boolean {
      return !!this.product.residential_complex_info
    },
    currency(): string {
      let curr = "₽";
      if (this.product.object_info?.currency !== "RUR") curr = "$";
      return curr;
    },
    price(): string {
      let num: string = "";
      if (this.product.object_info?.price)
        num = FormatNum(this.product.object_info?.price);
      return `${num} ${this.currency}`;
    },
    category(): string {
      let res = this.product.object_info?.category;
      if (res === TYPES.flat) res = `${this.product.object_info?.rooms_count}-комн. ${this.product.object_info?.category}`;
      return res ?? "";
    },
    ResidentalComplexPricePerUnit(): string {
      return `${FormatNum(this.product?.residential_complex_info?.price_per_unit ?? 0)} ₽`
    },
    ResidentalComplexPrice(): string {
      return `${FormatNum(this.product?.residential_complex_info?.price_from ?? 0)} ₽`;
    },
    LinkToDetail(): Link {
      if (this.isResidentalComplex) {
        return {name: "ResidentialComplex", params: {id: this.product.id}}
      } else
        return {name: "Offer", params: {id: this.product.id}}
    },
    WrapperCount(): number {
      let res = 0;
      let flats_count = this.product.residential_complex_info?.filtered_flats_count;
      let breakpoints = [30, 70];
      breakpoints.map(item => {
        (flats_count && flats_count > item) ? res++ : null;
      });
      return res;
    },
    flats_formatted(): string {
      let tmp = this.product.residential_complex_info?.filtered_flats_count ?? 0;
      return declOfNum(tmp, ["квартира", "квартиры", "квартир"]);
    }
  },
  methods: {
    FormatNum: FormatNum,
    TitleInBox: TitleInBox,
    setImage(index: number): void {
      if (this.canUse) {
        this.currentImage = index;
        this.preloader = true;
      }
    },
    AllowToUse() {
      this.canUse = Date.now() - this.time_start >= this.TIME_TO_SHOW;
    },
    startTimer(): void {
      this.time_start = Date.now();
    },
    endTimer(): void {
      this.time_start = null;
    },
    OnImageLoading(): void {
      this.preloader = false;
    },
    SavePosition(): void {
      localStorage.setItem('saved_position', `${pageYOffset}`);
    },

  },
});
</script>

<style scoped lang="scss">
.object-card {
  position: relative;
  display: flex;
  flex-direction: column;

  .counter {
    position: absolute;
    left: 5px;
    top: 5px;
    background: #fff;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.11);
    width: 100%;
    height: 100%;
    @apply rounded;
    @media(max-width: 768px) {
      display: none;
    }
  }

  &__box {
    overflow: hidden;
    border-radius: 4px;
    filter: drop-shadow(4px 4px 7px rgba(0, 0, 0, 0.11));
    z-index: 2;

    &__images {
      min-height: 240px;
      max-height: 240px;
      height: 240px;
      overflow-y: hidden;
      background: #eee;

      img {
        width: 100%;
        min-width: 100%;
        height: 100%;
        min-height: 240px;
        object-fit: cover;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }

      &.loading {
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
      }
    }


    &__object-info {
      padding: 20px 15px;
      display: flex;
      flex-direction: column;
      background: #fff;
      height: 220px;

      &__category {
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 150%;
        color: #333;
      }

      &__residential-complex-name {
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 117%;
        color: #3D7E9C;
        max-width: 50%;
      }
    }

    &__residential-complex-info {
      padding: 20px 15px;
      display: flex;
      flex-direction: column;
      background: #fff;
      height: 220px;

      &__flats {
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 150%;
        color: #3D7E9C;
      }
    }

    &__address {
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 117%;
      color: #333;
      white-space: pre-line
    }

    &__seller {
      margin-left: auto;

      &__type {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        text-align: right;
        color: #B4B4B4;
      }

      &__name {
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 150%;
        text-align: right;
        color: #333;
      }
    }

    &__params {
      display: flex;
      justify-content: space-between;

      &__param {
        box-sizing: border-box;
        padding-right: 15px;
        padding-left: 15px;

        &:first-child {
          border-right: 1px solid #B4B4B4;
          padding-left: 0;
        }

        &:nth-child(2) {
          padding: 0;
        }

        &:last-child {
          border-left: 1px solid #B4B4B4;
          padding-right: 0;
        }

        &__label {
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 150%;
          color: #B4B4B4;
        }

        &__value {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 150%;
          color: #333;
        }
        @media(max-width: 768px){
          &__label{
            font-size: 11px;
          }
        }
      }
    }

    &__price {
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 150%;
      text-align: right;
      color: #333;
      margin-left: auto;
    }

    &__footer {
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__info {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: auto;
      margin-bottom: 8px;
    }

  }

}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
</style>
