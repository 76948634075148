<template>
  <div>
    <div class="liter-select">
      <div class="liter-select__button" :class="{show: show}" @click="Toggle">
        <span class="text">{{ currentValue }}</span>
        <div class="chevron">
          <chevronDown fill="#828282"/>
        </div>
      </div>
      <div class="liter-select__list" v-if="show">
        <div class="liter-select__list__item" v-for="item in data" :key="item" @click="SetLiter(item.value)"
             :class="{active: currentLiter === item.value}">{{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {Param} from "@/typings/types";

export default defineComponent({
  name: "LiterSelect",
  emits: ["valueSelect"],
  props: {
    data: {
      type: [Object] as PropType<Param[]>,
      required: true
    },
    currentLiter: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    currentValue(): string {
      return this.data?.find(item => item.value.toString() === this.currentLiter)?.name ?? "Выбор литера"
    }
  },
  methods: {
    Toggle(): void {
      this.show = !this.show;
    },
    SetLiter(item: string): void {
      this.show = false;
      this.$emit('valueSelect', item);
    }
  }
})
</script>

<style scoped lang="scss">
.liter-select {
  position: relative;

  &__button {
    border: 1px solid #C9C9C9;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    height: 36px;
    padding: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.show {
      border-radius: 4px 4px 0 0;
      border: 1px solid transparent;
    }

    .text {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      color: #333;
    }

    .chevron {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  &__list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 20;
    background: #fff;
    padding: 8px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 4px 4px;

    &__item {
      padding: 5px 12px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #333;
      cursor: pointer;

      &:hover, &.active {
        background: #DADADA;
      }
    }
  }
}
</style>
