
import {defineComponent, PropType} from "vue";
import {OfferCard, Link} from "@/typings/types";
import declOfNum from "@/ts-helpers/declOfNum";
import FormatNum from "@/ts-helpers/formatNum";
import TitleInBox from "@/ts-helpers/TitleInBox";
import TYPES from "@/constants/FilterTypes";
import ImagesCarousel from "@/components/base/images/ImagesCarousel.vue";

export default defineComponent({
  name: "ObjectGridCard",
  props: {
    product: {
      required: true,
      type: Object as PropType<OfferCard>,
    },
  },
  components: {
    ImagesCarousel
  },
  data(): {
    currentImage: number;
    time_start: any;
    TIME_TO_SHOW: any;
    canUse: boolean;
    preloader: boolean;
  } {
    return {
      currentImage: 0,
      time_start: null,
      TIME_TO_SHOW: 250,
      canUse: false,
      preloader: true,
    };
  },
  computed: {
    isResidentalComplex(): boolean {
      return !!this.product.residential_complex_info
    },
    currency(): string {
      let curr = "₽";
      if (this.product.object_info?.currency !== "RUR") curr = "$";
      return curr;
    },
    price(): string {
      let num: string = "";
      if (this.product.object_info?.price)
        num = FormatNum(this.product.object_info?.price);
      return `${num} ${this.currency}`;
    },
    category(): string {
      let res = this.product.object_info?.category;
      if (res === TYPES.flat) res = `${this.product.object_info?.rooms_count}-комн. ${this.product.object_info?.category}`;
      return res ?? "";
    },
    ResidentalComplexPricePerUnit(): string {
      return `${FormatNum(this.product?.residential_complex_info?.price_per_unit ?? 0)} ₽`
    },
    ResidentalComplexPrice(): string {
      return `${FormatNum(this.product?.residential_complex_info?.price_from ?? 0)} ₽`;
    },
    LinkToDetail(): Link {
      if (this.isResidentalComplex) {
        return {name: "ResidentialComplex", params: {id: this.product.id}}
      } else
        return {name: "Offer", params: {id: this.product.id}}
    },
    WrapperCount(): number {
      let res = 0;
      let flats_count = this.product.residential_complex_info?.filtered_flats_count;
      let breakpoints = [30, 70];
      breakpoints.map(item => {
        (flats_count && flats_count > item) ? res++ : null;
      });
      return res;
    },
    flats_formatted(): string {
      let tmp = this.product.residential_complex_info?.filtered_flats_count ?? 0;
      return declOfNum(tmp, ["квартира", "квартиры", "квартир"]);
    }
  },
  methods: {
    FormatNum: FormatNum,
    TitleInBox: TitleInBox,
    setImage(index: number): void {
      if (this.canUse) {
        this.currentImage = index;
        this.preloader = true;
      }
    },
    AllowToUse() {
      this.canUse = Date.now() - this.time_start >= this.TIME_TO_SHOW;
    },
    startTimer(): void {
      this.time_start = Date.now();
    },
    endTimer(): void {
      this.time_start = null;
    },
    OnImageLoading(): void {
      this.preloader = false;
    },
    SavePosition(): void {
      localStorage.setItem('saved_position', `${pageYOffset}`);
    },

  },
});
