<template>
  <div class="about-developer">
    <div class="about-developer__back">
      <svg
        width="47"
        height="14"
        viewBox="0 0 47 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.21031 -3.47853e-06L9.96116 2.66144L7.44634 5.09461L47 5.09462L47 8.85851L7.44634 8.85851L9.96116 11.2917L7.21031 13.9531L-6.09911e-07 6.97656L7.21031 -3.47853e-06Z"
          fill="#DADADA"
        />
      </svg>
    </div>
    <div class="about-developer__region"></div>
    <div class="about-developer__content">
      <div class="about-developer__name"></div>
      <div class="about-developer__info-container"></div>
      <div class="about-developer__text"></div>
    </div>
    <div class="about-developer__image"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AboutDeveloperLoading",
});
</script>

<style scoped lang="scss">
.about-developer {
  position: relative;
  background: #fff;
  border-radius: 5px;
  display: flex;
  min-height: 242px;
  padding: 20px 34px;

  &__region {
    position: absolute;
    right: 253px;
    top: 18px;
    width: 261px;
    height: 27px;
  }

  &__content {
    width: calc(100% - 255px);
    max-width: 100%;
  }

  &__back {
    position: absolute;
    left: 32px;
    top: 12px;
  }

  &__name {
    margin-top: 15px;
    width: 137px;
    height: 54px;
  }

  &__info-container {
    width: 637px;
    height: 54.69px;
    margin-top: 15px;
  }

  &__text {
    width: 100%;
    height: 47px;
    margin-top: 15px;
  }

  &__image {
    width: 182px;
    height: 182px;
    border-radius: 4px;
    margin-left: auto;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.04);
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-right: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.04);
    }
  }

  &__region,
  &__name,
  &__text,
  &__info-container,
  &__image {
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    animation: 1.5s shine linear infinite;
    background-size: 200% 100%;
  }

  @media (max-width: 1024px) {
    &__info-container {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    border-radius: 0;
    &__region {
      margin-bottom: 15px;
    }
    &__image {
      order: 1;
      margin-left: 0;
    }
    &__region,
    &__back {
      position: unset;
      padding-bottom: 15px;
    }
    &__content {
      max-width: 100%;
      width: 100%;
      order: 2;
    }
  }
  @media (max-width: 575px) {
    padding-left: 15px;
    padding-right: 15px;
    &__info-container {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    &__info {
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 15px;

      &::after {
        display: none;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
}
</style>
