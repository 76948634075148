<template>
  <div class="residental-complex-advantage" v-click-outside="Hide">
    <div @click="Toggle" :class="{active: show}" class="flex items-center cursor-pointer hover:text-blue">
      <div class="icon mr-3">
        <house/>
      </div>
      <div class="font-semibold text-xl leading-5">
        {{ adv.name }}
      </div>
    </div>
    <div v-if="show" class="border-2 border-blue rounded p-5 relative bg-white adv">
      <h4 class="text-lg font-semibold mb-3">
        {{ adv.name }}
      </h4>
      <div class="mb-3 residental-complex-advantage__photos">
        <ImagesCarousel :photos="adv.photos"/>
      </div>
      <div class="mb-3">
        {{ adv.description }}
      </div>
      <div class="absolute right-3.5 top-5 cursor-pointer">
        <font-awesome-icon icon="times" @click="Hide"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import ClickOutside from "@/helpers/clickoutside.js";
import ImagesCarousel from "@/components/base/images/ImagesCarousel.vue";
import {Advantage} from "@/typings/types";

export default defineComponent({
  name: "ResidentalComplexAdvantage",
  props: {
    adv: {
      type: Object as PropType<Advantage>,
      required: true
    }
  },
  components: {
    ImagesCarousel
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    Toggle(): void {
      this.show = !this.show;
    },
    Hide(): void {
      this.show = false;
    }
  },
  directives: {
    ClickOutside
  }
})
</script>

<style scoped lang="scss">
.adv {
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.11);
  width: 351px;
  height: 505px;
  position: absolute;
  left: calc(100% + 12px);
  top: 0;
}

.active {
  @apply text-blue;
}

.residental-complex-advantage {
  &__photos {
    height: 203px;
  }
}

@media (max-width: 1280px) {
  .adv {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 95%;
  }
}
</style>
