
import {defineComponent} from "vue";
import {fetch_companies, send_appeal} from "@/api/siteMap.ts";
import {Office} from "@/typings/types";
import {formatPhoneNumber} from "@/helpers/formatPhone";
import {createNamespacedHelpers} from "vuex";
import ContactUs from "./Components/ContactUs.vue";
import Requisites from "./Components/Requisites.vue";
import ImagesCarousel from "@/components/base/images/ImagesCarousel.vue";
import Map from "./Components/Map.vue";

const {mapGetters} = createNamespacedHelpers("serverInfo");

export default defineComponent({
  name: "Contacts",
  data() {
    return {
      work_time: "",
      offices: [] as Office[],
      key: 1,
      current_office: {} as Office,
      write_us: false,
      message: "",
      tags: [
        "Хочу стать партнёром",
        "Хочу купить квартиру",
        "Хочу у вас работать",
        "Хочу продать квартиру",
        "Другой запрос",
      ],
      name: "",
      email: "",
      sended: false,
      animation: "slide-fade",
    };
  },
  components: {
    Map,
    ContactUs,
    Requisites,
    ImagesCarousel,
  },
  computed: {
    ...mapGetters(["getLocality"]),
  },
  async created() {
    await this.fetch_offices();
    await this.recaptcha();
  },
  methods: {
    formatPhoneNumber,
    async fetch_offices() {
      fetch_companies(this.getLocality)
        .then((response: any) => {
          this.work_time = response.data.work_time;
          this.offices = response.data.offices;
        })
    },
    async send_appeal() {
      const captcha_token = await this.recaptcha();

      function validateEmail(email: string): boolean {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }

      if (!validateEmail(this.email)) {
        let email_input = document.getElementById("email");
        if (email_input) {
          email_input.style.border = "1px solid red";
          email_input.focus();
        }
        return;
      }

      const config = {
        city_code: this.getLocality.toString(),
        office_name: this.current_office.office_name,
        name: this.name,
        text: this.message,
        email: this.email,
        'recaptcha-token': captcha_token
      };

      send_appeal(config).then(() => {
        this.sended = true
      })
    },
    async recaptcha() {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('submit');
      return token;
    }
  },
  watch: {
    getLocality: function () {
      this.key++;
      this.write_us = false;
      this.current_office = {} as Office;
      this.fetch_offices();
    },
    current_office: function () {
      if (Object.keys(this.current_office).length > 1) {
        this.animation = "slide-fade";
      } else {
        this.animation = "slide-back";
      }
    },
  },
});
