
import {defineComponent, PropType} from "vue";
import {Param} from "@/typings/types";
import TextDropdown from "@/components/base/dropdown/TextDropdown.vue";

export default defineComponent({
  name: "ParamsList",
  props: {
    params: {
      type: [Object] as PropType<Param[]>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  components: {
    TextDropdown
  }
});
