<template>
  <div class="developer-products pb-10">
    <div class="container mx-auto">
      <div class="developer-products__filter">
        <div class="developer-products__title">
          Новостройки от застройщика в {{ regionInPrepositionalCase }}:
        </div>
      </div>
      <products-loading v-if="loading"/>
      <ObjectsList v-else/>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ProductsLoading from "@/components/base/loaders/ProductsLoading.vue";
import ObjectsList from "@/components/common/Products/ObjectsList.vue";
import PrepositionalCase from "@/ts-helpers/PrepositionalCase";
export default defineComponent({
  name: "DeveloperProducts",
  props: {
    loading: {
      type: Boolean,
      default: (): boolean => {
        return true;
      }
    },
    region: {
      type: String,
      default: (): string => {
        return "";
      }
    }
  },
  computed: {
    regionInPrepositionalCase():string {
      return PrepositionalCase(this.region);
    }
  },
  components: {
    ProductsLoading,
    ObjectsList
  },
});
</script>

<style lang="scss" scoped>
.developer-products {
  &__filter {
    @apply flex items-center justify-between pb-10;
  }

  &__title {
    @apply font-semibold text-black text-3xl;
  }

  &__sort {
    @apply flex items-center text-black text-xl;
  }

  @media (max-width: 1024px) {
    &__title {
      font-size: 24px;
    }
  }
  @media (max-width: 768px) {
    &__filter {
      flex-direction: column;
    }
  }
  @media (max-width: 400px) {
    &__filter {
      flex-direction: column;
    }
    &__title {
      font-size: 20px;
    }
    &__sort {
      font-size: 14px;
    }
  }
}
</style>
