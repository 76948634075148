<template>
  <div class="flex lg:flex-row flex-col lg:space-x-5">
    <div class="rounded bg-white w-full p-5">
      <h3 class="text-black font-bold text-xl lg:text-3xl mb-5">
        Реквизиты компании
      </h3>
      <div class="flex">
        <div class="text-gray">
          <span>
            <a class="info-card" href="/requisites.pdf">Скачать реквизиты</a>
          </span>
          <!-- <span class="info-card">
            ИНН: 234606512400
          </span>
          <span class="info-card">
            ОГРНИП: 319237500462953
          </span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Requisites.vue",
};
</script>

<style scoped lang="scss">
.info-card {
  border-right: 1px solid #D3D3D3;
  padding: 8px 30px;
  text-decoration: underline;
  color: #666;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border-right: none;
  }

}
@media(max-width: 768px) {
 .info-card{
   display: block;
   padding: 0;
   border: none;
   margin-bottom: 10px;
 }
}
</style>
