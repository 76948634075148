
import {defineComponent, PropType} from 'vue';
import {Param} from "@/typings/types";

export default defineComponent({
  name: "LiterSelect",
  emits: ["valueSelect"],
  props: {
    data: {
      type: [Object] as PropType<Param[]>,
      required: true
    },
    currentLiter: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    currentValue(): string {
      return this.data?.find(item => item.value.toString() === this.currentLiter)?.name ?? "Выбор литера"
    }
  },
  methods: {
    Toggle(): void {
      this.show = !this.show;
    },
    SetLiter(item: string): void {
      this.show = false;
      this.$emit('valueSelect', item);
    }
  }
})
