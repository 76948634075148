
import {defineComponent} from "vue";
import {Region, Company} from "@/typings/types";
//components
import AboutDeveloper from "@/views/ForDevelopers/components/AboutDeveloper.vue";
import DeveloperProducts from "@/views/ForDevelopers/components/DeveloperProducts.vue";
import {RawCompanyInfo} from "@/typings/RAW_DATA";

export default defineComponent({
  name: "ForDevelopers",
  components: {
    AboutDeveloper,
    DeveloperProducts
  },
  data() {
    return {
      region: {} as Region,
      loading: true,
      error: false
    };
  },
  mounted() {
    this.loading = false;
  },
  computed: {
    company():Company {return RawCompanyInfo}
  },
  methods: {
    /*
     *
     * Установить новый регион для того чтобы передать его соседнему компоненту со списком
     * новостроек(developer-products),
     * */
    setNewRegion(region: Region): void {
      this.region = region;
    },

  },
});
