<template>
  <div class="similar-objects">
    <div class="similar-objects__title">{{ title }} <span class="similar-objects__sub-title">{{ subTitle }}</span></div>
    <div class="similar-objects__container">
      <ObjectsList :offers="similar" v-if="!loading"/>
      <ProductsLoading :products-count="3" v-else/>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {id, OfferCard} from "@/typings/types";
import {get_random_objects} from "@/api/productsApi";
import ObjectsList from "@/components/common/Products/ObjectsList.vue";
import ProductsLoading from "@/components/base/loaders/ProductsLoading.vue";

export default defineComponent({
  name: "SimilarObjects",
  props: {
    title: {
      type: String,
      default: (): string => {
        return "Похожие объекты";
      }
    },
    subTitle: {
      type: String,
      default: (): string => {
        return "";
      }
    }
  },
  components: {
    ObjectsList,
    ProductsLoading
  },
  data() {
    return {
      loading: true,
      error: false,
      similar: [Object] as PropType<OfferCard[]>
    }
  },
  mounted() {
    this.GetSimilar();
  },
  methods: {
    GetSimilar(): void {
      this.loading = true;
      get_random_objects({count: 3}).then((response) => {
        this.similar = response.data;
        this.loading = false;
      })
    }
  }
})
</script>

<style scoped lang="scss">
.similar-objects {
  @apply mt-5 mb-5;
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @apply text-3xl font-extrabold mb-5;
  }

  &__sub-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    @apply text-gray;
  }
  @media(max-width: 1024px){
    &__title{
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 20px;
      line-height: 100%;
    }
    &__sub-title {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      @apply text-gray;
    }
  }
}
</style>
