export default {
  office: "офис",
  auto_repair: "автосервис",
  beaty: "салон красоты",
  freeToUse: "свободного назначения",
  hotel: "гостиница",
  commercial_land: "земли коммерческого назначения",
  production_room: "производственное помещение",
  catering: "кейтеринг",
  market_square: "торговая площадь",
  store: "магазин",
  car_wash: "автомойка",
  fitness: "фитнес",
  recreation_center: "база отдыха",
  hookah: "кальян",
};
