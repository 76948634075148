<template>
  <div :key="key" class="py-10">
    <div class="container  mx-auto">
      <div class="text-base lg:text-3xl font-semibold mb-5 lg:flex lg:pl-0 pl-4">
        <div class="text-gray mr-2 ">Мы работаем:</div>
        <div class="whitespace-pre text-black">
          {{ work_time }}
        </div>
      </div>
      <div class="rounded bg-white flex flex-wrap mb-5">
        <div class="w-full lg:w-2/3">
          <Map v-if="offices.length" :offices="offices"/>
        </div>
        <div class="w-full lg:w-1/3 relative overflow-x-hidden">
          <transition :name="animation" mode="out-in">
            <div v-if="Object.keys(current_office).length < 1" class="my-7">
              <div
                v-for="office in offices"
                :key="office"
                class="hover:bg-whitegray cursor-pointer"
                @click="current_office = office"
              >
                <div class="py-2 px-5 font-semibold text-2xl">
                  <div class="border-b border-gray text-black flex justify-between items-center text-lg">
                    {{ office.office_name }}
                    <span><chevron-right/></span>
                  </div>
                </div>
                <div class="lg:flex px-5 py-1">
                  <div class="text-sm font-semibold text-gray">
                    {{ office.office_address }}
                  </div>
                  <div class="ml-auto text-blue font-bold">
                    <a
                      :href="'tel:' + office.office_phone"
                      class="whitespace-nowrap"
                    >{{ formatPhoneNumber(office.office_phone) }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="px-5 py-7 relative">
              <div
                class="absolute top-3 right-3 cursor-pointer"
                @click="current_office = {}"
              >
                <font-awesome-icon icon="times"/>
              </div>
              <div class="py-2 border-b border-gray font-semibold text-2xl">
                {{ current_office.office_name }}
              </div>
              <div class="flex py-1 mb-3">
                <div class="text-sm font-semibold text-gray">
                  {{ current_office.office_address }}
                </div>
                <div class="ml-auto text-blue font-bold">
                  <a
                    :href="'tel:' + current_office.office_phone"
                    class="whitespace-nowrap"
                  >{{ formatPhoneNumber(current_office.office_phone) }}</a
                  >
                </div>
              </div>
              <div class="images-carousel">
                <ImagesCarousel
                  :photos="
                  current_office.office_photos.sort((a, b) => {
                    return a.priority - b.priority;
                  })
                "
                  with-lightbox
                />
              </div>
              <div class="mt-3">
                <button
                  class="rounded bg-blue hover:bg-darkblue text-white p-2"
                  @click="write_us = true"
                >
                  Написать нам
                </button>
              </div>
              <transition mode="out-in" name="slide-fade">
                <div
                  v-if="write_us && !sended"
                  class="
                    absolute
                    top-0
                    left-0
                    right-0
                    bottom-0
                    z-10
                    w-full
                    min-h-full
                    text-white
                    px-5
                    py-7
                    bg-blue
                    flex flex-col
                    min-h-100
                  "
                >
                  <div
                    class="absolute top-3 right-3 cursor-pointer"
                    @click="write_us = false"
                  >
                    <font-awesome-icon icon="times"/>
                  </div>
                  <div
                    class="py-2 border-b border-darkblue font-semibold text-2xl"
                  >
                    {{ current_office.office_name }}
                  </div>
                  <div class="lg:flex lg:space-x-2  my-3 justify-between text-black">
                    <input
                      v-model="name"
                      class="bg-white w-full p-2 rounded focus:outline-none lg:mb-0 mb-3"
                      placeholder="Ваше имя*"
                      type="text"
                    />
                    <input
                      id="email"
                      v-model="email"
                      class="bg-white w-full p-2 rounded focus:outline-none"
                      placeholder="Ваш e-mail*"
                      type="email"
                    />
                  </div>
                  <div class="mb-3">
                    <textarea
                      id=""
                      v-model="message"
                      class="
                        bg-white
                        p-3
                        w-full
                        rounded
                        h-28
                        text-black
                        focus:outline-none
                      "
                      cols="20"
                      name=""
                      placeholder="Ваше сообщение"
                      rows="10"
                    ></textarea>
                  </div>
                  <div class="flex flex-wrap">
                    <div
                      v-for="tag in tags"
                      :key="tag"
                      :class="message === tag ? 'opacity-100' : 'opacity-60'"
                      class="
                        border-2 border-white
                        py-1
                        px-2
                        text-sm
                        rounded
                        cursor-pointer
                        mr-2
                        mb-2
                        hover:opacity-100
                        font-semibold
                      "
                      @click="message = tag"
                    >
                      {{ tag }}
                    </div>
                  </div>
                  <div class="mt-auto bottom-7">
                    <button
                      class="bg-white text-blue rounded px-3 py-2 font-semibold block ml-auto"
                      @click="send_appeal"
                    >
                      Отправить
                    </button>
                  </div>
                </div>
              </transition>
            </div>
          </transition>
        </div>
      </div>
      <ContactUs/>
      <Requisites/>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {fetch_companies, send_appeal} from "@/api/siteMap.ts";
import {Office} from "@/typings/types";
import {formatPhoneNumber} from "@/helpers/formatPhone";
import {createNamespacedHelpers} from "vuex";
import ContactUs from "./Components/ContactUs.vue";
import Requisites from "./Components/Requisites.vue";
import ImagesCarousel from "@/components/base/images/ImagesCarousel.vue";
import Map from "./Components/Map.vue";

const {mapGetters} = createNamespacedHelpers("serverInfo");

export default defineComponent({
  name: "Contacts",
  data() {
    return {
      work_time: "",
      offices: [] as Office[],
      key: 1,
      current_office: {} as Office,
      write_us: false,
      message: "",
      tags: [
        "Хочу стать партнёром",
        "Хочу купить квартиру",
        "Хочу у вас работать",
        "Хочу продать квартиру",
        "Другой запрос",
      ],
      name: "",
      email: "",
      sended: false,
      animation: "slide-fade",
    };
  },
  components: {
    Map,
    ContactUs,
    Requisites,
    ImagesCarousel,
  },
  computed: {
    ...mapGetters(["getLocality"]),
  },
  async created() {
    await this.fetch_offices();
    await this.recaptcha();
  },
  methods: {
    formatPhoneNumber,
    async fetch_offices() {
      fetch_companies(this.getLocality)
        .then((response: any) => {
          this.work_time = response.data.work_time;
          this.offices = response.data.offices;
        })
    },
    async send_appeal() {
      const captcha_token = await this.recaptcha();

      function validateEmail(email: string): boolean {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }

      if (!validateEmail(this.email)) {
        let email_input = document.getElementById("email");
        if (email_input) {
          email_input.style.border = "1px solid red";
          email_input.focus();
        }
        return;
      }

      const config = {
        city_code: this.getLocality.toString(),
        office_name: this.current_office.office_name,
        name: this.name,
        text: this.message,
        email: this.email,
        'recaptcha-token': captcha_token
      };

      send_appeal(config).then(() => {
        this.sended = true
      })
    },
    async recaptcha() {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('submit');
      return token;
    }
  },
  watch: {
    getLocality: function () {
      this.key++;
      this.write_us = false;
      this.current_office = {} as Office;
      this.fetch_offices();
    },
    current_office: function () {
      if (Object.keys(this.current_office).length > 1) {
        this.animation = "slide-fade";
      } else {
        this.animation = "slide-back";
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.line {
  @apply border-b border-whitegray border-solid flex mb-5 pb-2 border-opacity-50;
}

.slide-fade-enter-active {
  transition: all 0.3s linear;
}

.slide-fade-leave-active {
  transition: all 0.5s linear;
}

.slide-fade-enter-from {
  transform: translateX(100%);
}

.slide-fade-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-back-enter-active {
  transition: all 0.3s linear;
}

.slide-back-leave-active {
  transition: all 0.5s linear;
}

.slide-back-enter-from {
  transform: translateX(-100%);
}

.slide-back-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.images-carousel {
  height: 330px;
}
</style>
