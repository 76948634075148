<template>
  <div class="complex-flats bg-white rounded-md p-5 mt-5 mb-5 relative">
    <div :class="$style.plan_wrapper">
      <div class="flex flex-wrap lg:flex-nowrap items-center mb-4">
        <div class="complex-flats__title">Подобрать квартиру</div>
        <div class="lg:ml-auto mt-3 lg:mt-0 font-semibold text-lg">
<!--          <button-->
<!--            class="complex-flats__change-view mr-3"-->
<!--            :class="{ 'active': GraphView }"-->
<!--            @click="SetView(true)"-->
<!--          >-->
<!--            На плане-->
<!--          </button>-->
<!--          <button-->
<!--            class="complex-flats__change-view"-->
<!--            :class="{ 'active': !GraphView }"-->
<!--            @click="SetView(false)"-->
<!--          >-->
<!--            Списком-->
<!--          </button>-->
        </div>
      </div>
      <div class="flex flex-wrap lg:flex-nowrap">
        <!--   Фильтр     -->
        <div :class="$style.plan__filter">
          <div class="pr-5">
            <h4 class="my-2 font-xl font-semibold flex items-center">
              <filter-icon fill="#3D7E9C"/>
              <span class="pl-2">Фильтры</span>
            </h4>
            <!--      Литеры          -->
            <div v-if="sections.length > 1">
              <p class="block text-sm leading-5 font-medium text-gray-700 mt-3 mb-3">Выбор литера</p>
              <LiterSelect :data="sections" :current-liter="current_liter" @valueSelect="SetLiter"/>
            </div>
            <!--      Цена          -->
            <div>
              <span class="block text-sm leading-5 font-medium text-gray-700 mt-3 mb-3">Цена</span>
              <div class="flex w-full">
                <div class="pr-2 w-full">
                  <InputWithCurrency
                    v-model="filter.price_from"
                    text-left="от"
                    text-right="₽"
                  />
                </div>
                <div class="pl-2 w-full">
                  <InputWithCurrency
                    v-model="filter.price_to"
                    text-left="до"
                    text-right="₽"
                  />
                </div>
              </div>
            </div>
            <!--      Комнаты          -->
            <div>
              <span class="block text-sm leading-5 font-medium text-gray-700 mt-3 mb-3">Комнаты</span>
              <div class="flex w-full justify-between">
                <button
                  class="room"
                  :class="{'room__active': filter.rooms.includes(flat.value)}"
                  v-for="flat in flat_types"
                  :key="flat"
                  @click="SetRoom(flat.value)"
                >
                  <span class="block mx-auto">{{ flat.name }}</span>
                </button>
              </div>
            </div>
            <!--      Общая площадь       -->
            <div>
              <span class="block text-sm leading-5 font-medium text-gray-700 mt-3 mb-3">Общая площадь</span>
              <div class="flex w-full">
                <div class="pr-2 w-full">
                  <InputWithCurrency
                    v-model="filter.area_from"
                    text-left="от"
                    :text-right="'м<sup>2</sup>'"
                  />
                </div>
                <div class="pl-2 w-full">
                  <InputWithCurrency
                    v-model="filter.area_to"
                    text-left="до"
                    :text-right="'м<sup>2</sup>'"
                  />
                </div>
              </div>
            </div>
            <!--      Этаж          -->
            <div>
              <span class="block text-sm leading-5 font-medium text-gray-700 mt-3 mb-3">Этаж</span>
              <div class="flex w-full">
                <div class="pr-2 w-full">
                  <InputWithCurrency v-model="filter.floor_from" text-left="от"/>
                  <default-checkbox class="mt-2" v-model="filter.not_first" label="Не первый"/>
                </div>
                <div class="pl-2 w-full">
                  <InputWithCurrency v-model="filter.floor_to" text-left="до"/>
                  <default-checkbox class="mt-2" v-model="filter.not_last" label="Не последний"/>
                </div>
              </div>
            </div>
            <!--      Тип предложения          -->
            <div
              class="block text-sm leading-5 font-medium text-gray-700 mt-3 mb-3"
            >
              Предложения
            </div>
            <div class="flex mt-3">
              <default-checkbox label="От застройщика" v-model="filter.from_builder"/>
              <default-checkbox class="from_buyer" primary-color label="От собственника" v-model="filter.from_buyer"/>
            </div>
            <div class="mt-5 filter-footer">
              <p>Предложений найдено: {{ flats.length }}</p>
              <p class="clear" @click="ClearFilter">Очистить</p>
            </div>
          </div>
        </div>
        <div class="plan" :class="{graph: GraphView}">
          <div class="filter-bubbles" v-if="GraphView">
            <div class="filter-bubbles__item" v-for="item in filter_bubbles" :key="item">
              <div class="filter-bubbles__item__name">{{ item.name }}</div>
              <div class="filter-bubbles__item__check" :style="{borderColor: item.border,background: item.bg}"/>
            </div>
          </div>
          <!--     Вид "шахматкой"         -->
          <div class="graph-view" v-if="GraphView">
            <Floor class="floor" v-for="(floor,i) in floors" :key="i"
                   :total-floors="floors.length"
                   :current-floor="floors.length - i"
                   :flats="floor.flats" :min-len="minFlatsCount"
                   :filter="filter"/>
          </div>
          <!--     Вид списком            -->
          <div class="space-y-5 list-view" v-else :class="$style.list_wrapper">
            <FlatsList :flats="flats" :filter="filter"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {Flat, Param, FloorInterface} from "@/typings/types";
import Floor from "@/components/common/ResidentialComplexFlats/components/Floor.vue";
import FlatsList from "@/components/common/ResidentialComplexFlats/components/FlatsList.vue";
import InputWithCurrency from "@/components/base/input/InputWithCurrency.vue";
import LiterSelect from "@/components/common/ResidentialComplexFlats/components/LiterSelect.vue";
import DefaultCheckbox from "@/components/base/checkbox/DefaultCheckbox.vue";


import {get_residental_complex_flats} from "@/api/productsApi";

export default defineComponent({
  name: "ResidentialComplexFlats",
  props: {
    sections: {
      type: [Object] as PropType<Param[]>,
      required: true
    }
  },
  components: {
    Floor,
    InputWithCurrency,
    FlatsList,
    LiterSelect,
    DefaultCheckbox,
  },
  mounted() {
    this.SetLiter(this.sections[0].value)
  },
  data() {
    return {
      current_liter: "",
      filter: {
        price_from: "",
        price_to: "",
        rooms: [] as string[],
        area_from: "",
        area_to: "",
        floor_from: "",
        floor_to: "",
        not_first: false,
        not_last: false,
        from_builder: false,
        from_buyer: false,
      },
      flat_types: [
        {name: "C", value: "студия"},
        {name: "1", value: "1"},
        {name: "2", value: "2"},
        {name: "3", value: "3"},
        {name: "4", value: "4"},
        {name: "5+", value: "5+"},
      ],
      GraphView: false,
      floors: [],
      minFlatsCount: 5,
      flatsTotalCount: 0,
      filter_bubbles: [
        {name: "От собственника", border: "#559DBE", bg: "transparent"},
        {name: "В продаже", border: "#B4B4B4", bg: "transparent"},
      ]
    };
  },
  computed: {
    flats(): Flat[] {
      let res: Flat[] = [];
      const filter = this.filter;
      this.floors.map((item: FloorInterface, i: number) => {
        const flats: Flat[] = item.flats.map(item => {
          return {
            ...item,
            floor: i + 1,
            totalFloors: this.floors.length
          }
        })
        res = res.concat(flats);
      });
      //TODO: Оптимизировать на подфункции
      return res.filter((flat: Flat) => {
        let valid = true;
        for (let key in this.filter) {
          if (!valid) break;
          switch (key) {
            case "rooms": {
              if (filter.rooms.length) {
                const ThisRoom = flat.rooms_count ? flat.rooms_count.toString() : "";
                valid = filter.rooms.includes(ThisRoom);
              }
              break
            }
            case "not_last": {
              if (filter.not_last)
                valid = flat.floor !== flat.totalFloors
              break
            }
            case "not_first": {
              if (filter.not_first)
                valid = flat.floor !== 1
              break
            }
            case "from_builder": {
              if (filter.from_builder)
                valid = flat.seller?.toLowerCase() === "застройщик"
              break
            }
            case "from_buyer": {
              if (filter.from_buyer)
                valid = flat.seller?.toLowerCase() === "собственник"
              break
            }
            case "floor_from": {
              const floor_from = Number(filter.floor_from);
              const floor = flat.floor ?? 0;
              if (floor_from)
                valid = floor >= floor_from
              break
            }
            case "floor_to": {
              const floor_to = Number(filter.floor_to)
              const floor = flat.floor ?? 0;
              if (floor_to)
                valid = floor <= floor_to
              break
            }
            case "price_from": {
              const price_from = Number(filter.price_from.replace(/ /g, ''))
              const price = flat.price ?? 0;
              if (price_from)
                valid = price >= price_from;
              break
            }
            case "price_to": {
              const price_to = Number(filter.price_to.replace(/ /g, ''));
              const price = flat.price ?? 0;
              if (price_to)
                valid = price <= price_to
              break
            }
            case "area_from": {
              const area_from = Number(filter.area_from.replace(/ /g, ''))
              const area = flat.area ?? 0;
              if (area_from)
                valid = area >= area_from;
              break
            }
            case "area_to": {
              const area_to = Number(filter.area_to.replace(/ /g, ''));
              const area = flat.area ?? 0;
              if (area_to)
                valid = area <= area_to;
              break
            }
          }
        }
        return valid;
      });

    },

  },
  methods: {
    SetLiter(value: string | number): void {
      this.current_liter = value.toString();
      this.GetResidentalComplexFlats();
    },
    SetRoom(value: string): void {
      const InRooms = this.filter.rooms.includes(value);
      if (InRooms) this.filter.rooms = this.filter.rooms.filter((item: string) => item !== value);
      else this.filter.rooms.push(value);
    },
    GetResidentalComplexFlats(): void {
      get_residental_complex_flats(this.current_liter)
        .then(({data}) => {
          this.floors = data.floors;
          this.minFlatsCount = data.max_flats_count;
        })
    },
    SetView(value: boolean): void {
      this.GraphView = value;
    },
    ClearFilter(): void {
      this.filter = {
        price_from: "",
        price_to: "",
        rooms: [] as string[],
        area_from: "",
        area_to: "",
        floor_from: "",
        floor_to: "",
        not_first: false,
        not_last: false,
        from_builder: false,
        from_buyer: false,
      }
    }
  },
});

</script>
<style lang="scss" scoped>
.from_buyer {
  margin-left: 33px;
}

.room {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #333;
  width: 36px;
  height: 36px;
  border: 1px solid #DADADA;
  border-radius: 4px;
}

.room__active {
  @apply bg-blue text-white border-0;
}

.graph-view {
  padding-left: 30px;
  @media (max-width: 768px) {
    padding-left: 0;
    margin-top: 15px;
    width: 100%;
    margin-left: 0;
    max-height: unset;
  }
}
.list-view{
  height: 100%;
}
.plan {
  width: 100%;

  &.graph {
    display: flex;
    align-items: flex-end;
  }
}

.filter-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    @apply text-blue;
  }

  .clear {
    margin-left: auto;
    font-size: 12px;
    text-decoration-line: underline;
    cursor: pointer;
    @apply text-gray hover:text-black;

  }
}

.filter-bubbles {
  position: absolute;
  right: 15px;
  bottom: 15px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    &__name {
      margin-right: 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 13.9503px;
      line-height: 150%;
      color: #7D7D7D;
    }

    &__check {
      width: 23px;
      height: 23px;
      border: 1px solid;
      border-radius: 4px;
    }
  }

  @media(max-width: 768px) {
    display: none;
  }
}
.complex-flats{
  &__change-view{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #B4B4B4;
    &.active{
      color: #333;
      border-bottom: 1px solid #3D7E9C;
    }
  }
  &__title {
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 150%;
    margin-bottom: 10px;
    @apply text-black;
  }
  @media(max-width: 768px) {
    &__title {
      font-size: 20px;
      line-height: 150%;
    }
  }
}
</style>
<style scoped lang="scss" module>
.plan_wrapper {
  @apply bg-white;
}

.plan__filter {
  @apply w-full lg:w-96;
}


.list_wrapper {
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>


