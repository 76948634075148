
import {defineComponent} from "vue";

export default defineComponent({
  name: "RequestError",
  props: {
    errorCode: {
      type: Number,
      default: (): number => {
        return 500
      }
    },
  },
});
