
import {defineComponent, PropType} from 'vue';
import ClickOutside from "@/helpers/clickoutside.js";
import ImagesCarousel from "@/components/base/images/ImagesCarousel.vue";
import {Advantage} from "@/typings/types";

export default defineComponent({
  name: "ResidentalComplexAdvantage",
  props: {
    adv: {
      type: Object as PropType<Advantage>,
      required: true
    }
  },
  components: {
    ImagesCarousel
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    Toggle(): void {
      this.show = !this.show;
    },
    Hide(): void {
      this.show = false;
    }
  },
  directives: {
    ClickOutside
  }
})
