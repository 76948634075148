<template>
  <div class="container mx-auto">

  </div>
</template>

<script>
export default {
  name: "Profile",
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>

</style>
