<template>
  <router-link
    :to="{ name: 'Offer', params: { id: flat.id } }"
    :id="'card-' + flat.id"
    target="_blank"
  >
    <div :style="{right: boxOffset}" class="flat">
      <h4 class="flat__title">
        {{ flat_type }}
      </h4>
      <div class="flat__renovation" v-if="flat.renovation">Ремонт: {{ flat.renovation }}</div>
      <div class="flat__image">
        <img :src="flat.plan" alt="">
      </div>
      <div class="flat__params">
        <div class="flat__params__area">
          {{ flat.area }}
          <span>м<sup>2</sup> </span>
        </div>
        <div class="flat__params__price">
          {{ price }} ₽
        </div>
      </div>
      <div class="absolute right-3.5 top-5 cursor-pointer">
        <font-awesome-icon icon="times" @click.prevent="close_adv"/>
      </div>
    </div>
  </router-link>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {Flat} from "@/typings/types";
import FormatNum from "@/ts-helpers/formatNum";

export default defineComponent({
  name: "ResidentialComplexFlat",
  props: {
    flat: {
      type: Object as PropType<Flat>,
      required: true
    }
  },
  emits: ["close"],
  computed: {
    price(): string {
      return FormatNum(this.flat.price ?? 0)
    },
    flat_type(): string {

      return `${this.flat.rooms_count} комн. квартира`;
    },
    boxOffset(): string {
      const container = document.querySelector<HTMLElement>('.container.mx-auto');
      if (window.innerWidth < 1024) return "unset";
      if (container && window.innerWidth < 1530) return `${container.offsetLeft + 35}px`;
      if (container !== null) return `${container.offsetLeft + 97}px`;
      else return "50%";
    }
  },
  methods: {
    close_adv(): void {
      this.$emit("close");
    },
  },

})
</script>

<style scoped lang="scss">
.flat {
  position: fixed;
  top: 351px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.11);
  border: 1px solid #3D7E9C;
  padding: 15px;
  z-index: 10;

  &__title {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 100%;
    color: #333;
  }

  &__renovation {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    color: #333;
    margin-bottom: 9px;
  }

  &__image {
    height: 158px;
    width: 178px;

    img {
      width: 100%;
      height: 100%;

    }
  }

  &__params {
    padding-top: 9px;
    display: flex;
    justify-content: space-between;

    &__area {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      color: #333;
    }

    &__price {
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 150%;
      color: #333;
    }
  }

  @media(max-width: 1024px) {
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    &__renovation {
      font-size: 12px;
      margin-top: 5px;
    }
  }
}
</style>

