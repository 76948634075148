<template>
  <div class="bg-white p-7 pr-32">
    <p class="decorated">
      Банки, государство, застройщики регулярно улучшают условия и возможности
      для клиентов, собирающихся приобрести жилье в ипотеку: снижают ставки,
      дают скидки и льготы. Но как быть тем, кто уже взял ипотеку? Есть ли
      возможность меньше платить по уже оформленному кредиту? Да! Рассказываем,
      как это сделать.
    </p>
    <h3>Снизить ставку по программе «Ипотека для семей с детьми»</h3>
    <p>
      Если у вас родился второй или последующий ребенок, вы можете перевести
      действующий кредит на условия программы «Ипотека для семей с детьми» и
      снизить ставку до 5% годовых на весь срок кредита.
    </p>
    <p>
      Чтобы снизить действующую ставку необходимо одновременное соблюдение
      следующих условий.
    </p>
    <ul class="list-disc">
      <li>
        У вас родился или родится второй ребенок или последующий в период с 1
        января 2018 года до 1 марта 2023 года.
      </li>
      <li>
        Недвижимость куплена по договору долевого участия, договору уступки прав
        требования или договору купли-продажи. Продавец жилья по договору —
        юридическое лицо: застройщик или инвестор.
      </li>
      <li>
        Вам осталось выплатить по кредиту меньше 80% от стоимости жилья и не
        более 12 млн рублей для Москвы и Московской области, Санкт-Петербурга
      </li>
      <li>Оформлен полис страхования жизни.</li>
    </ul>
    <p>
      Подробности в статье «Как снизить ставку по действующей ипотеке Сбербанка
      для семей с детьми»
    </p>
    <h3>Выплатить кредит досрочно</h3>
    <p>
      Запретить вам досрочно погасить ипотеку банк не вправе. Вы можете оформить
      кредит на 25 лет и выплатить через год — были бы деньги.
    </p>
    <p>
      В первую очередь — это выгодно. Парадокс, но выплачивая больше — вы
      платите меньше. Средства досрочного платежа идут на уменьшение основного
      долга. Меньше основной долг — меньше процентов на него начисляется.
      Поэтому досрочно выплачивать ипотеку выгоднее в первые годы после ее
      оформления — в это время большую часть ежемесячного платежа составляют
      именно проценты.
    </p>
    <p>
      Если вы решили выплатить кредит целиком, необходимо сообщить об этому
      банку и выяснить конкретную сумму долга на день, когда вы планируете
      осуществить погашение. Каждый день — это разная сумма.
    </p>
    <p>
      Если вы выбирает досрочное частичное погашение нужно определиться, что вы
      хотите сократить: общий срок кредита или ежемесячные выплаты.
    </p>
    <p>
      Даже небольшие платежи выгодно вносить досрочно, не дожидаясь, пока
      скопится кругленькая сумма. Любой скромный досрочный платеж = экономия.
    </p>
    <p>Подробности в статье «Как досрочно погасить ипотеку»</p>
    <p class="notice">
      Бесплатно и быстро получить справку с информацией об остатке основного
      долга и начисленных процентах вы можете на LOFT UG
    </p>
    <p>
      Досрочно погасить ипотеку можно и за счет собственных накоплений, и с
      помощью государственных программ.
    </p>
    <p class="checked">
      <img
        src="../../../assets/icons/checked.svg"
        class="mr-2 inline-flex bg-blue w-10 h-10"
        alt=""
      />
      Получить 450 000 рублей на погашение ипотеки, если у вас многодетная семья
    </p>
    <p>
      Если вы родитель троих или более детей, один из которых рожден после 1
      января 2019 года, вы можете получить 450 000 рублей на погашение ипотеки.
      Эту сумму можно приплюсовать к другим дотациям, в частности к материнскому
      капиталу — во многих случаях этого может быть достаточно, чтобы погасить
      кредит полностью.
    </p>
    <div class="mark">
      <div class="mark-text">Была ли полезна новость?</div>
      <div class="yes cursor-pointer">
        <img src="../../../assets/icons/like.svg" class="mr-2" alt="" />
        Да
      </div>
      <div class="no cursor-pointer">
        <img src="../../../assets/icons/dislike.svg" class="mr-2" alt="" />
        Нет
      </div>
      <div class="rating">20 человек<br />проголосовали</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsItemText",
};
</script>

<style scoped lang="scss">
.decorated {
  @apply pl-5 border-l-2 border-solid border-blue;
}

ul {
  @apply pl-5;
  li {
    @apply my-5;
  }
}

h3 {
  @apply text-3xl font-bold my-5;
}

p {
  @apply my-3;
}

.notice {
  line-height: 160%;
  background-color: #fafafa;
  @apply bg-lightgray px-3 py-5 pl-10;
}

.checked {
  font-weight: 600;

  svg {
    margin-right: 15px;
    @apply bg-blue;
  }
}

.mark {
  align-items: center;
  padding: 20px 0;
  font-weight: 600;
  border-top: 1px solid;
  border-bottom: 1px solid;
  @apply border-lightgray flex;

  &-text {
    margin-right: 20px;
  }

  .yes,
  .no {
    align-items: center;
    margin-right: 20px;
    @apply rounded-full flex;

    svg {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }

  .rating {
    font-size: 10px;
    font-weight: 500;
    line-height: 120%;
    @apply text-gray;
  }
}
</style>
