<template>
  <div class="container mx-auto py-5 px-3">
    <h1>Уведомление об использовании файлов cookie</h1>
    <p>
      Наш сайт, как и большинство других, использует файлы cookie, а также
      прочие технологии (веб-маяки, пиксельные тэги и т. п.), для последующего
      анализа информации и обеспечения наиболее эффективной работы сайта и
      оказываемых услуг.
    </p>
    <p>
      Данное Уведомление об использовании файлов cookie (далее — «Уведомление»)
      является частью Политики о конфиденциальности.
    </p>
    <p>
      Используя этот сайт, вы подтверждаете свое согласие на использование
      файлов cookie и других подобных технологий в соответствии с настоящим
      Уведомлением.
    </p>
    <p>
      В случае не согласия с использованием файлов cookie, вы должны
      соответствующим образом установить настройки вашего браузера или не
      пользоваться данным сайтом (подробнее — в разделе «Как управлять файлами
      cookie»).
    </p>
    <h2>ЧТО ТАКОЕ ФАЙЛ COOKIE И ДРУГИЕ ПОХОЖИЕ ТЕХНОЛОГИИ</h2>
    <p>
      Файл cookie представляет собой небольшой текстовый файл, сохраняемый на
      вашем компьютере, смартфоне или другом устройстве, которое вы используете
      для посещения сайта.
    </p>
    <p>
      Некоторые из посещенных страниц могут также собирать информацию, используя
      пиксельные тэги и веб-маяки, представляющие собой электронные изображения,
      называемые одно-пиксельными (1×1) или пустыми GIF-изображениями.
    </p>
    <p>
      Файлы cookie могут размещаться на вашем устройстве нами («собственные»
      файлы cookie) или другими операторами (файлы cookie «третьих лиц»).
    </p>
    <p>
      Мы используем два вида файлов cookie на сайте: «cookie сессии» и
      «постоянные cookie». Cookie сессии — это временные файлы, которые остаются
      на устройстве пока вы не покинете сайт. Постоянные cookie остаются на
      устройстве в течение длительного времени или пока вы вручную не удалите их
      (как долго cookie останется на вашем устройстве будет зависеть от
      продолжительности или «времени жизни» конкретного файла и настройки вашего
      браузера).
    </p>
    <p>
      На основании этих файлов собирается информация, которая используется для
      оценки эффективности наших услуг и помогает улучшить их. Сбор данных
      помогает нам выстраивать правильное взаимодействия с клиентом и
      обеспечивать правильное и безопасное обслуживание при оплатах банковскими
      картами.
    </p>
    <p>
      Информация, собираемая такими файлами и технологиями, носит обобщенный
      характер и не связана с идентификацией отдельных пользователей.
    </p>
    <h2>ВИДЫ ФАЙЛОВ COOKIE, КОТОРЫЕ МОГУТ ИСПОЛЬЗОВАТЬСЯ НА НАШЕМ САЙТЕ</h2>
    <p>
      Обязательные файлы cookie. Эти файлы cookie позволяют вам посещать сайты и
      использовать их функции. Их отключение усложнит функционирование сайтов и
      может сделать недоступными их функции и сервисы.
    </p>
    <p>
      Файлы cookie, относящиеся к производительности, эффективности и аналитике.
      Эти файлы помогают нам понять, как пользователи взаимодействуют с нашим
      сайтом, показывают проблемы в его работе (например, сообщения об ошибках),
      помогают измерять эффективность наших рекламных кампаний и оптимизировать
      их для пользователей.
    </p>
    <p>
      Функциональные файлы cookie. Они помогают запоминать индивидуальные
      предпочтения пользователей и персонализировать для них содержание сайта.
    </p>
    <p>
      Рекламные файлы cookie. Они используются для доставки целевой рекламы и
      помогают нам оценивать эффективность рекламных кампаний. Мы можем делиться
      этими файлами с соответствующими рекламными системами и площадками, где
      размещается наша реклама.
    </p>
    <p>
      Электронная почта. Мы также можем использовать технологии, позволяющие
      отслеживать, открывали ли вы, прочитали или переадресовывали определенные
      сообщения, отправленные нами на вашу электронную почту. Это необходимо,
      чтобы сделать наши средства коммуникации более полезными для пользователя.
      Если вы не желаете, чтобы мы получали сведения об этом, вам нужно
      аннулировать подписку посредством ссылки «Отписаться» («Unsubscribe»),
      находящейся внизу соответствующей электронной рассылки.
    </p>
    <p>
      Кнопки доступа к социальным сетям. Они используются для того, чтобы
      пользователи могли поделиться ссылкой на страницу в социальных сетях или
      сделать электронную закладку. Данные кнопки являются ссылками на веб-сайты
      социальных сетей, принадлежащих третьим лицам, которые, в свою, очередь
      могут фиксировать информацию о вашей активности в интернете, в том числе
      на нашем сайте. Пожалуйста, ознакомьтесь с соответствующими условиями
      использования и политикой конфиденциальности таких сайтов для понимания
      того, как они используют ваши данные, и того, как можно отказаться от
      использования ими ваших данных или удалить их.
    </p>
    <p>
      Сторонние веб-сервисы. Иногда на данном сайте мы используем сторонние
      веб-сервисы. Например, для отображения тех или иных элементов
      (изображения, видео, презентации и т. п.), организации опросов, оплаты
      продуктов и услуг через платежные системы и т. п. Как и в случае с
      кнопками доступа к социальным сетям, мы не можем препятствовать сбору
      этими сайтами или внешними доменами информации о том, как вы используете
      содержание сайта.
    </p>

    <h2>КАК УПРАВЛЯТЬ ФАЙЛАМИ COOKIE?</h2>
    <p>
      Большинство интернет-браузеров изначально настроены на автоматический
      прием файлов cookie.
    </p>
    <p>
      Вы можете изменить настройки вашего браузера таким образом, чтобы
      блокировать файлы cookie или предупреждать вас о том, когда они будут
      отправляться к вам на устройство (обратитесь к руководству использования
      конкретного браузера).
    </p>
    <p>
      Если вы используете несколько устройств и (или) браузеров для доступа в
      интернет, соответствующие настройки должны быть изменены в каждом из них.
    </p>
    <h2>ИЗМЕНЕНИЕ УСЛОВИЙ ИСПОЛЬЗОВАНИЯ ФАЙЛОВ COOKIE</h2>
    <p>
      По собственному усмотрению мы можем периодически изменять настоящее
      Уведомление.
    </p>
    <p>
      Изменения в Уведомлении вступают в силу с момента публикации новой
      редакции на сайте. Факт использования вами нашего сайта после внесения
      соответствующих изменений означает ваше согласие на принятие новой
      редакции Уведомления.
    </p>
    <p>
      Дата последнего обновления и предыдущие редакции указаны в начале
      настоящего Уведомления
    </p>
  </div>
</template>

<script>
export default {
  name: "AboutCookiesPage",
};
</script>

<style scoped>
h1 {
  @apply text-3xl font-bold mb-5;
}

h2 {
  @apply text-2xl font-semibold my-3;
}

p {
  @apply my-3;
}
</style>
