
import {defineComponent} from 'vue';

export default defineComponent({
  name: "DefaultCheckbox",
  props: {
    label: {
      type: String,
      default: (): string => {
        return "";
      }
    },
    modelValue: {
      type: Boolean,
      required: true
    },
    primaryColor: {
      type: Boolean,
      default: (): boolean => {
        return false;
      }
    }
  },
  emits: ['update:modelValue'],
  methods: {
    Toggle() {
      this.$emit("update:modelValue", !this.modelValue);
    }
  }
})
