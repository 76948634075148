<template>
  <div class="container mx-auto" v-if="!loading">
    <div class="flex flex-column flex-wrap lg:flex-row justify-between py-5">
      <div class="w-full xl:w-9/12">
        <!--    main content    -->
        <ResidentalComplexTop
          :photos="ResidentalComplex.photos"
          :advantages="ResidentalComplex.advantages"
          :address="ResidentalComplex.address"
          :sub-locality="ResidentalComplex.sub_locality"
          :name="ResidentalComplex.name"
          :price-info="ResidentalComplex.price"
          :params="TopParams"
        />
        <ParamsList
          title="Характеристики ЖК"
          :params="params"
          :text="ResidentalComplex.description"
        />
        <ResidentialComplexFlats :sections="ResidentalComplex.sections"/>
        <Infrastructure
          :infrastructure="ResidentalComplex.infrastructure"
          :latitude="ResidentalComplex.address?.coordinates?.lat ?? 0"
          :longitude="ResidentalComplex.address?.coordinates?.lon ?? 0"
        />
        <!--  здесь Ход строительства     -->
        <Documents v-if="false"/>
        <SimilarObjects title="Похожие жилые комплексы"
                        sub-title="Предложения сформированы по таким критериям, как: цена, класс жилья, район"
                        :id="ID"/>
      </div>
      <div
        class="xl:sticky bottom-0 xl:bottom-auto xl:top-16 w-full xl:w-3/12 xl:h-72 xl:pl-5 right-0 seller-container">
        <!--   seller container     -->
        <Seller :seller="ResidentalComplex.seller" :left-param="SellerLeftParam" :right-param="SellerRightParam"/>
      </div>
    </div>
  </div>
  <SiteLoading v-else/>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {ResidentalComplex, Param, ResidentalComplexInfo} from "@/typings/types";
import ResidentalComplexTop from "@/views/ResidentalComplex/components/ResidentalComplexTop.vue";
import Documents from "@/components/common/DetailViews/Documents.vue";
import ParamsList from "@/components/common/DetailViews/ParamsList.vue";
import Infrastructure from "@/components/common/DetailViews/Infrastructure.vue";
import SimilarObjects from "@/components/common/Products/SimilarObjects.vue";
import Seller from "@/components/common/DetailViews/Seller.vue";
import ResidentialComplexFlats from "@/components/common/ResidentialComplexFlats/ResidentialComplexFlats.vue";
import SiteLoading from "@/components/base/loaders/SiteLoading.vue";
//api
import {get_residental_complex} from "@/api/productsApi";
//helpers
import {ParseResidentalComplexParams} from "@/ts-helpers/parseParams";
import FormatNum from "@/ts-helpers/formatNum";

export default defineComponent({
  name: "ResidentalComplex",
  components: {
    ResidentalComplexTop,
    Documents,
    ParamsList,
    Infrastructure,
    SimilarObjects,
    Seller,
    ResidentialComplexFlats,
    SiteLoading
  },
  data() {
    return {
      ResidentalComplex: {} as ResidentalComplex,
      loading: true,
      error: false
    }
  },
  mounted() {
    this.GetResidentalComplex();
  },
  computed: {
    ID(): string {
      return this.$route.params.id.toString();
    },
    params(): Param[] {
      return ParseResidentalComplexParams(this.ResidentalComplex.residental_complex_info);
    },
    SellerLeftParam(): Param {
      return {name: "Цена за м<sup>2</sup>", value: `от ${FormatNum(this.ResidentalComplex.price?.value_per_unit)} ₽`}
    },
    SellerRightParam(): Param {
      return {name: "Общая площадь", value: this.ResidentalComplex?.residental_complex_info?.area ?? ""}
    },
    TopParams(): Param[] {
      let res: Param[] = [];
      const paramsList: ResidentalComplexInfo = this.ResidentalComplex.residental_complex_info
      if (paramsList) {
        res.push({name: "Застройщик", value: paramsList.developer_name});
        res.push({name: "Класс жилья", value: paramsList.living_class ?? ""});
        res.push({name: "Ремонт", value: paramsList.renovation});
        res.push({name: "Сдача", value: paramsList.finished_at});
      }
      return res;
    }
  },
  methods: {
    GetResidentalComplex(): void {
      this.loading = true;
      this.error = false;
      get_residental_complex(this.ID)
        .then((response) => {
          this.ResidentalComplex = response.data;
          this.loading = false;
          this.error = false;
          document.title = `LOFT | ${this.ResidentalComplex.name}`;
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        })
    }
  }
})
</script>

<style scoped lang="scss">

</style>
