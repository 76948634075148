import api from "./api";
import {id} from "@/typings/types";
import {AxiosResponse} from "axios";

const URLS = {
  realty_objects: "/offers/all",
  realty_object: "/offers/detailed_info",
  filters_data_list: "/server_info/filters_data_list",
  additional_filter: "/server_info/additional_filters",
  random_realty_objects: "/offers/random",
  similar_objects: "/similar",
  residental_complex_detail: "/realty_objects/residential_complex",
  residental_complex_flats: "/realty_objects/building_structure"
};

export function get_random_objects(params: any): Promise<AxiosResponse>  {
  return api.get(URLS.random_realty_objects, {params});
}

export function get_object(params: any): Promise<AxiosResponse>  {
  return api.get(URLS.realty_object, {params});
}

export function get_residental_complex(id: id): Promise<AxiosResponse>  {
  return api.get(URLS.residental_complex_detail, {params: {id: id}});
}

export function get_similar_objects(id: id): Promise<AxiosResponse> {
  return api.get(URLS.similar_objects, {params: {id}})
}

export function get_residental_complex_flats(id: id): Promise<AxiosResponse>  {
  return api.get(URLS.residental_complex_flats, {params: {id}})
}
