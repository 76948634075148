<template>
  <div class="p-5 bg-whitegray rounded-md mb-3 share-box">
    <div class="share-box__close" @click="$emit('close')">
      <cross fill="#333"/>
    </div>
    <div class="share-box__social">
      <a :href="'https://vk.com/share.php?url=' + url" target="_blank">
        <vk-transparent />
      </a>
      <a
        :href="'https://www.facebook.com/sharer/sharer.php?u=' + url"
        target="_blank"
      >
        <facebook />
      </a>
      <a :href="`https://connect.ok.ru/offer?url=${url}`" target="_blank">
        <ok />
      </a>
    </div>
    <div class="share-box__button">
      <button :class="{active: copied}" @click="copyTextToClipboard(url)">{{ copied ? "Ссылка скопирована" : "Скопировать ссылку" }}</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ShareBox",
  data(){
    return {
      copied: false
    }
  },
  methods: {
    fallbackCopyTextToClipboard(text: string): void {
      let textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
    },
    copyTextToClipboard(text: string): void {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        this.copied = true;
        return;
      }
      navigator.clipboard.writeText(text);
      this.copied = true;
    },
  },
  computed: {
    url() {
      return window.location.href;
    },
  },
});
</script>
<style lang="scss">
.share-box #vk-bg{
  fill: #333;
}
</style>
<style lang="scss" scoped>
.share-box {
  height: 152px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: 0.5s ease init;
  position: relative;
  &__close{
    position: absolute;
    right: 7px;
    top: 7px;
    cursor: pointer;
  }
  &__social {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 23px;
      height: 23px;
    }
    a {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__button {
    padding-top: 20px;
    button {
      width: 178px;
      height: 32px;
      border: 1px solid #000;
      box-sizing: border-box;
      border-radius: 4px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      color: #333;
      &.active{
        background: #333;
        color: #fff;
      }
    }
  }
  @media(max-width: 1024px){
    width: 100%;
  }
}
@keyframes init {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
