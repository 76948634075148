<template>
  <div v-if="!loading && !error" class="container mx-auto">
    <div class="flex flex-column flex-wrap lg:flex-row justify-between py-5">
      <div class="w-full xl:w-9/12">
        <OfferTop
          :address="offer.address.normalized_address"
          :description="offer.object_info.description"
          :params="main_specs"
          :photos="offer.photos"
          :price="price"
          :price_per_unit="price_per_unit"
          :residential-complex-info="offer.residential_complex_info"
          :subLocality="offer.sub_locality"
          :title="title"
        />
        <PaymentTypes :types="offer.object_info.payment_types"/>
        <ParamsList
          v-if="offer.flat_params?.length"
          :params="flat_params"
          title="Характеристики квартиры"
        />
        <ParamsList
          v-if="offer.building_params?.length"
          :params="building_params"
          title="Характеристики дома"
        />
        <ParamsList
          v-if="offer.lot_params?.length"
          :params="lot_params"
          title="Характеристики участка"
        />
        <ParamsList
          v-if="offer.commerce_params?.length"
          :params="commerce_params"
          title="Характеристики коммерции"
        />
        <ParamsList
          v-if="offer.residential_complex_params?.length"
          :params="residentalComplexParams"
          title="Характеристики ЖК"
        />
        <Infrastructure
          :latitude="offer.address.coordinates.lat"
          :longitude="offer.address.coordinates.lon"
          :hint-title="offer.address.normalized_address"
        />
        <AreaPhotos v-if="offer.sub_locality?.id" :sub-locality="offer.sub_locality"/>
        <SimilarObjects :sub-title="similarSubtitle" title="Похожие объекты"/>
      </div>
      <div class="xl:sticky bottom-0 xl:bottom-auto xl:top-16 w-full xl:w-3/12 xl:h-72 xl:pl-5 right-0 seller-container">
        <Seller :seller="offer.seller" :left-param="{name: 'Цена',value: price}"
                :right-param="{name: SellerAreaLabel,value: area}"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {get_object} from "@/api/productsApi";
import {id, Offer, Param} from "@/typings/types";
import FormatNum from "@/ts-helpers/formatNum";
import declOfNum from "@/ts-helpers/declOfNum";
import ParseParams from "@/ts-helpers/parseParams";
import OfferTop from "@/views/Offer/components/OfferTop.vue";
import PaymentTypes from "@/components/common/DetailViews/PaymentTypes.vue";
import ParamsList from "@/components/common/DetailViews/ParamsList.vue";
import Infrastructure from "@/components/common/DetailViews/Infrastructure.vue";
import Seller from "@/components/common/DetailViews/Seller.vue";
import AreaPhotos from "@/views/Offer/components/AreaPhotos.vue";
import SimilarObjects from "@/components/common/Products/SimilarObjects.vue";
import TYPES from "@/constants/FilterTypes.js";
import COMMERCE_TYPES from "@/constants/CommerceTypes";

export default defineComponent({
  name: "Offer",
  components: {
    OfferTop,
    Seller,
    PaymentTypes,
    ParamsList,
    Infrastructure,
    AreaPhotos,
    SimilarObjects
  },
  data() {
    return {
      offer: {} as Offer,
      loading: true,
      error: false,
    };
  },
  computed: {
    id(): id {
      return this.$route.params.id.toString();
    },
    area(): string {
      let unit = this.offer.object_info.area_unit,
        area = this.offer.object_info.area;
      if (unit === "сотка") {
        unit = declOfNum(area, ["сотка", "сотки", "соток"]);
      } else {
        unit = `м<sup>2</sup>`;
      }
      return `${area} ${unit}`;
    },
    price_symbol(): string {
      let symbol = "₽";
      if (this.offer.price.currency !== "RUR") symbol = "$";
      return symbol;
    },
    price(): string {
      if (this.offer?.price?.value)
        return FormatNum(this.offer.price.value) + " " + this.price_symbol;
      else return "";
    },
    price_per_unit(): string {
      let unit = this.offer.object_info.area_unit;
      if (unit === "сотка") unit = "сотку";
      else unit = `м<sup>2</sup>`;

      return `${FormatNum(this.offer.price.value_per_unit)} ${
        this.price_symbol
      } за ${unit}`;
    },
    title(): string {
      let res = "";
      switch (this.offer.object_info.category) {
        case TYPES.commerce: {
          res = `${this.offer.sell_type} ${this.offer.object_info.commerce_type},\n ${this.offer.object_info.area_normalized}`;
          break
        }
        case TYPES.flat: {
          if (!this.offer.object_info.is_studio){
            res = `${this.offer.sell_type} ${this.offer.object_info.rooms_count}-комн. ${TYPES.flat}, \n${this.offer.object_info.area_normalized}`
          }
          else{
            console.log('студия')
            res = `${this.offer.sell_type} студия, \n${this.offer.object_info.area_normalized}`
          }
          break
        }
        case TYPES.house: {
          res = `${this.offer.sell_type} ${this.offer.object_info.house_type}, \n${this.offer.object_info.area_normalized}`
          break
        }
        case TYPES.new_flats: {
          res = `${this.offer.sell_type} ${this.offer.object_info.rooms_count}-комн. ${TYPES.flat}, \n${this.offer.object_info.area_normalized}`
          break
        }
        case TYPES.segment: {
          res = `${this.offer.sell_type} ${TYPES.segment} ${this.offer.object_info.lot_property ?? ""}, \n${this.offer.object_info.area_normalized}`;
        }
      }
      return res.toLowerCase();
    },
    main_specs(): Param[] {
      return ParseParams(this.offer.main_parameters, this.offer.object_info);
    },
    flat_params(): Param[] {
      return ParseParams(this.offer.flat_params ?? [], this.offer.object_info);
    },
    building_params(): Param[] {
      return ParseParams(
        this.offer.building_params ?? [],
        this.offer.object_info
      );
    },
    lot_params(): Param[] {
      return ParseParams(this.offer.lot_params ?? [], this.offer.object_info);
    },
    commerce_params(): Param[] {
      return ParseParams(
        this.offer.commerce_params ?? [],
        this.offer.object_info
      );
    },
    similarSubtitle(): string {
      let res = "Предложения сформированы по таким критериям, как: цена, общая площадь, район";
      if (this.offer.object_info.category === TYPES.segment)
        res = "Предложения сформированы по таким критериям, как: цена, площадь, район";
      return res;
    },
    residentalComplexParams(): Param[] {
      return ParseParams(
        this.offer.residential_complex_params ?? [],
        this.offer.object_info
      );
    },
    SellerAreaLabel(): string {
      let res = "Общая площадь";
      const category = this.offer.object_info.category;
      const commerce_type = this.offer.object_info.commerce_type ?? "";
      if (category === TYPES.segment || commerce_type.toLowerCase() === COMMERCE_TYPES.commercial_land) res = "Площадь участка";
      return res;
    }
  },
  methods: {
    getObject() {
      this.loading = true;
      this.error = false;
      get_object({id: this.id})
        .then((response) => {
          this.offer = response.data;
          this.loading = false;
          document.title = `LOFT | ${this.offer.object_info.category}, ${this.offer.object_info.area} ${this.offer.object_info.area_unit}`;
        })
        .catch(() => {
          this.loading = false;
          this.error = false;
        });
    },
  },
  mounted() {
    this.getObject();
  },
});
</script>

<style lang="scss" scoped>
.seller-container {
  @media (max-width: 1280px) {
    margin-top: 20px;
  }
}
</style>
