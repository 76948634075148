<template>
  <div class="seller" v-click-outside="HideSeller">
    <div class="seller__button" @click="ToggleSeller">Объект разместил</div>
    <div class="seller__content" :class="{show: showSeller}">
      <div class="seller__mobile-toggle" @click="HideSeller">
        <chevron-down/>
      </div>
      <div class="seller__top">
        <div class="seller__top__button" :class="{ active: showShare }" @click="showShare = !showShare">
          <span>Поделиться</span>
          <share/>
        </div>
        <div class="seller__top__button" @click="print">
          <span>Распечатать</span>
          <print/>
        </div>
        <div class="seller__top__button">
          <font-awesome-icon icon="heart"/>
        </div>
      </div>
      <ShareBox v-if="showShare" @close="showShare = false"/>
      <div v-else class="p-5 bg-white rounded-md seller__middle">
        <div class="seller__created-at" v-if="createdAt > 0">Дата публикации: {{ creationDate }}</div>
        <div class="seller__extra" v-if="seller.is_physical && seller.seller_company_name">{{ seller.seller_company_name }}</div>
        <div class="seller__type">
          {{ type }}
        </div>
        <router-link class="seller__name" :to="{name: 'Company',params: {id: seller.id}}" v-if="seller.id">{{ name }}</router-link>
        <div class="seller__name" v-else>
          {{ name }}
        </div>
        <div class="seller__phone">
          <div class="icon">
            <font-awesome-icon icon="phone-alt"/>
          </div>
          <a :href="`tel:${seller.phone}`">
            {{ phone }}
          </a>
        </div>
      </div>
      <div class="bg-white rounded-md p-5 seller__bottom">
        <div class="seller__param">
          <div class="seller__param__label" v-html="leftParam.name"/>
          <div class="seller__param__value" v-html="leftParam.value"/>
        </div>
        <div class="delimiter"/>
        <div class="seller__param">
          <div class="seller__param__label" v-html="rightParam.name"/>
          <div class="seller__param__value" v-html="rightParam.value"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {Seller, Param} from "@/typings/types";
import ShareBox from "@/components/common/Share/ShareBox.vue";
import clickOutside from "@/helpers/clickoutside.js";
import {formatPhoneNumber} from "@/helpers/formatPhone.js";
import ParseSecondsToDate from "@/ts-helpers/ParseSecondsToDate";

export default defineComponent({
  name: "Seller",
  props: {
    createdAt: {
      type: Number,
      default: (): number => {
        return 0
      }
    },
    seller: {
      type: Object as PropType<Seller>,
      required: true
    },
    rightParam: {
      type: Object as PropType<Param>,
      required: true
    },
    leftParam: {
      type: Object as PropType<Param>,
      required: true
    }
  },
  components: {
    ShareBox
  },
  data() {
    return {
      showShare: false,
      showSeller: false
    }
  },
  computed: {
    creationDate(): string {
      return ParseSecondsToDate(this.createdAt);
    },
    name(): string {
      let res = this.seller.is_physical
        ? this.seller.name
        : this.seller.seller_company_name;
      return res ?? "";
    },
    phone(): string {
      return formatPhoneNumber(this.seller.phone) ?? "";
    },
    type():string {
      let res = this.seller.seller_type;
      if(this.seller.is_physical && this.seller.seller_type === 'Компания Loft') res = "Персональный менеджер";
      if(!this.seller.is_physical && this.seller.seller_type === 'Компания Loft') res = "Агенство недвижимости";
      return res ?? "";
    }
  },
  methods: {
    print(): void {
      window.print();
    },
    ToggleSeller(): void {
      this.showSeller = !this.showSeller;
    },
    HideSeller(): void {
      this.showSeller = false;
    }
  },
  directives: {
    clickOutside
  }
})
</script>

<style scoped lang="scss">
.seller {
  &__button {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px 4px 0 0;
    padding: 20px;
    z-index: 100;
    display: none;
    @apply bg-blue text-white;
  }
  &__extra {
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    @apply text-blue;
  }
  &__created-at{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #B4B4B4;
    margin-bottom: 5px;
  }
  &__mobile-toggle {
    position: absolute;
    right: 14px;
    top: 22px;
    z-index: 100;
    transform: rotate(180deg);
    display: none;
  }

  &__type {
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    @apply text-lightgray;
  }

  &__name {
    font-weight: 800;
    font-size: 24px;
    line-height: 150%;
    @apply text-black;
  }

  &__phone {
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    display: flex;
    align-items: center;
    margin-top: 17px;
    @apply text-black;
    .icon {
      @apply text-blue mr-2;
    }
  }

  &__param {
    &__label {
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      @apply text-lightgray;
    }

    &__value {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      @apply text-black;
    }
  }

  &__top {
    margin-bottom: 6px;
    display: flex;
    align-items: center;

    &__button {
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      border-radius: 5px;
      padding: 6px 14px;
      margin-right: 6px;
      text-align: center;
      min-width: 78px;
      min-height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      @apply text-lightgray bg-white;
      span {
        margin-right: 10px;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover, &.active {
        cursor: pointer;
        @apply bg-whitegray text-black;
      }
    }
  }

  &__middle {
    margin-bottom: 6px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .delimiter {
      height: 40px;
      width: 2px;
      margin: 0 30px;
      @apply bg-blue;
    }
  }

  @media (max-width: 1535px) {
    &__top {
      &__button {
        width: 33%;

        span {
          display: none;
        }
      }
    }
    &__param {
      &__value {
        font-size: 14px;
      }
    }
  }
  @media (max-width: 1280px) {
    margin-top: 30px;
  }
  @media (max-width: 768px) {
    &__mobile-toggle {
      display: block;
    }
    &__button {
      display: block;
    }
    &__content {
      position: fixed;
      bottom: -100%;
      left: 0;
      width: 100%;
      z-index: 100;
      transition: .5s;

      &.show {
        bottom: 0;
      }
    }
    &__top {
      display: none;
    }
    &__middle {
      margin-bottom: 0;
      box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.11);
      position: relative;
      z-index: 2;
    }
    &__middle, &__bottom {
      border-radius: 0;
      @apply bg-blue;
    }
    &__bottom {
      display: none;
      z-index: 1;

      .delimiter {
        @apply bg-black;
      }
    }
    &__type {
      @apply text-black;
    }
    &__created-at{
      color: #fff;
    }
    &__name {
      margin-bottom: 15px;
      @apply text-white;
    }
    &__phone {
      @apply text-white;
      .icon {
        @apply text-white;
      }
    }
    &__param {
      &__label {
        font-size: 14px;
        @apply text-black;
      }

      &__value {
        font-size: 15px;
        @apply text-white;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1280px) {
    &__bottom {
      display: none;
    }
  }
}
</style>
