<template>
  <div :class="$style.moreFilters">
    <div v-if="current_tab_data.length > 1" class="carousel-wrapper">
      <div class="carousel-next-btn">
        <chevron-right fill="#333"/>
      </div>
      <swiper slides-per-view="auto" :space-between="2" freemode mousewheel :navigation="{nextEl: '.carousel-next-btn'}">
        <swiper-slide
          class="tab-link"
          v-for="(item, i) in current_tab_data"
          :key="i"
          :class="[
          item.tab_value === current_tab ? $style.current : '',
          $style.tab_link,
        ]"
          @click="SetAdditionalFilterTab(item.tab_value)"
        >
          {{ item.tab_name }}
        </swiper-slide>
      </swiper>
    </div>
    <FieldsList
      :current_tab_data="current_tab_data"
      :current_tab="current_tab"
      :additional_filter="additional_filter"
      @OnSelect="Select"
    />
    <div :class="$style.footer">
      <p class="clear-filter-button" @click="ClearFilter">Очистить фильтр</p>
      <BaseButton size="xsm" class="block" @click="Search">
        Применить фильтр
      </BaseButton>
    </div>
  </div>
</template>

<script>
import FieldsList from "./FieldsList";
import BaseButton from "../../../components/base/button/BaseButton";
import {Swiper, SwiperSlide} from "swiper/vue";
import SwiperCore, {Mousewheel, Navigation} from 'swiper/core';
import "swiper/swiper.scss";
import {mapGetters, mapMutations} from "vuex";

SwiperCore.use([Mousewheel,Navigation]);
export default {
  name: "additional_filter",
  components: {
    Swiper,
    SwiperSlide,
    BaseButton,
    FieldsList,
  },
  computed: {
    ...mapGetters("search", {
      current_tab_data: "GET_CURRENT_TAB_ADDITIONAL_FILTER",
      additional_filter: "GET_ADDITIONAL_FILTER",
      additional_filter_raw: "GET_ADDITIONAL_FILTER_RAW",
      offer_property: "GET_OFFER_PROPERTY",
      current_tab: "GET_CURRENT_TAB",
    }),
  },
  methods: {
    ...mapMutations({
      SetAdditionalFilterTab: "search/SET_ADDITIONAL_FILTER_TAB",
      Select: "search/SET_ADDITIONAL_FILTER_SELECT_DATA",
    }),
    Search() {
      this.$emit("close");
      this.$emit("onSearch");
    },
    ClearFilter(){
      this.$emit('clearFilter');
      this.$emit("close");
    }
  },
};
</script>
<style lang="scss" scoped>
.clear-filter-button{
  margin-left: auto;
  margin-right: 20px;
  font-weight: bold;
  font-size: 14px;
  text-decoration-line: underline;
  color: #C9C9C9;
  cursor: pointer;
}
.carousel-wrapper {
  position: relative;

  .carousel-next-btn {
    z-index: 10;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F0F0F0;
    cursor: pointer;

    &:hover {
      background: #B4B4B4;
    }
  }
}

.swiper-container {
  position: relative;
}
</style>
<style module scoped lang="scss">
.fields {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  width: 100%;
  @apply gap-8 grid mx-auto py-5;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.moreFilters {
  width: 700px;
  height: 600px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
}

.tab_link {
  display: block;
  padding: 10px 15px;
  color: #222;
  cursor: pointer;
  background: #F0F0F0;
  width: max-content;
  @apply mr-2 font-semibold text-center;
}

.current {
  color: #222;
  @apply bg-white font-bold;
}

.body {
  @apply pb-5 md:py-5;
}

.col {
  @apply w-full md:w-1/2 md:px-2 pt-2 md:pt-0;
}

.footer {
  padding-left: 30px;
  padding-right: 37px;
  padding-bottom: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer__clear {
  @apply order-2 md:order-1 underline text-lightgray font-semibold;
}

.footer__save {
  @apply order-3 md:order-2 underline ml-auto md:mr-5 font-semibold;
}
</style>
