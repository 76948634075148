import {ResidentalComplex, OfferCard,Company} from "@/typings/types";

export const RawResidentalComplexCard: OfferCard = {
  id: 2,
  address: "Адрес ЖК",
  photos: [
    {
      preview:
        "https://images.stage.loft.ug/1/lot/8/e059a168-6d48-45dc-8241-77e45be2cff6_500.jpg",
      sort: 0,
    },
    {
      preview:
        "https://images.stage.loft.ug/1/lot/8/e42af500-4803-4afa-9772-e48c1178b477_500.jpg",
      sort: 1,
    },
    {
      preview:
        "https://images.stage.loft.ug/1/lot/8/b70c12fb-926d-4f6b-bf4f-20a2d4309765_500.jpg",
      sort: 2,
    },
    {
      preview:
        "https://images.stage.loft.ug/1/lot/8/2accd63f-6495-4c17-bb9b-d52b20e465ac_500.jpg",
      sort: 3,
    },
  ],
  seller: {
    category: "Агентство недвижимости",
    name: "Компания LOFT"
  },
  residential_complex_info: {
    total_flats_count: 100 ,
    filtered_flats_count: 50,
    name: "Название",
    finish_at: "IV кв. 2022",
    price_per_unit: 10000,
    price_from: 10000000
  }
}
export const RawCompanyInfo: Company = {
  id: 1,
  name: "LOFT",
  logo: {
    preview: "https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/12513674/original/4e068c01d68803fb103ae7fd6a21a06c94b72cbe/create-a-logo-for-a-business-or-group.png"
  },
  description: "Описание компании или агенства или застройщика",
  houses_finished: {
    handover: 10,
    residental_complex_count: 20
  },
  houses_in_progress: {
    on_finish: 5,
    residental_complex_count: 25
  },
  cities_of_presence: [
    {code: "1234",name: "Краснодар"},
    {code: "1233",name: "Москва"}
  ]
}
