<template>
  <div>
    <div :class="$style['home-top']">
      <div class="container mx-auto px-0 md:px-0">
        <h1 :class="$style['home-top-title']">
          Найдите недвижимость с&nbsp;Loft.ug
        </h1>

        <div class="py-3 bg-white rounded">
            <div class="filter-container">
              <Filter with-push OpenFilter/>
            </div>
        </div>
      </div>
    </div>
    <div class="container mx-auto lg:py-5 pb-10">
      <RandomProducts
        :products_count="8"
        title="Эксклюзивные предложения от Loft"
      />
    </div>
    <div class="container mx-auto py-5 pb-10">
      <Exclusive
        title="Посмотреть все эксклюзивные предложения"
        subtitle="Выбирая эксклюзивный объект, вы не оплачиваете комиссию"
      />
    </div>
  </div>
</template>

<script>
import Filter from "../Search/components/Filter";
import RandomProducts from "@/components/common/Products/RandomProducts.vue";
import Exclusive from "@/components/common/Products/Exclusive.vue";

export default {
  name: "Home",
  components: {
    RandomProducts,
    Exclusive,
    Filter,
  },
  data() {
    return {
      isDesktopFiltersOpened: false,
      filterKey: 1,
    };
  },
  methods: {
    openDesktopFilter: function () {
      this.isDesktopFiltersOpened = true;
    },
    closeDesktopFilter: function () {
      this.isDesktopFiltersOpened = false;
    },
    search: function () {
      this.filterKey++;
    },
  },
};
</script>

<style lang="scss" module scoped>
.main-filter {
  background-image: url("~@/assets/images/loft-fon.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @media (max-width: 768px) {
    background-position: top;
    background-size: contain;
    background-image: url("~@/assets/home-mobile.png");
  }

}

.line {
  width: 100%;
  height: 1px;
  @apply bg-lightgray my-3;
}

.home-top {
  @apply pt-10 md:pt-28 md:pb-60 lg:pb-20 xl:pb-60 lg:pt-10 xl:pt-28 main-filter bg-center sm:px-5;
}

.home-top-title {
  @apply text-4xl md:text-3xl px-5 sm:px-0 md:text-5xl text-white font-bold my-5 md:my-16 mb-20 md:mb-5;
  @media (max-width: 768px) {
    margin-bottom: 20px;
    margin-top: 0;
  }
}
</style>
<style scoped lang="scss">
.filter-container {
  padding-right: 20px;
}

@media (max-width: 768px) {
  .filter-container-wrapper {
    padding: 0 10px;
  }
  .filter-container {
    padding: 0;
    height: unset;
  }
}
</style>
