<template>
  <div class="lightbox">
    <div class="lightbox__shadow" @click="Hide"></div>
    <div class="lightbox__current-image">
      <div class="lightbox__counter">
        {{ currentImage + 1 + "/" + photos.length }}
      </div>
      <img :src="photos[currentImage].preview" alt="">
    </div>
    <div class="lightbox__images-list">
      <div @click="PrevImage" class="lightbox__image" v-if="prevPhoto">
        <img :src="prevPhoto.preview" alt="">
      </div>
      <div class="lightbox__image current">
        <img :src="photos[currentImage].preview" alt="">
      </div>
      <div @click="NextImage" class="lightbox__image" v-if="nextPhoto">
        <img :src="nextPhoto.preview" alt="">
      </div>
    </div>
    <button @click="PrevImage" type="button"  class="lightbox__button prev">
      <arrow-prev/>
    </button>
    <button @click="NextImage" type="button" class="lightbox__button next">
      <arrow-next/>
    </button>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {Photo} from "@/typings/types";

export default defineComponent({
  name: "lightbox",
  props: {
    photos: {
      type: [Object] as PropType<Photo[]>,
      required: true
    },
  },
  emits: ["closeLightbox"],
  data() {
    return {
      currentImage: 0
    }
  },
  mounted() {
    window.addEventListener('keydown', this.KeyDownChange)
    this.currentImage = 0;
  },
  unmounted() {
    window.removeEventListener('keydown', this.KeyDownChange)
  },
  computed: {
    prevPhoto(): Photo {
      if (this.photos[this.currentImage - 1]) return this.photos[this.currentImage - 1];
      else return this.photos[this.photos.length - 1];
    },
    nextPhoto(): Photo {
      if (this.photos[this.currentImage + 1]) return this.photos[this.currentImage + 1];
      else return this.photos[0];
    },
  },
  methods: {
    NextImage(): void {
      if (this.currentImage === this.photos.length - 1) this.currentImage = 0;
      else this.currentImage++;
    },
    PrevImage(): void {
      if (this.currentImage === 0) this.currentImage = this.photos.length - 1;
      else this.currentImage--;
    },
    Hide(): void {
      this.$emit('closeLightbox');
    },
    KeyDownChange({code}: any): void {
      console.log(code)
      if (code === "ArrowRight") this.NextImage();
      else if (code === "ArrowLeft") this.PrevImage();
      else if (code === "Escape") this.Hide();
      else return undefined;
    }
  }
})
</script>

<style scoped lang="scss">
.lightbox {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000000000000000000;

  &__shadow {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
  }

  &__current-image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 90vh;

    img {
      max-height: calc(100vh - 200px);
      max-width: 100%;
      margin: 0 auto;
    }
  }

  &__button {
    position: absolute;
    width: 50px;
    top: calc(50% - 25px);

    &.next {
      right: 15px;
    }

    &.prev {
      left: 15px;
    }

    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }

  &__images-list {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: 75px;
    height: 75px;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }

    &.current, &:hover {
      border: 2px solid #3D7E9C;
      cursor: pointer;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__counter {
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    right: 0;
    top: -30px;
    @apply text-white;
  }

  @media (max-width: 768px) {
    &__button {
      width: 25px;
    }
    &__current-image {
      width: 100%;
    }
  }
}
</style>
