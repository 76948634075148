<template>
  <div :class="$style.listLayout">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" module>
.listLayout {
  > * {
    min-height: 150px;
    margin-bottom: 50px;
  }
}
</style>
