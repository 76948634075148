
import {defineComponent} from "vue";

export default defineComponent({
  name: "Exclusive",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
});
