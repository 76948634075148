<template>
  <div class="flat-list">
    <div class="flat-list__page">
      <ListFlat v-for="flat in currentPageData" :key="flat.id" :flat="flat" :filter="filter"/>
    </div>
    <div class="flat-list__pagination">
      <div class="flat-list__pagination__page" v-if="currentPage > 1" @click="SetPage(currentPage - 1)">
        {{ currentPage - 1 }}
      </div>
      <div class="flat-list__pagination__page current">{{ currentPage }}</div>
      <div class="flat-list__pagination__page" v-if="flats.length > currentPage * showOnPage + 1"
           @click="SetPage(currentPage + 1)">{{ currentPage + 1 }}
      </div>
      <div class="flat-list__pagination__page" v-if="flats.length > currentPage * showOnPage + 1"
           @click="SetPage(currentPage + 1)">
        <arrow-next fill="#333"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {Flat} from "@/typings/types";
import ListFlat from "@/components/common/ResidentialComplexFlats/components/ListFlat.vue";

export default defineComponent({
  name: "FlatsList",
  props: {
    flats: {
      type: [Object] as PropType<Flat[]>,
      required: true
    },
    filter: {
      type: Object as PropType<any>,
      required: true
    }
  },
  components: {
    ListFlat
  },
  data() {
    return {
      currentPage: 1,
      showOnPage: 6
    }
  },
  watch: {
    flats() {
      this.currentPage = 1;
    }
  },
  computed: {
    currentPageData(): Flat[] {
      const start = (this.currentPage - 1) * this.showOnPage;
      const end = start + this.showOnPage;
      return this.flats.slice(start, end);
    },
  },
  methods: {
    SetPage(page: number): void {
      this.currentPage = page;
    }
  }
})
</script>

<style scoped lang="scss">
.flat-list {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  &__page {
    padding-top: 15px;
  }

  &__pagination {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &__page {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      padding: 4px;
      cursor: pointer;
      font-weight: bold;
      @apply bg-white;
      &:hover {
        border: 1px solid #3D7E9C;
        @apply bg-darkblue text-white;
        svg {
          fill: #fff;
        }
      }

      &.current {
        border: 1px solid #3D7E9C;
        @apply bg-blue text-white;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
