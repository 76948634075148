<template>
  <li class="rounded-xl shadow-xl hover:shadow-2xl bg-white mb-5 block">
    <router-link to="/news/1">
      <div class="w-full">
        <img
          class="w-full"
          src="../../../assets/images/new_banner.jpg"
          alt=""
        />
      </div>
      <div class="p-5">
        <span class="text-blue text-xs font-semibold">
          LOFT.UG
        </span>
        <h3 class="text-2xl font-bold mb-3">
          Как меньше платить по действующей ипотеке
        </h3>
        <p class="mb-2">
          Взять ипотечный кредит – это серьезный и ответственный шаг. Потому
          очень важно заранее продумать все нюансы, ведь выплаты будут длиться
          долгие годы.
        </p>
        <span class="text-xs">
          День назад
        </span>
      </div>
    </router-link>
  </li>
</template>

<script>
export default {
  name: "NewsCard",
};
</script>

<style scoped>
li {
  width: 31%;
}
</style>
