<template>
  <ul :class="['pagination', { disabled: disabled }]">
    <li ref="navPrev">
      <button
        :class="['pagination-navigation', { disabled: isValueFirst }]"
        v-on="isValueFirst ? {} : { click: goPrevious }"
      >
        <slot name="prev-icon">
          <font-awesome-icon icon="chevron-left" />
        </slot>
      </button>
    </li>

    <li v-for="(item, index) in items" :key="'paging_' + index">
      <span v-if="isNaN(Number(item))" class="pagination-more">{{ item }}</span>

      <button
        v-else
        type="button"
        :class="['pagination-item', { active: item === value }]"
        @click="$emit('input', item)"
      >
        {{ item }}
      </button>
    </li>

    <li ref="navNext">
      <button
        type="button"
        :class="['pagination-navigation', { disabled: isValueLast }]"
        v-on="isValueLast ? {} : { click: goNext }"
      >
        <font-awesome-icon icon="chevron-right" />
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    value: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      default: 0,
    },
    totalVisible: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    disabled: Boolean,
  },

  data: () => ({
    maxButtons: 0,
  }),

  computed: {
    isValueLast() {
      return this.value >= this.length;
    },

    isValueFirst() {
      return this.value <= 1;
    },

    items() {
      //const maxLength = this.totalVisible > this.maxButtons
      // ? this.maxButtons
      //: this.totalVisible || this.maxButtons;
      const maxLength = this.totalVisible;

      if (this.length <= maxLength || maxLength < 1) {
        return this.getRange(1, this.length);
      }

      const even = maxLength % 2 === 0 ? 1 : 0;
      const left = Math.floor(maxLength / 2);
      const right = this.length - left + 1 + even;

      if (this.value > left && this.value < right) {
        const start = this.value - left + 2;
        const end = this.value + left - 2 - even;

        return ["...", ...this.getRange(start, end), "..."];
      } else if (this.value === left) {
        const end = this.value + left - 2 - even;

        return ["...", ...this.getRange(2, end), "..."];
      } else if (this.value === right) {
        const start = this.value - left + 1;

        return ["...", ...this.getRange(start, this.length)];
      } else {
        return [...this.getRange(1, left), "..."];
      }
    },
  },

  mounted() {
    this.setMaxButtons();
  },

  methods: {
    goNext(e) {
      e.preventDefault();
      this.$emit("input", this.value + 1);
    },

    goPrevious(e) {
      e.preventDefault();
      this.$emit("input", this.value - 1);
    },

    getRange(from, to) {
      const range = [];

      from = from > 0 ? from : 1;

      for (let i = from; i <= to; i++) {
        range.push(i);
      }

      return range;
    },

    setMaxButtons() {
      const containerWidth =
        this.$el && this.$el.parentElement
          ? this.$el.parentElement.clientWidth
          : window.innerWidth;

      const navButton = this.$refs.navNext.getBoundingClientRect();

      // width of the items considering navItem.height = item.width
      const itemWidth = navButton.height;
      const navItemsWidth = navButton.width * 2;

      this.maxButtons = Math.floor(
        (containerWidth - navItemsWidth) / itemWidth
      );
    },
  },
};
</script>

<style scoped lang="scss">
.pagination-item,
.pagination-navigation,
.pagination-more {
  width: 30px;
  height: 30px;
  margin: 0.3rem;
  font-weight: 700;
  @apply text-black;

  @media (max-width: 768px) {
    @apply m-0;
  }
}

.pagination-item,
.pagination-navigation {
  border-radius: 4px;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-duration: 160ms;
  transition-property: opacity, color, background-color;
}

.pagination-item {
  padding: 0 5px;

  // Активная
  &.active {
    @apply bg-blue text-white;
  }

  &:hover,
  &:focus {
    // Ховер на любую
    @apply bg-lightgray;

    // Ховер на активную
    &.active {
      @apply bg-darkblue;
    }
  }

  &:active {
    @apply bg-lightgray;
    &.active {
      @apply bg-lightgray;
    }
  }
}

.pagination-navigation {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  text-decoration: none;

  &.disabled {
    cursor: default;
    opacity: 0;
  }

  &:hover:not(.disabled),
  &:focus:not(.disabled) {
    @apply bg-lightgray;
  }
}

.pagination-more {
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}

.pagination {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  margin: 0;
  list-style-type: none;
  @apply mt-5;

  > li {
    display: flex;
    align-items: center;
  }

  button {
    cursor: pointer;
    border: 0;
    outline: none;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

.pagination-more {
  @apply text-black;
}
</style>
