<template>
  <div class="bg-white rounded-md p-5 mt-5 area-photos">
    <div class="area-photos__title">
      <p>Фотографии района</p>
      <a href="#">
        <placeholder/>
        {{ subLocality.name }}
      </a>
    </div>
    <div class="area-photos__images">
      <swiper slides-per-view="auto" @swiper="onChangeSlide" :space-between="22">
        <swiper-slide >
          <div class="box"></div>
        </swiper-slide>
        <swiper-slide>
          <div class="box"></div>
        </swiper-slide>
        <swiper-slide>
          <div class="box"></div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="area-photos__footer">
      <div class="carousel-counter">
        1 из 6
      </div>
      <div class="carousel-buttons">
        <button class="carousel-buttons__prev"><chevron-left/></button>
        <button class="carousel-buttons__next"><chevron-right/></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {SubLocality} from "@/typings/types";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';

export default defineComponent({
  name: "AreaPhotos",
  props: {
    subLocality: {
      type: Object as PropType<SubLocality>,
      required: true
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    onChangeSlide(swiper: any):void {
      console.log(swiper)
    }
  }
})
</script>

<style scoped lang="scss">
.area-photos {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 25px;
    p{
      @apply text-3xl font-extrabold;
    }
    a{
      border: 1px solid #3D7E9C;
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: 600;
      font-size: 17px;
      line-height: 150%;
      padding: 0 11px;
      @apply text-blue flex items-center;
    }
  }
  &__footer{
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .box{
    background: gray;
    height: 314px;
  }
  .swiper-slide{
    width: 658px;
  }
  .swiper-slide:nth-child(2n + 2){
    width: 430px;
  }
  .carousel-buttons{
    display: flex;
    align-items: center;
    &__prev{
      margin-right: 20px;
    }
  }
}
</style>
