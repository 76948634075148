<template>
  <div v-click-outside="closeDropdown" class="relative">
    <label
      id="listbox-label"
      class="label"
      :class="isInMainFilter ? 'mb-0' : 'mb-3'"
    >
      {{ label }}
    </label>
    <div
      class="multiselect-button">
      <div class="relative h-full">
        <span class="inline-block w-full h-full">
          <button
            type="button"
            @click="toggleDropdown"
            aria-haspopup="listbox"
            aria-expanded="true"
            aria-labelledby="listbox-label"
            class="cursor-pointer relative w-full h-full pl-3 pr-16 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:leading-5">
            <span class="flex items-center space-x-3">
              <span class="block truncate first-letter-upper">
                <slot name="value">
                  <span v-if="value.length !== 0">
                    <span v-if="value.length === 1">
                      <span v-for="d in data" :key="d">
                        <span v-if="value.includes(d.value)">
                          {{ d.name + " " }}
                        </span>
                      </span>
                    </span>
                    <span v-if="value.length > 1">
                      <span v-for="d in data" :key="d">
                        <span v-if="value.includes(d.value)">
                          {{ d.name + " " }}
                        </span>
                      </span>
                    </span>
                    <span v-if="value.length > 3">
                      {{ " ..." }}
                    </span>
                  </span>
                  <span v-else> Не выбрано</span>
                </slot>
              </span>
            </span>
            <span
              class="absolute inset-y-0 arrow right-0 rounded flex items-center pr-3 text-gray pointer-events-none"
              :class="{ rotate180deg: isOpen }">
              <font-awesome-icon icon="chevron-down"/>
            </span>
          </button>
        </span>
      </div>
    </div>
    <div v-show="isOpen">
      <ul class="list">
        <li
          @click="select(d.value, d.name)"
          v-for="d in data"
          v-bind:key="d"
          class="list__item"
          :class="{active: value.includes(d.value)}"
        >
          <div class="list__item__check" :class="{checked: value.includes(d.value)}">
            <checked style="pointer-events: none" v-if="value.includes(d.value)"/>
          </div>
          <div class="list__item__name first-letter-upper">{{ d.name }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ClickOutside from "@/helpers/clickoutside";

export default {
  name: "BaseMultiSelect",
  components: {},
  props: {
    data: Array,
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    studio: {
      type: Number,
      defautl: false,
    },
    isInMainFilter: Boolean,
    label: String,
    name: String,
    tab: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    isSelected(value) {
      return this.value === value;
    },
    closeDropdown() {
      this.isOpen = false;
    },
    openDropdown() {
      this.isOpen = true;
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    select(value, value_name) {
      let payload = {
        name: this.name,
        value: value,
        label: this.label,
        value_label: value_name,
        hide_value: true,
        type: "multiselect",
        tab: this.tab,
      };
      this.$emit("select", payload);
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped lang="scss">
.arrow svg {
  transition: all ease-in-out 0.3s;
  transform: rotate(0deg);
}

.rotate180deg svg {
  transition: all ease-in-out 0.3s;
  transform: rotate(180deg);
}

.inMain {
  @apply border-r-0 md:border-r border-lightgray border-solid border-l-0 border-t-0 border-b-0;
}

input {
  display: none;

  + label::before {
    display: inline-block;
    width: 21px;
    height: 21px;
    margin-right: 10px;
    vertical-align: text-top;
    content: "";
    background: white;
    border: 1px solid #c9c9c9;
    border-radius: 4px;
  }

  &:focus + label::before {
    @apply border-blue;
  }

  &:checked + label::before {
    @apply bg-black border-black;
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label::before {
    background: #ddd;
    box-shadow: none;
  }

  &:checked + label::after {
    position: absolute;
    top: 14px;
    left: 27px;
    width: 15px;
    height: 12px;
    content: "";
    background-image: url("~@/assets/images/checked.svg");
    background-size: cover;
  }
}

.item:hover {
  .checkbox {
    ~ label::before {
      @apply border-gray;
    }
  }
}

.multiselect-button {
  border: 1px solid #C9C9C9;
  box-sizing: border-box;
  border-radius: 5px 5px 4px 4px;
  height: 36px;
}

.label {
  display: block;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 12px;
  @apply text-black;
}

.list {
  position: absolute;
  top: 100%;
  width: 100%;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 4px 4px;
  padding: 20px 0;
  z-index: 20;
  &__item {
    cursor: pointer;
    padding: 5px 12px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #333;
    &__check {
      margin-right: 8px;
      width: 21px;
      height: 21px;
      background: transparent;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #828282;
      &.checked{
        background: #333;
        border: 1px solid #333;
      }
    }
    &:hover{
      background: #DADADA;
    }
  }
}
</style>
