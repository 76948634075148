<template>
  <div class="error-403">
    <div class="error-403__image">
      <svg width="605" height="366" viewBox="0 0 605 366" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M64.4048 263.128L142.467 109.105L143.203 107.653H141.575H80.7979H80.1836L79.9059 108.2L1.10802 263.676L1 263.889V264.128V316.425V317.425H2H113.367V356V357H114.367H171.61H172.61V356V317.425H196.345H197.345V316.425V264.128V263.128H196.345H172.61V211.125V210.125H171.61H114.367H113.367V211.125V263.128H64.4048ZM236.208 326.565L236.213 326.572L236.219 326.579C254.56 350.161 279.595 361.947 311.212 361.947C343.062 361.947 367.986 350.162 385.859 326.57C403.931 302.792 412.918 271.345 412.918 232.326C412.918 193.078 403.934 161.746 385.858 138.434C367.986 114.61 343.064 102.706 311.212 102.706C279.358 102.706 254.318 114.611 236.212 138.436L236.211 138.437C218.374 161.982 209.506 193.311 209.506 232.326C209.506 271.343 218.374 302.787 236.208 326.565ZM268.043 232.326C268.043 207.895 271.719 189.737 278.942 177.718C286.117 165.781 296.827 159.829 311.212 159.829C325.597 159.829 336.308 165.781 343.482 177.718C350.705 189.737 354.381 207.895 354.381 232.326C354.381 256.758 350.705 274.915 343.482 286.934C336.308 298.872 325.597 304.824 311.212 304.824C296.827 304.824 286.117 298.872 278.942 286.934C271.719 274.915 268.043 256.758 268.043 232.326Z"
          stroke="#333333" stroke-width="2"/>
        <path
          d="M519.719 160.075L475.062 216.686L474.612 217.258L475.017 217.863L497.08 250.776L497.377 251.219H497.91H509.846C521.038 251.219 529.314 253.839 534.849 258.913C540.419 264.019 543.205 270.498 543.205 278.43C543.205 286.362 540.419 292.841 534.849 297.947C529.314 303.021 521.038 305.641 509.846 305.641C500.233 305.641 492.337 303.447 486.091 299.128C479.848 294.81 475.169 288.309 472.089 279.545L471.689 278.406L470.644 279.012L420.732 307.946L419.972 308.387L420.311 309.198C427.864 327.227 439.578 340.903 455.45 350.183L455.456 350.186C471.535 359.444 489.674 364.063 509.846 364.063C535.853 364.063 557.909 356.523 575.952 341.406C594.055 326.238 603.074 305.203 603.074 278.43C603.074 261.342 598.065 246.53 588.028 234.043C578.287 221.691 565.545 212.698 549.833 207.06L592.734 152.459L592.947 152.187V151.841V104.823V103.823H591.947H429.191H428.191V104.823V159.075V160.075H429.191H519.719Z"
          stroke="#333333" stroke-width="2"/>
        <path
          d="M236.635 325.86L236.64 325.867L236.645 325.874C254.986 349.455 280.021 361.242 311.639 361.242C343.489 361.242 368.413 349.457 386.286 325.865C404.358 302.086 413.345 270.64 413.345 231.621C413.345 192.373 404.361 161.041 386.285 137.729C368.414 113.904 343.491 102 311.639 102C279.784 102 254.745 113.906 236.638 137.73L236.638 137.732C218.801 161.276 209.933 192.605 209.933 231.621C209.933 270.637 218.801 302.081 236.635 325.86ZM268.47 231.621C268.47 207.189 272.146 189.032 279.369 177.013C286.543 165.075 297.254 159.123 311.639 159.123C326.024 159.123 336.735 165.075 343.909 177.013C351.132 189.032 354.808 207.189 354.808 231.621C354.808 256.052 351.132 274.21 343.909 286.229C336.735 298.167 326.024 304.118 311.639 304.118C297.254 304.118 286.543 298.167 279.369 286.229C272.146 274.21 268.47 256.052 268.47 231.621Z"
          fill="#333333" stroke="#333333" stroke-width="2"/>
        <path
          d="M139.935 203.281L188.75 231.624L291.042 103.736L439.977 169.983L468.849 123.665L275.464 39.7511L139.935 203.281Z"
          fill="#333333"/>
      </svg>
    </div>
    <div class="error-403__message">Доступ запрещен 🔒</div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "NoAccess"
})
</script>

<style scoped lang="scss">
.error-403{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__message{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 100.9%;
    text-align: center;
    color: #333;
    margin-top: 65px;
  }
}
</style>
