import { render } from "./ResidentialComplexFlats.vue?vue&type=template&id=6bd67618&scoped=true"
import script from "./ResidentialComplexFlats.vue?vue&type=script&lang=ts"
export * from "./ResidentialComplexFlats.vue?vue&type=script&lang=ts"

import "./ResidentialComplexFlats.vue?vue&type=style&index=0&id=6bd67618&lang=scss&scoped=true"
const cssModules = script.__cssModules = {}
import style1 from "./ResidentialComplexFlats.vue?vue&type=style&index=1&id=6bd67618&scoped=true&lang=scss&module=true"
cssModules["$style"] = style1
script.render = render
script.__scopeId = "data-v-6bd67618"

export default script