<template>
  <div class="default-checkbox" :class="{primary: primaryColor}" @click="Toggle">
    <div class="default-checkbox__check" :class="{checked: modelValue}">
      <checked v-if="modelValue"/>
    </div>
    <div class="default-checkbox__label">
      {{ label }}
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "DefaultCheckbox",
  props: {
    label: {
      type: String,
      default: (): string => {
        return "";
      }
    },
    modelValue: {
      type: Boolean,
      required: true
    },
    primaryColor: {
      type: Boolean,
      default: (): boolean => {
        return false;
      }
    }
  },
  emits: ['update:modelValue'],
  methods: {
    Toggle() {
      this.$emit("update:modelValue", !this.modelValue);
    }
  }
})
</script>

<style scoped lang="scss">
.default-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__check {
    margin-right: 5px;
    width: 18px;
    height: 18px;
    background: transparent;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #C9C9C9;

    &.checked {
      background: #333;
      border: 1px solid #333;
    }
  }

  &__label {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #333;
  }

  &.primary {
    .default-checkbox__check {
      border: 1px solid #6EB0CF;
      &.checked{
        background: #6EB0CF;
      }
    }
    .default-checkbox__label{
      color: #6EB0CF;
    }
  }
}
</style>
