import { ObjectInfo, Param,ResidentalComplexInfo } from "@/typings/types";
import { GetNameByKey } from "@/ts-helpers/GetNameByKey";

export default function ParseParams(
  keys_array: string[],
  params_list: ObjectInfo
): Param[] {
  let res: Param[] = [];
  keys_array.forEach((param: string) => {
    let value = params_list[param];
    let name = GetNameByKey(param);

    if (typeof value === "boolean") value = value ? "Есть" : "Нет";
    if (typeof value === "undefined") value = "Не указан";

    if(value !== 0)
    res.push({ name, value });
  });
  return res;
}
export function ParseResidentalComplexParams(info: ResidentalComplexInfo): Param[] {
  let res: Param[] = [];
  for (let key in info){
    let name = GetNameByKey(key);
    let value = info[key];

    if (typeof value === "boolean") value = value ? "есть" : "нет";
    if (typeof value === "undefined") value = "не указан";
    res.push({name,value});
  }
  return res;
}
