<template>
  <span
    @click="ChangeSortType"
    class="sort-button"
    :class="{ active: active, asc: asc, desc: desc }"
  >
    {{ title }}
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.54319 11L11 6.76147L8.94424 4.80636L6.96892 6.68489L6.96892 0L4.06161 0L4.06161 6.68489L2.05576 4.77724L-8.01499e-08 6.73235L4.48743 11H6.54319Z"
        fill="#333333"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SortButton",
  /*
   *
   * @prop title - Заголовок для кнопки
   * @prop sort  - Какую сортировку активирует кнопка
   * @prop sortNow - Кака сортировка активка на данный момент
   * */
  props: {
    title: {
      type: String,
      required: true,
    },
    sort: {
      type: String,
      required: true,
    },
    sortNow: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      type: "asc",
    };
  },
  methods: {
    ChangeSortType() {
      if (this.type === "asc") this.type = "desc";
      else this.type = "asc";

      this.$emit("changeSort", this.sort, this.type);
    },
  },
  computed: {
    /*
     * Проверка на совпадение sort и sortNow
     * OUTPUT True | False
     * */
    active(): boolean {
      return this.sort === this.sortNow;
    },
    /*
     * Проверка на активный тип сортировки
     * OUTPUT True | False
     * */
    asc(): boolean {
      return this.active && this.type === "asc";
    },
    /*
     * Проверка на активный тип сортировки
     * OUTPUT True | False
     * */
    desc(): boolean {
      return this.active && this.type === "desc";
    },
  },
});
</script>

<style scoped lang="scss">
.sort-button {
  display: flex;
  align-items: center;
  margin-left: 7px;
  cursor: pointer;
  user-select: none;

  svg {
    margin-left: 7px;
  }

  &.active {
    color: #3d7e9c;

    svg {
      path {
        fill: #3d7e9c;
      }
    }
  }

  &.asc {
    svg {
      transform: rotate(180deg);
    }
  }
}
</style>
