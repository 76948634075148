<template>
  <div class="my-5">
    <NewsItemBanner />

    <div class="container mx-auto">
      <div class="flex justify-between">
        <NewsItemText class="w-7/12" />
        <aside :class="$style.aside">
          <NewsItemAside />
        </aside>
      </div>
    </div>
  </div>
</template>

<script>
import NewsItemBanner from "@/views/NewsItem/Components/NewsItemBanner";
import NewsItemText from "@/views/NewsItem/Components/NewsItemText";
import NewsItemAside from "@/views/NewsItem/Components/NewsItemAside";

export default {
  name: "NewsItem",
  components: {
    NewsItemBanner,
    NewsItemText,
    NewsItemAside,
  },
};
</script>

<style scoped module>
.aside {
  background-image: url("~@/assets/images/aside-bg.png");
  @apply sticky top-20 h-80 w-4/12 bg-no-repeat bg-cover text-white p-7 flex rounded-xl mt-5;
}
</style>
