
import { defineComponent } from "vue";

export default defineComponent({
  name: "DocumentDropdown",
  props: {
    title: {
      type: String,
      required: true,
    },
    files_count: {
      type: Number,
      default: (): number => {
        return 0;
      },
    },
  },
  data() {
    return {
      show: false,
    };
  },
});
