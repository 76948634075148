<template>
  <div class="filter-map-search">
    <div class="filter-map-search__close" @click="$emit('close')">Закрыть карту</div>
    <div class="filter-map-search__wrapper" v-if="!loading && !error">
      <div class="filter-map-search__toggle" @click="ShowList">
        <p>Показать предложения</p>
      </div>
      <div id="search_map"></div>
      <SearchOnMapOffersList ref="OffersList" @changeSort="setSort" :offers-len="offersCount"
                             @nextPage="GetOffersByIdsNextPage" :next-page-loading="nextPageLoading"
                             :loading="offersLoading" :error="offersError"
                             :offers="inOffersList"
                             :current-sort="currentSort"/>
    </div>
    <div v-else-if="loading" class="filter-map-search__loading">
      <BaseLoader/>
    </div>
  </div>
</template>

<script>
import {get_offers, get_offer_cards_by_id} from "@/api/searchApi";
import clickOutside from "@/helpers/clickoutside";
import {CopyObject} from "@/helpers/CopyObject";
import ClearEmptyFields from "@/ts-helpers/ClearEmptyFields";
import FormatNum from "@/ts-helpers/formatNum";
import declOfNum from "@/ts-helpers/declOfNum";
//components
import SearchOnMapOffersList from "@/views/Search/components/SearchOnMapOffersList";
import ObjectGridCard from "@/components/common/Products/components/ObjectGridCard.vue";
import BaseLoader from "@/components/base/loaders/BaseLoader";

/* eslint-disable */
export default {
  name: "SearchOnMap",
  props: {
    key: {
      type: Number,
      default: () => {
        return 1
      }
    }
  },
  components: {
    ObjectGridCard,
    BaseLoader,
    SearchOnMapOffersList
  },
  data() {
    return {
      placemark: require("@/assets/icons/map/placemark.svg"),
      placemarkHover: require("@/assets/icons/map/placemark-hover.svg"),
      placemarkInPolygon: require("@/assets/icons/map/placemark-in-polygon.svg"),
      complexIcon: require("@/assets/icons/map/complex.svg"),
      cluster: require("@/assets/icons/map/cluster.svg"),
      clusterHover: require("@/assets/icons/map/cluster-hover.svg"),
      selection: require("@/assets/icons/map/selection.svg"),
      location: require("@/assets/icons/map/location.svg"),
      cross: require("@/assets/icons/map/cross.svg"),
      inOffersList: [],
      offers: [],
      loading: true,
      error: false,
      offersLoading: false,
      offersError: false,
      currentSort: {label: "Сначала новые", sort: "asc", field: "date"},
      nextPageLoading: false,
      IDS: {objects: [], complexes: []}
    }
  },
  mounted() {
    this.GetOffers();
    document.body.style.overflow = "hidden"
  },
  unmounted() {
    document.body.style.overflow = "";
    if(window.map !== null)
    window?.map?.destroy();
    window.map = null;
  },
  computed: {
    mapMaxCornerPos() {
      if (window.innerWidth > 1024) return 50;
      else return 15;
    },
    offersCount() {
      return this.IDS.complexes.length + this.IDS.objects.length
    }
  },
  methods: {

    async init() {
      if (window.map) {
        window.map.destroy();
        window.map = null;
      }
      //variables
      window.map = new ymaps.Map('search_map', {
        center: [45.0448, 38.976],
        zoom: 13,
        controls: [],
      });
      //layouts
      const ClusterLayout = ymaps.templateLayoutFactory.createClass(
        '<div class="map-cluster-object $[options.active] $[options.inPolygon]">$[properties.geoObjects.length]</div>')
      const DrawControlLayout = ymaps.templateLayoutFactory.createClass([
        `<div class="map-draw-control {% if state.selected %} selected {% endif %}">
        <div class="map-draw-control__button"><img class="map-draw-control__img" src="{{ data.image }}" alt="{{ data.title }}"></div>
        <span class="map-draw-control__text">{{ data.content }}</span>
        </div>`
      ].join(''));
      const DeletePolygonControlLayout = ymaps.templateLayoutFactory.createClass([
        '<div title="{{ data.title }}" class="map-delete-polygon-control ',
        '{% if state.selected %} map-delete-polygon-control__selected{% endif %}">',
        '<img class="map-delete-polygon-control__img" src="{{ data.image }}" alt="{{ data.title }}">',
        '<span class="map-delete-polygon-control__text">{{ data.content }}</span>',
        '</div>'
      ].join(''));
      const LocationControlLayout = ymaps.templateLayoutFactory.createClass([
        '<div title="{{ data.title }}" class="map-location-control ',
        '{% if state.selected %} map-location-control__selected{% endif %}">',
        '<img class="map-location-control__img" src="{{ data.image }}" alt="{{ data.title }}">',
        '<span class="map-location-control__text">{{ data.content }}</span>',
        '</div>'
      ].join(''));
      const ZoomLayout = ymaps.templateLayoutFactory.createClass(`
      <div class="zoom-buttons">
            <div class="zoom-buttons__button" id="zoom-in">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="#333" xmlns="http://www.w3.org/2000/svg">
                <rect y="8.44531" width="19" height="2.11111" />
                <rect x="10.3721" width="19" height="2.11111" transform="rotate(90 10.3721 0)" />
            </svg>
            </div>
            <div class="zoom-buttons__button" id="zoom-out">
            <svg width="19" height="3" viewBox="0 0 19 3" fill="#333" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.111328" width="19" height="2.11111" />
            </svg>

            </div>
       </div>
      `, {
        build: function () {

          ZoomLayout.superclass.build.call(this);
          const ZoomInButton = document.querySelector('#zoom-in'),
            ZoomOutButton = document.querySelector('#zoom-out');

          this.zoomInCallback = ymaps.util.bind(this.zoomIn, this);
          this.zoomOutCallback = ymaps.util.bind(this.zoomOut, this);

          ZoomInButton.addEventListener('click', this.zoomInCallback);
          ZoomOutButton.addEventListener('click', this.zoomOutCallback);

        },

        zoomIn: function () {
          var map = this.getData().control.getMap();
          map.setZoom(map.getZoom() + 1, {checkZoomRange: true});
        },

        zoomOut: function () {
          var map = this.getData().control.getMap();
          map.setZoom(map.getZoom() - 1, {checkZoomRange: true});
        }
      })
      //clusters
      const Cluster = new ymaps.Clusterer({
        clusterIconContentLayout: ClusterLayout,
        active: "",
        clusterIcons: [{
          href: '',
          size: [40, 40],
          // Отступ, чтобы центр картинки совпадал с центром кластера.
          offset: [0, 0]
        },]
      });
      const ClusterActive = new ymaps.Clusterer({
        clusterIconContentLayout: ClusterLayout,
        inPolygon: "in-polygon",
        clusterIcons: [{
          href: '',
          size: [40, 40],
          // Отступ, чтобы центр картинки совпадал с центром кластера.
          offset: [0, 0]
        },]
      });
      //стиль линии при выделении области
      const lineStyle = {
        strokeColor: '#3D7E9C',
        strokeOpacity: 1,
        strokeWidth: 3,
        fillColor: '#3d7e9c',
        fillOpacity: 0.1
      };
      //Кнопка геолокации
      const LocationControl = new ymaps.control.GeolocationControl({
        data: {
          image: this.location,
        },
        options: {
          layout: LocationControlLayout,
          noPlacemark: true,
          position: {
            right: this.mapMaxCornerPos,
            top: 172
          },
          size: "large",
          maxWidth: 37
        }
      })
      //Зум контроллер для карты
      const ZoomControl = new ymaps.control.ZoomControl({
        options: {
          layout: ZoomLayout,
          position: {
            right: this.mapMaxCornerPos,
            top: 18
          }
        }
      })
      // кнопка переключения для выбора области
      const ControlDrawPolygonButton = new ymaps.control.Button({
        data: {
          image: this.selection,
          content: "Выделить область"
        },
        options: {
          layout: DrawControlLayout,
          position: {
            right: this.mapMaxCornerPos,
            top: 115
          },
          size: "large",
          maxWidth: 37
        }
      });
      // удаление полигона
      const ControlDeletePolygon = new ymaps.control.Button({
        data: {
          image: this.cross,
        },
        options: {
          layout: DeletePolygonControlLayout,
          position: {
            right: this.mapMaxCornerPos,
            top: 115
          },
          size: "large",
          maxWidth: 37
        }
      })
      //модуль для рисования на карте
      let paintProcess;
      //список маркеров
      const markers = await this.Markers();
      //старый полигон
      let oldPolygon = null;
      //добавить маркеры на карту
      const objects = ymaps.geoQuery(markers)
      //functions
      let objectsInActiveCluster = [];
      const ShowAllObjectOnWindow = ()=>{
        let visibleObjects = objects.searchInside(window.map);
        let zoom = 13;
        while(visibleObjects !== this.offers.length){
          zoom--;
          window.map.setZoom(zoom);
          visibleObjects = objects.searchInside(window.map);
          if(visibleObjects._objects.length === this.offers.length) break;
          if(zoom < 5 ) break;
        }
      }
      const SetOnlyVisible = () => {
        const visibleObjects = objects.searchInside(window.map);
        ClusterActive.add(objectsInActiveCluster);
        window.map.geoObjects.removeAll();
        Cluster.add(visibleObjects._objects);
        window.map.geoObjects.add(Cluster)
        window.map.geoObjects.add(ClusterActive)
        if (oldPolygon)
          window.map.geoObjects.add(oldPolygon);
        else {
          let params = {
            objects: [],
            complexes: []
          };
          visibleObjects.each((item) => {
            const id = item.properties.get('id');
            const type = item.properties.get('type');
            if (type === "object") params.objects.push(id);
            else params.complexes.push(id);
          })
          this.GetOffersByIds(params);
        }
      }
      const DrawStart = async (e) => {
        if (ControlDrawPolygonButton.isSelected()) {
          paintProcess = ymaps.ext.paintOnMap(window.map, e, {style: lineStyle});
        }
      }
      const DrawEnd = (e) => {
        if (paintProcess) {
          //координаты для будущего полигона
          const coordinates = paintProcess.finishPaintingAt(e);
          //останавливаем процесс рисования
          paintProcess = null;
          //создаем полигон и добавляем на карту
          const polygon = new ymaps.Polygon([coordinates], {}, lineStyle);
          window.map.geoObjects.add(polygon);
          //ищем объект в полигоне
          const objectsInsidePolygon = objects.searchInside(polygon);
          //делаем активные иконки
          objectsInsidePolygon.setProperties('inPolygon', "in-polygon");
          //сохраним данные для активного кластера
          objectsInActiveCluster = objectsInsidePolygon._objects;
          //добавляем их в отдельный кластер
          ClusterActive.add(objectsInsidePolygon._objects)
          //добавляем на карту этот кластер
          window.map.geoObjects.add(ClusterActive)
          //удаляем с карты объекты внутри полигона (которые не активны)
          objects.remove(objectsInsidePolygon).setProperties('inPolygon', "");
          //отключить возможность выелять снова
          ControlDrawPolygonButton.deselect();
          //если есть старый полигон то удалить
          window.map.controls.add(ControlDeletePolygon);
          if (oldPolygon !== null) {
            window.map.geoObjects.remove(oldPolygon)
          }
          //сохранить полигон
          oldPolygon = polygon;
          //получим ID каждого объекта в полигоне и запихнем в список офферов
          let params = {
            objects: [],
            complexes: []
          }
          objectsInsidePolygon.each((item) => {
            const objectInsidePolygonID = item.properties.get('id');
            const objectInsidePolygonTYPE = item.properties.get('type');
            if (objectInsidePolygonTYPE === "object") params.objects.push(objectInsidePolygonID);
            else params.complexes.push(objectInsidePolygonID);
          })
          this.GetOffersByIds(params);
        }
      }
      const DeletePolygon = () => {
        window.map.geoObjects.remove(oldPolygon);
        oldPolygon = null;
        objects.setProperties('inPolygon', "");
        objectsInActiveCluster = [];
        ClusterActive.removeAll();
        SetOnlyVisible();
        window.map.controls.remove(ControlDeletePolygon);
      };
      const ClusterEnter = (e) => {
        const target = e.get('target');
        target.options.set('active', 'active');
      }
      const ClusterLeave = (e) => {
        const target = e.get('target');
        target.options.set('active', '');
      }
      const ClusterClick = (e) => {
        const target = e.get('target');
        let IDS = [];
        if(e.currentTarget?._objects?.lenght)
        target.getGeoObjects().map(item => {
          IDS.push(item.properties.get('id'))
        })
        this.GetOffersByIds(IDS);
      }
      //controls
      ControlDeletePolygon.events.add('press', DeletePolygon);
      window.map.controls.add(ControlDrawPolygonButton);
      window.map.controls.add(ZoomControl);
      window.map.controls.add(LocationControl);

      //window.map.controls.add(LocationControl);
      //delete unused
      window.map.behaviors.disable(['rightMouseButtonMagnifier'])
      //map events
      window.map.events.add('mousedown', DrawStart);
      window.map.events.add('mouseup', DrawEnd);
      //window.map.events.add('touchstart', DrawStart);
      //window.map.events.add('touchend', DrawEnd);

      window.map.events.add('boundschange', SetOnlyVisible);
      Cluster.events.add('mouseenter', ClusterEnter);
      Cluster.events.add('mouseleave', ClusterLeave);
      Cluster.events.add('click', ClusterClick);
      //functions calls
      SetOnlyVisible();
      ShowAllObjectOnWindow();
    },
    Markers() {
      let markers = [];
      //layout для маркера
      let ObjectLayout = ymaps.templateLayoutFactory.createClass(
        `<div class="map-placemark-layout $[properties.active] $[properties.inPolygon]">
                    <div class="map-placemark-layout__pop">
                          <div class="map-placemark-layout__pop__image">
                                <img src="$[properties.image]" alt="">
                          </div>
                          <div class="map-placemark-layout__pop__content">
                            <div class="map-placemark-layout__pop__content__address">$[properties.address]</div>
                            <div class="map-placemark-layout__pop__content__footer">
                            <div class="map-placemark-layout__pop__content__footer__type first-letter-upper">$[properties.category]</div>
                            <div class="map-placemark-layout__pop__content__footer__price">$[properties.price] ₽</div>
                          </div>
                          </div>
                    </div>
                </div>`
      );
      let ComplexLayout = ymaps.templateLayoutFactory.createClass(
        `<div class="map-realty-object-layout $[properties.active] $[properties.inPolygon]">
                    <div class="map-realty-object-layout__mark">
                      <svg width="16" height="25" viewBox="0 0 16 25" fill="#333" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.0435 0.478516V24.6416H9.46737V21.8535H5.74998V24.6416H0.173889V4.68078L15.0435 0.478516Z" />
                      </svg>
                    </div>
                    <div class="map-realty-object-layout__pop">
                        <div class="map-realty-object-layout__pop__name">ЖК $[properties.complexName]</div>
                        <div class="map-realty-object-layout__pop__footer">
                            <div class="map-realty-object-layout__pop__footer__flats">$[properties.flats_count]</div>
                            <div class="map-realty-object-layout__pop__footer__price">от $[properties.price] ₽</div>
                        </div>
                    </div>
                    {% if properties.flats_count_total > 30 %} <div class="map-realty-object-layout__ladder ladder_1"></div> {% endif %}
                    {% if properties.flats_count_total > 70 %} <div class="map-realty-object-layout__ladder ladder_2"></div> {% endif %}

                </div>`
      );
      this.offers.forEach(offer => {
        const coords = [offer.coordinates.lat, offer.coordinates.lon];
        const type = (offer.object_info !== undefined) ? "object" : "complex";
        const image = offer.photos[0] ? offer.photos[0].preview : "/img/no-images/svg";
        const price = type === "object" ? offer.object_info.price : offer.residential_complex_info.price_from;
        const category = type === "object" ? offer.object_info.category : "";
        const flats_count = offer.residential_complex_info?.total_flats_count;
        const complexName = offer.residential_complex_info?.name;
        //вид точки на карте
        let ObjectPlacemark = new ymaps.Placemark(coords, {
          id: offer.id,
          type: type,
          active: "",
          address: offer.address,
          image: image,
          price: FormatNum(price),
          category: category,
          inPolygon: ""
        }, {
          iconLayout: 'default#imageWithContent',
          iconImageSize: [17, 17],
          iconImageOffset: [0, 0],
          iconContentOffset: [0, 0],
          iconContentLayout: ObjectLayout
        });
        let ComplexPlacemark = new ymaps.Placemark(coords, {
            id: offer.id,
            type: type,
            active: "",
            inPolygon: "",
            icon: this.complexIcon,
            complexName: complexName,
            flats_count: `${flats_count} ${declOfNum(flats_count, ["квартира", "квартиры", "квартир"])}`,
            flats_count_total: flats_count,
            price: FormatNum(price),
          },
          {
            iconLayout: 'default#imageWithContent',
            iconImageSize: [36, 36],
            iconImageOffset: [0, 0],
            iconContentOffset: [0, 0],
            iconContentLayout: ComplexLayout
          })
        const placemark = type === "object" ? ObjectPlacemark : ComplexPlacemark;
        //события для этой точки
        placemark.events
          .add('mouseenter', (e) => {
            e.get('target').properties.set('active', "active");
          })
          .add('mouseleave', (e) => {
            e.get('target').properties.set('active', "");
          })
          .add('click', (e) => {
            const id = e.get('target').properties.get('id');
            const type = e.get('target').properties.get('type');
            let params = {};
            if (type === "object") params = {objects: [id]};
            else params = {complexes: [id]};
            this.GetOffersByIds(params);
          })
        markers.push(placemark);
      })
      return markers;
    },
    GetOffers() {
      this.loading = true;
      this.error = false;
      let main_filter = ClearEmptyFields(CopyObject(this.$store.state.search.main_filter));
      let additional_filter = ClearEmptyFields(CopyObject(this.$store.state.search.additional_filter), true);
      const params = {
        per_page: 1000,
        page: 1,
        main_filter,
        additional_filter
      }
      get_offers(params)
        .then(({data}) => {
          this.offers = data.results;
          this.loading = false;
          this.error = false;
          ymaps.ready(['ext.paintOnMap']).then(() => {
            this.init();
          });
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
        })
    },
    GetOffersByIds({objects, complexes}) {
      this.offersLoading = true;
      this.offersError = false;
      this.IDS = {objects: objects ?? [], complexes: complexes ?? []}
      get_offer_cards_by_id({
        objects,
        complexes,
        sort: this.currentSort.field,
        sort_by: this.currentSort.sort,
        page: 1,
        per_page: 5
      })
        .then(({data}) => {
          this.offersLoading = false;
          this.offersError = false;
          this.inOffersList = data.results;
          this.page = 1
        })
    },
    setSort(type) {
      this.currentSort = type;
      this.offersLoading = true;
      this.offersError = false;
      get_offer_cards_by_id({
        ...this.IDS,
        sort: type.field,
        sort_by: type.sort,
        per_page: 5,
        page: 1
      })
        .then(({data}) => {
          this.offersLoading = false;
          this.offersError = false;
          this.inOffersList = data.results;
          this.page = 1
        })
    },
    GetOffersByIdsNextPage() {
      this.nextPageLoading = true;
      get_offer_cards_by_id({
        ...this.IDS,
        sort: this.currentSort.field,
        sort_by: this.currentSort.sort,
        per_page: 5,
        page: this.page + 1
      })
        .then(({data}) => {
          this.nextPageLoading = false
          this.inOffersList = [...this.inOffersList, ...data.results];
          this.page = data.page;
        })
    },
    ShowList() {
      this.$refs.OffersList.ShowList();
    }
  },
  directives: {
    clickOutside
  }
}
</script>
<style lang="scss">
/* stylelint-disable */
.map-cluster-object {
  height: 46px;
  width: 46px;
  background: rgba(61, 126, 156, 0.2);
  border: 2px solid #3D7E9C;
  box-sizing: border-box;
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3D7E9C;
  font-weight: bold;
  border-radius: 100%;

  &.active {
    border: 2px solid #333;
    background: rgba(0, 0, 0, 0.2);
    color: #333;
  }

  &.in-polygon {
    color: #333;
    background: rgba(46, 98, 121, 0.8);
    border: 2px solid #2E6279;
  }
}

.map-placemark-layout {
  position: relative;
  height: 17px;
  width: 17px;
  border-radius: 100%;
  background: rgba(61, 126, 156, 0.7);
  border: 2px solid #2E6279;
  box-sizing: border-box;
  backdrop-filter: blur(3px);

  &__pop {
    display: none;
    position: absolute;
    left: 100%;
    bottom: 100%;

    &__image {
      height: 135px;
      width: 205px;
      overflow: hidden;
      border-radius: 4px 4px 0 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__content {
      height: 74px;
      width: 205px;
      background: rgba(255, 255, 255, 0.8);
      border: 0.3px solid rgba(0, 0, 0, 0.5);
      border-top: none;
      box-sizing: border-box;
      backdrop-filter: blur(2px);
      border-radius: 0 0 4px 4px;
      padding: 8px 7px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      &__address {
        text-align: left;
        font-family: Gilroy;
        margin-bottom: 7px;
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 105%;
        color: #333;
      }

      &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__type {
          text-align: left;
          font-family: Gilroy;
          font-style: normal;
          font-weight: 800;
          font-size: 12px;
          line-height: 150%;
          color: #333;
        }

        &__price {
          text-align: right;
          font-family: Gilroy;
          font-style: normal;
          font-weight: 800;
          font-size: 13.6667px;
          line-height: 150%;
          color: #333;
        }
      }
    }
  }

  &.active {
    background: #333;
    border: 2px solid #333;

    .map-placemark-layout__pop {
      display: block;
    }
  }

  &.in-polygon {
    border: 2px solid #2E6279;
    background: rgba(46, 98, 121, 0.8);
  }
}

.map-realty-object-layout {
  z-index: 1000000000000;
  position: relative;

  &__mark {
    border-radius: 100%;
    height: 36px;
    width: 36px;
    background: rgba(61, 126, 156, 0.2);;
    border: 2px solid #3D7E9C;
    box-sizing: border-box;
    backdrop-filter: blur(3px);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: #3D7E9C;
    }
  }

  &__pop {
    display: none;
    z-index: 6;
    height: 74px;
    width: 205px;
    position: absolute;
    left: calc(100% + 30px);
    bottom: 20%;
    background: rgba(255, 255, 255, 0.8);
    border: 0.3px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    backdrop-filter: blur(2px);
    border-radius: 4px;
    padding: 16px 8px 9px 8px;
    justify-content: space-between;
    flex-direction: column;

    &__name {
      text-align: left;
      font-family: Gilroy;
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      line-height: 105%;
      color: #3D7E9C;
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__flats {
        font-family: Gilroy;
        font-style: normal;
        font-weight: 800;
        font-size: 13px;
        line-height: 150%;
        color: #3D7E9C;
      }

      &__price {
        font-family: Gilroy;
        font-style: normal;
        font-weight: 800;
        font-size: 13px;
        line-height: 150%;
        text-align: right;
        color: #333;
      }
    }
  }

  &__ladder {
    display: none;
    height: 74px;
    width: 205px;
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    border: 0.3px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    backdrop-filter: blur(2px);
    border-radius: 4px;

    &.ladder_1 {
      z-index: 5;
      left: calc(100% + 33px);
      bottom: calc(20% - 3px);
    }

    &.ladder_2 {
      z-index: 4;
      left: calc(100% + 36px);
      bottom: calc(20% - 6px);
    }
  }

  &.active {
    .map-realty-object-layout__mark {
      background: rgba(0, 0, 0, 0.2);
      border: 2px solid #333;

      svg {
        fill: #333;
      }
    }

    .map-realty-object-layout__pop {
      display: flex;
    }

    .map-realty-object-layout__ladder {
      display: block;
    }
  }

  &.in-polygon {
    .map-realty-object-layout__mark {
      background: rgba(46, 98, 121, 0.8);
      border: 2px solid #2E6279;

      svg {
        fill: #333;
      }
    }

  }
}

.map-draw-control {
  position: relative;

  &__button {
    width: 37px;
    height: 37px;
    background: #3D7E9C;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    transition: .5s;
    z-index: 2;
    position: relative;
  }

  &__text {
    transition: .8s;
    padding: 8px 9px;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    height: 37px;
    width: 0;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.8);
    border: 0.3px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    backdrop-filter: blur(2px);
    border-radius: 4px;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #333;

  }

  &.selected {
    .map-draw-control__button {
      background: #333;
    }
  }

  &:hover:not(&.selected) {
    .map-draw-control__text {
      width: 174px;
    }
  }
}

.map-delete-polygon-control {
  position: relative;
  z-index: 10;
  width: 37px;
  height: 37px;
  background: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  transition: .5s;
}

.ymaps-2-1-78-default-cluster {
  color: #3D7E9C;
  font-weight: bold;
  border-radius: 100%;
  backdrop-filter: blur(3px);

  &:hover {
    background-image: url("~@/assets/icons/map/cluster-hover.svg");
  }

}

.map-location-control {
  width: 37px;
  height: 37px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  transition: .5s;
  backdrop-filter: blur(2px);
  border: 0.3px solid rgba(0, 0, 0, 0.2);
}

.zoom-buttons {
  &__button {
    width: 37px;
    height: 37px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    transition: .5s;
    backdrop-filter: blur(2px);
    border: 0.3px solid rgba(0, 0, 0, 0.2);

    &:first-child {
      margin-bottom: 3px;
    }

    &:hover {
      background: #333;

      svg {
        fill: #fff;
      }
    }
  }
}
/* stylelint-enable */
</style>
<style scoped lang="scss">

.styled-scroll {
  &::-webkit-scrollbar {
    width: 5px;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #333;

  }
}

.filter-map-search {
  position: fixed;
  top: 106px;
  left: 0;
  height: calc(100vh - 106px);
  width: 100%;
  z-index: 5;
  background: #fff;

  #search_map {
    height: 100%;
  }

  &__wrapper {
    height: 100%;
    width: 100%;
  }

  &__toggle {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 17px;
    width: 100px;
    height: 40px;
    background: #fff;
    border-radius: 0 4px 4px 0;
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 12px;
      text-align: center;
      color: #333;
    }

    &:hover {
      background: #efefef;
    }
  }

  &__close {
    display: none;
    position: absolute;
    bottom: 90px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 10px 15px;
    background: #fff;
    color: #333;
    z-index: 10;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:hover {
      background: #efefef;
    }

    @media(max-width: 1024px) {
      display: block;
    }
  }

  &__loading {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media(max-width: 768px) {
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 10000;
  }
}
</style>
