<template>
  <div class="p-5 bg-white mt-5 rounded-md">
    <h2 class="text-3xl font-extrabold mb-3">Документация</h2>
    <div class="mb-3"><document-dropdown /></div>
    <div class="mb-3"><document-dropdown /></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DocumentDropdown from "@/components/base/dropdown/DocumentDropdown.vue";
export default defineComponent({
  name: "Documents",
  components: {
    DocumentDropdown,
  },
});
</script>

<style scoped></style>
