
import {defineComponent, PropType} from "vue";
import {Flat} from "@/typings/types"
import FormatNum from "@/ts-helpers/formatNum";
import ClickOutside from "@/helpers/clickoutside.js"
import ResidentialComplexFlat from "@/components/common/ResidentialComplexFlats/components/ResidentialComplexFlat.vue";

export default defineComponent({
  name: "ListFlat",
  props: {
    flat: {
      type: Object as PropType<Flat>,
      required: true
    },
    filter: {
      type: Object,
      required: true
    }
  },
  components: {
    ResidentialComplexFlat
  },
  emits: ['reset', "setFlat"],
  data() {
    return {
      showDetails: false
    }
  },
  computed: {
    price(): string {
      return FormatNum(this.flat.price ?? 0) + " ₽";
    },
    price_per_unit(): string {
      const price: string = String(this.flat.price).replaceAll(",",".");
      const area: string = String(this.flat.area).replaceAll(",",'.');
      const res = parseInt(price) / parseInt(area)
      let tmp = Math.floor(res);
      return FormatNum(tmp) + " ₽";
    },
    rooms(): string {
      return `${this.flat.rooms_count}-ком.`
    },
    seller(): string {
      return `От ${this.flat.seller}a`;
    },
  },
  methods: {
    Hide() {
      this.showDetails = false;
    },
    Toggle() {
      this.showDetails = !this.showDetails;
    }
  },
  directives: {
    ClickOutside
  }
})
