<template>
  <div v-touch:swipe.right="SwipeHandlerPrev"
       v-touch:swipe.left="SwipeHandlerNext"
       class="images-carousel"
       @mouseenter="startTimer"
       @mouseleave="endTimer"
       @mousemove="AllowToUse"
  >
    <div v-if="photos && photos.length" :class="{loading: !image_complete}" class="images-carousel__current-image">
      <img :src="photos[currentImageIndex].preview" alt="" @load="OnImageLoading">
    </div>
    <div class="images-carousel__buttons" @click="ToggleLightbox">
      <div v-for="(button,i) in photos.slice(0,5)" :key="button" :class="{active: i === currentImageIndex}"
           class="images-carousel__button" @mousemove="SetImage(i)"></div>
    </div>
    <div v-if="currentImageIndex === 4 && photos.length > 5" class="images-carousel__more">
      <div class="font-semibold text-2xl m-auto opacity-80">
        Еще {{ photos.length - 5 }} {{ images_text }}
      </div>
    </div>
    <lightbox v-if="withLightbox && showLightbox && photos.length" :photos="photos" @closeLightbox="ToggleLightbox"/>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {Photo} from "@/typings/types";
import declOfNum from "@/ts-helpers/declOfNum";
import lightbox from "@/components/base/images/lightbox.vue";

export default defineComponent({
  name: "ImagesCarousel",
  props: {
    photos: {
      type: [Object] as PropType<Photo[]>,
      required: true,
    },
    delay: {
      type: Number,
      default: (): number => {
        return 0;
      }
    },
    withLightbox: {
      type: Boolean,
      default: (): boolean => {
        return false;
      }
    }
  },
  components: {
    lightbox
  },
  data(): {
    currentImageIndex: number,
    allowToUse: boolean,
    time_start: any,
    image_complete: boolean,
    showLightbox: boolean
  } {
    return {
      currentImageIndex: 0,
      allowToUse: false,
      time_start: new Date(),
      image_complete: true,
      showLightbox: false
    }
  },
  computed: {
    images_text(): string {
      return declOfNum(this.photos.length - 5, [
        "изображение",
        "изображения",
        "изображений",
      ]);
    },
  },
  methods: {
    SetImage(index: number): void {
      if (this.allowToUse)
        this.currentImageIndex = index;
      this.image_complete = true;
    },
    AllowToUse(): void {
      this.allowToUse = Date.now() - this.time_start >= this.delay;
    },
    startTimer(): void {
      this.time_start = Date.now();
    },
    endTimer(): void {
      this.time_start = null;
    },
    onImageLoading(): void {
      this.image_complete = false;
    },
    ToggleLightbox(): void {
      this.showLightbox = !this.showLightbox;
    },
    SwipeHandlerNext(): void {
      if(this.currentImageIndex < this.photos.slice(0,5).length - 1)
      this.currentImageIndex++;
    },
    SwipeHandlerPrev(): void {
      if(this.currentImageIndex !== 0)
      this.currentImageIndex--;
    }
  }
})
</script>

<style lang="scss" scoped>
.images-carousel {
  position: relative;
  width: 100%;
  height: 100%;

  &__current-image {
    height: 100%;
    width: 100%;
    @apply bg-milk;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.loading {
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
    }
  }

  &__buttons {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 5;
  }

  &__button {
    height: 100%;
    display: block;
    position: relative;
    cursor: pointer;
    @apply block w-full mx-2;
    &::after {
      content: "";
      position: absolute;
      bottom: 3px;
      left: 0;
      width: 100%;
      height: 3px;
      @apply bg-white;
    }

    &.active {
      &::after {
        content: "";
        position: absolute;
        bottom: 3px;
        left: 0;
        width: 100%;
        height: 3px;
        @apply bg-blue;
      }
    }
  }

  &__more {
    z-index: 2;
    @apply bg-black bg-opacity-50 text-white absolute w-full h-full top-0 left-0 flex cursor-pointer;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
</style>
