import { render } from "./NewsItemAside.vue?vue&type=template&id=d3c44a6a&scoped=true"
import script from "./NewsItemAside.vue?vue&type=script&lang=js"
export * from "./NewsItemAside.vue?vue&type=script&lang=js"

const cssModules = script.__cssModules = {}
import style0 from "./NewsItemAside.vue?vue&type=style&index=0&id=d3c44a6a&scoped=true&module=true&lang=scss"
cssModules["$style"] = style0
script.render = render
script.__scopeId = "data-v-d3c44a6a"

export default script