<template>
  <div class="container mx-auto">
    <div class="banner p-5 flex flex-col bg-cover text-white relative">
      <div class="my-auto text-sm">
        Популярное
        <h1 class="text-5xl font-bold">
          Как меньше платить по <br />
          действующей ипотеке
        </h1>
      </div>
      <div class="flex text-sm">
        <div class="">
          8 часов назад
          <font-awesome-icon class="mx-3" icon="eye" />
          249
        </div>
        <div class="ml-auto">
          Время чтения 5 минут
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsItemBanner",
};
</script>

<style scoped>
.banner {
  height: 480px;
  background-image: url("~@/assets/images/newsItem-bg.png");
}
</style>
