<template>
  <div class="floor">
    <floor-flat v-for="flat in filledFlats" :key="flat" :flat="flat" :filter="filter" :current-floor="currentFloor"
                :total-floors="totalFloors"/>
    <div class="floor__number">{{ currentFloor }}</div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {Flat} from "@/typings/types";
import FloorFlat from "@/components/common/ResidentialComplexFlats/components/FloorFlat.vue";

export default defineComponent({
  name: "Floor",
  props: {
    flats: {
      type: [Object] as PropType<Flat[]>,
      required: true
    },
    minLen: {
      type: Number,
      default: (): number => {
        return 5;
      }
    },
    filter: {
      type: Object,
      required: true
    },
    currentFloor: {
      type: Number,
      required: true
    },
    totalFloors: {
      type: Number,
      required: true
    }
  },
  components: {
    FloorFlat
  },
  computed: {
    filledFlats(): Flat[] {
      let disabledFlats: Flat[] = [];
      const len = this.flats?.length ?? 0;
      if (this.minLen > len) {
        for (let i = len; i < this.minLen; i++) {
          if (!disabledFlats[i]) {
            disabledFlats.push({
              status: "disabled",
            })
          }
        }
      }
      disabledFlats = [...disabledFlats, ...this.flats];
      return disabledFlats.sort(() => Math.random() - 0.5);
    }
  }
})
</script>

<style scoped lang="scss">
.floor {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 3px;

  &__number {
    pointer-events: none;
    position: absolute;
    right: -15px;
    bottom: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 12.4507px;
    line-height: 150%;
    color: #B4B4B4;
  }
}
</style>
