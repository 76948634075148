<template>
  <div v-if="!loading" class="app-filter">
    <div class="mobile-filter-button" v-if="!OpenFilter" @click="ToggleFilter">
      <filter-icon/>
      <p class="ml-2 text-lg font-semibold text-black">Фильтр</p>
      <div class="mobile-filter-button__arrow">
        <chevron-down/>
      </div>
    </div>
    <div :class="{show: showFilter || OpenFilter}" class="main-filter">
      <div class="app-filter__mobile-search-field">
          <input
            type="text"
            @input="EnterSearch"
            @keyup.enter="Search"
            :value="text_search"
            placeholder="Введите название ЖК, улицы, района"
          />
      </div>
      <base-dropdown :align_right="false">
        <template #values>
          <span class="font-semibold">
            {{ offer_property === 1 ? "Купить" : "Снять" }}
          </span>
        </template>
        <template #inputs>
          <div class="p-3">
            <Radio
              false_text="Купить"
              true_text="Снять"
              :value="offer_property !== 1"
              @change="ChangeOfferProperty"
            />
          </div>
        </template>
      </base-dropdown>
      <Multiselect
        :data="offer_property_types"
        v-model="offer_property_model"
        :value="offer_property_model"
        v-on:valueSelect="OnSelectType"
        :is-in-main-filter="true"
        v-if="routeName !== 'NewFlats'"
      >
        <template #value>
          <span class="font-semibold">Тип недвижимости</span>
        </template>
      </Multiselect>
      <Multiselect
        :data="rooms_choice_data"
        v-model="rooms_choice_model"
        :value="rooms_choice_model"
        v-on:valueSelect="OnSelectRoom"
        :is-in-main-filter="true"
        v-if="types_includes([TYPES.flat, TYPES.new_flats])"
      >
        <template #value><span class="font-semibold">Комнат</span></template>
      </Multiselect>
      <Multiselect
        :data="commercial_types_data"
        v-model="commercial_types_model"
        :value="commercial_types_model"
        v-on:valueSelect="OnSelectCommerce"
        :is-in-main-filter="true"
        v-if="types_includes([TYPES.commerce])"
      >
        <template #value><span class="font-semibold">Тип коммерции</span></template>
      </Multiselect>
      <base-dropdown ref="price_dropdown">
        <template #values><span class="font-semibold">Цена</span></template>
        <template #inputs>
          <div class="w-full p-3">
            <Radio
              @change="ChangePriceType"
              :true_text="'За все'"
              :false_text="pricePerUnitLabel"
              :value="price_unit"
            />
            <div class="flex mt-5" v-if="price_unit">
              <InputWithCurrency
                v-model="price_model.from"
                :text-left="'от'"
                class="mr-2"
                :text-right="'₽'"
              />
              <InputWithCurrency
                v-model="price_model.to"
                :text-left="'до'"
                :text-right="'₽'"
                class="ml-2"
              />
            </div>

            <div class="flex mt-5" v-else>
              <InputWithCurrency
                v-model="price_sqm_model.from"
                :text-left="'от'"
                class="mr-2"
                :text-right="'₽'"
              />
              <InputWithCurrency
                v-model="price_sqm_model.to"
                :text-left="'до'"
                :text-right="'₽'"
                class="ml-2"
              />
            </div>
            <base-button @click="Search" size="xsm" class="mt-3 w-full">
              Применить
            </base-button>
          </div>
        </template>
      </base-dropdown>
      <base-dropdown v-if="types_includes([TYPES.commerce, TYPES.house, TYPES.segment])">
        <template #values>
          <span class="font-semibold">Площадь</span>
        </template>
        <template #inputs>
          <div class="w-full p-3">
            <div class="mt-5 flex">
              <InputWithCurrency
                v-model="area_model.from"
                :text-left="'от'"
                class="mr-2"
                :text-right="area_unit"
              />
              <InputWithCurrency
                v-model="area_model.to"
                :text-left="'до'"
                :text-right="area_unit"
                class="ml-2"
              />
            </div>
            <div>
              <base-button @click="Search" size="xsm" class="mt-3 w-full">
                Применить
              </base-button>
            </div>
          </div>
        </template>
      </base-dropdown>
      <base-dropdown
        :padding="false"
        ref="dropdown"
        :align_right="true"
        v-if="
        types_includes([TYPES.flat, TYPES.new_flats, TYPES.segment, TYPES.house]) ||
        commercial_types_model.length">
        <template #values><span class="font-semibold">Доп. фильтры</span></template>
        <template #inputs>
          <AdditionalFilters @onSearch="Search" @clearFilter="ClearFilter"/>
        </template>
      </base-dropdown>
      <div class="rounded-r flex-grow  md:mt-0 h-16 md:h-auto hidden lg:block">
        <label>
          <input
            type="text"
            @input="EnterSearch"
            @keyup.enter="Search"
            :value="text_search"
            class="rounded md:rounded-none w-full h-full pl-5 focus:outline-none search__input-desk"
            :placeholder="SearchPlaceholder"
          />
        </label>
      </div>
      <button v-if="showMapButton" class="showOnMap" :class="{active: openMap}" @click="$emit('ToggleMap')">{{ openMap ? "Скрыть карту" : "На карте" }}</button>
      <button
        @click="Search"
        class="search-button"
      >
        Найти
      </button>
    </div>
  </div>
</template>

<script>
import BaseDropdown from "@/components/base/dropdown/BaseDropdown";
import Radio from "@/components/base/radio/Radio";
import Multiselect from "@/components/base/select/Multiselect";
import BaseButton from "../../../components/base/button/BaseButton";
import InputWithCurrency from "../../../components/base/input/InputWithCurrency";
import AdditionalFilters from "./AdditionalFilters";
import {mapGetters, mapMutations} from "vuex";
import FilterTypes from "../../../constants/FilterTypes";

export default {
  name: "Filter",
  props: {
    withPush: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    OpenFilter: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    showMapButton: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    openMap: {
      type: Boolean,
      default: ()=>{
        return false;
      }
    }
  },
  components: {
    BaseDropdown,
    Radio,
    Multiselect,
    BaseButton,
    InputWithCurrency,
    AdditionalFilters,
  },
  data() {
    return {
      price_unit: true,
      showFilter: false,
    };
  },
  computed: {
    ...mapGetters({
      types_includes: "search/GET_TYPES_INCLUDES_NAME",
      text_search: "search/GET_TEXT_SEARCH",
    }),
    SearchPlaceholder(){
      let res =  "Введите название ЖК, улицы, района";
      if(this.types_includes([this.TYPES.house, this.TYPES.segment])) res = "Введите название улицы, района";
      return res;
    },
    TYPES() {
      return FilterTypes
    },
    offer_property() {
      return this.$store.state.search.main_filter.offer_property;
    },
    offer_property_types() {
      const offer_property = this.offer_property === 1 ? "buy" : "rent";
      return this.$store.state.search.main_filter_data[
        `objects_types_${offer_property}`
        ];
    },
    routeName() {
      return this.$route.name;
    },
    offer_property_model() {
      return this.$store.state.search.main_filter.types;
    },
    rooms_choice_data() {
      return this.$store.state.search.main_filter_data.rooms_choices;
    },
    rooms_choice_model() {
      return this.$store.state.search.main_filter.rooms_count;
    },
    commercial_types_data() {
      return this.$store.state.search.main_filter_data.commercial_types;
    },
    commercial_types_model() {
      return this.$store.state.search.main_filter.commerce_types ?? [];
    },
    price_model() {
      return this.$store.state.search.main_filter.price;
    },
    price_sqm_model() {
      return this.$store.state.search.main_filter.price_sqm;
    },
    area_model() {
      return this.$store.state.search.main_filter.area;
    },
    loading() {
      return this.$store.state.search.filter_loading;
    },
    area_unit() {
      const rule_1 = this.types_includes([this.TYPES.segment]);
      const rule_2 = this.types_includes([this.TYPES.commerce]) && this.commercial_types_model.includes("5-6");

      return (rule_1 || rule_2) ? "сот." : "м<sup>2</sup>"
    },
    pricePerUnitLabel(){
      const rule_1 = this.types_includes([this.TYPES.segment]);
      const rule_2 = this.types_includes([this.TYPES.commerce]) && this.commercial_types_model.includes("5-6");
      return (rule_1 || rule_2) ? "За сотку" : "За м<sup>2</sup>"
    }
  },
  methods: {
    ...mapMutations({
      OnSelectType: "search/SET_OFFER_PROPERTY_TYPE",
      OnSelectRoom: "search/SET_ROOMS_COUNT",
      OnSelectCommerce: "search/SET_COMMERCE_TYPE",
    }),
    ChangeOfferProperty(val) {
      let offer_property = val ? 2 : 1;
      this.$store.commit("search/SET_OFFER_PROPERTY", offer_property);
    },
    ChangePriceType(val) {
      this.price_unit = val;
    },
    EnterSearch(e) {
      this.$store.commit("search/SET_SEARCH_TEXT", e.target.value);
    },
    Search() {
      this.$refs?.dropdown?.closeDropdown();
      this.$refs?.price_dropdown?.closeDropdown();
      this.$store.dispatch("search/Search");
      if (this.withPush) this.$router.push("offers");
      this.$emit('map');
    },
    ClearFilter(){
      this.$refs?.dropdown?.closeDropdown();
      this.$refs?.price_dropdown?.closeDropdown();
      this.$store.dispatch("search/ClearFilterAndSearch")
    },
    ToggleFilter() {
      this.showFilter = !this.showFilter;
    }
  },
};
</script>

<style scoped lang="scss">
.main-filter {
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    display: none;
    padding: 0;
    &.show {
      display: block;
    }
  }
}

.app-filter {
  z-index: 100;
  &__mobile-search-field {
    display: none;
  }
  @media(max-width: 768px){
    &__mobile-search-field {
      display: block;
      input{
        width: 100%;
        padding: 16px 20px;
        border-bottom: 1px solid #D3D3D3;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
}

.mobile-filter-button {
  padding-right: 30px;
  padding-left: 15px;
  @apply flex flex-row justify-start items-center;
  &__arrow {
    margin-left: auto;
  }

  @media (min-width: 1024px) {
    display: none;
  }
}

.search-button {
  background: #3D7E9C;
  border-radius: 4px;
  padding: 8px 20px;
  width: 83.19px;
  height: 38px;
  color: #fff;
  font-weight: 600;
  font-size: 14.3784px;
  line-height: 150%;

  &:hover {
    @apply bg-darkblue text-white;
  }
  @media(min-width: 1025px) and (max-width: 1535px) {
    font-size: 12px;
    padding: 8px  12px;
  }
  @media (max-width: 1024px) {
    width: calc(100% - 40px);
    margin-left: 18px;
    margin-top: 10px;
    display: block;
  }
}
@media(min-width: 1025px) and (max-width: 1535px) {
  .search__input-desk{
    font-size: 12px;
  }
}
.showOnMap {
  margin-right: 10px;
  background: rgba(61, 126, 156, 0.2);
  border-radius: 4px;
  padding: 8px 15px;
  height: 38px;
  color: #3D7E9C;
  font-weight: 600;
  font-size: 14.3784px;
  line-height: 150%;
  &:hover, &.active{
    @apply bg-darkblue text-white;
  }
  @media(min-width: 1025px) and (max-width: 1535px) {
    font-size: 12px;
    padding: 8px  12px;

  }
  @media (max-width: 1024px) {
    width: calc(100% - 40px);
    margin: 10px 0;
    margin-left: 18px;
    display: block;
  }
}
</style>
